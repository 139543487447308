import styled from '@emotion/styled'
import { CATALOG_ITEM_TYPE, CatalogItemType } from '@pubstack/common/src/catalogItem'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius, Sizes } from '~/assets/style/tokens'
import { AdStackIntegrationLogo } from '~/components/AdStackIntegrationLogo'
import { Icon } from '~/components/Icon'
import { Skeleton } from '~/components/Skeleton'
import { Status } from '~/components/Status'
import { WithClassName } from '~/types/utils'

const ModuleInformation = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  & > div {
    ${BorderRadius.style}
  }
`

const ModuleLogo = styled.div`
  background-color: ${Colors.White};
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-width: 286px;
  flex: 0 0 286px;
  & img {
    align-self: center;
    max-height: 50px;
    max-width: 286px;
    width: auto;
  }
`

const ModuleDetail = styled.div`
  background-color: ${Colors.White};
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
`

const ModuleDetailTitle = styled.span`
  ${Fonts.H2};
  display: inline-flex;
  align-items: center;
  gap: 4px;
`
const ModuleDetailContent = styled.div`
  display: inline-flex;
  gap: 150px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
`

const ModuleLabel = styled.span`
  color: ${Colors.SlateGrey};
  ${Fonts.P2}
`

type PureCatalogItemHeaderProps = WithClassName & {
  catalogItemCode?: string
  isLoading: boolean
  catalogItemType: CatalogItemType
  isActive: boolean
  baseCDNUrl: string
}

const _PureCatalogItemHeader: FunctionComponent<PureCatalogItemHeaderProps> = ({ isLoading, catalogItemCode, isActive, catalogItemType, baseCDNUrl }) => {
  return (
    <ModuleInformation>
      <ModuleLogo>{isLoading || !catalogItemCode ? <Skeleton bigger width={'195px'} /> : <AdStackIntegrationLogo width={'132px'} name={catalogItemCode} baseCDNUrl={baseCDNUrl} />}</ModuleLogo>
      <ModuleDetail>
        <ModuleDetailTitle>
          <Icon name={'rocket'} width={Sizes['22']} /> Module detail
        </ModuleDetailTitle>
        <ModuleDetailContent>
          <div>
            <ModuleLabel>Integration type</ModuleLabel>
            <span>{CATALOG_ITEM_TYPE[catalogItemType]}</span>
          </div>
          <div>
            <ModuleLabel>Status</ModuleLabel>
            <div>{isLoading ? <Skeleton bigger width={'70px'} /> : <Status state={isActive ? 'active' : 'inactive'}>{isActive ? 'Active' : 'Not used'}</Status>}</div>
          </div>
        </ModuleDetailContent>
      </ModuleDetail>
    </ModuleInformation>
  )
}

export const PureCatalogItemHeader = styled(_PureCatalogItemHeader)``
