import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AllQuery, AnalyticsQueryArgs, AnalyticsQueryDashboard, AnalyticsResponse, GenericTopValuesByDim } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DateTime, Interval } from 'luxon'
import { FC, PropsWithChildren, ReactElement, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useLocalStorage } from 'usehooks-ts'
import { useAnalyticsQuery, useAnalyticsSearch } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { FilterSidebar } from '~/components/FilterSidebar/FilterSidebar'
import { FilterSidebarCategory } from '~/components/FilterSidebar/FilterSidebarCategory'
import { FilterSidebarMinorDataToggle } from '~/components/FilterSidebar/FilterSidebarMinorDataToggle'
import { TimeSelectorProps } from '~/components/TimeSelector'
import { useToast } from '~/components/Toast/useToasts'
import { Context, Filter, contextState, filterSidebarPinnedState, filterSidebarState } from '~/state'
import { FilterCategory, FilterCategoryDataItem, Range } from '~/types/analytics'
import { WithClassName } from '~/types/utils'
import { filterDimensionsByUserFeatures, getTimePresets, isContextInSelectableInterval } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { displayWithCurrency } from '~/utils/string'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { AnalyticsChartsTooltipHidden } from './AnalyticsChartsTooltip'
import { PureAnalyticsPage } from './PureAnalyticsPage'
import { auctionCount, hbImpressionRevenue } from './formulas'
import { asShort } from './formulas/operation'
import { useSyncContext } from './useContext'

type BuildContext = (context: Context) => Context
type AnalyticsPageProps = WithClassName & {
  timePresets?: TimeSelectorProps['presets']
  selectableInterval?: Interval
  onRefreshClick?: () => void
  comparedTo?: boolean
  filterDimensions: Dimension[]
  queryDimensions?: Dimension[]
  dashboard: AnalyticsQueryDashboard
  title?: ReactElement
  buildContext?: BuildContext
  maxNumberOfDayInTimerangeSelected?: number
  showMinorDataToggle?: boolean
}

const getComputedData = (data: FilterCategoryDataItem[] | undefined, dimension: Dimension, context: Context) => {
  return data
    ? data.map((item) => {
        const hasFilter = !!(context.filters[dimension]?.values || []).length
        const inFilter = !!context.filters[dimension]?.values.find((filter) => filter.value === item.key)
        const inFilterIncluded = hasFilter && inFilter && context.filters[dimension]?.mode === 'include'
        const notInFilterExcluded = hasFilter && !inFilter && context.filters[dimension]?.mode === 'exclude'
        return {
          ...item,
          checked: !hasFilter || inFilterIncluded || notInFilterExcluded,
        }
      })
    : undefined
}

const getComputedCategories = (categories: FilterCategory[], context: Context): FilterCategory[] => {
  return categories.map((category) => ({
    ...category,
    data: getComputedData(category.data, category.dimension, context),
    searchResults: getComputedData(category.searchResults, category.dimension, context),
  }))
}

const useActions = (dimensions: Dimension[], dashboard: AnalyticsQueryDashboard, buildContext?: BuildContext) => {
  const [context, setContext] = useRecoilState(contextState)
  const [categories, setCategories] = useRecoilState(filterSidebarState)
  const currencySymbol = useScopeCurrency()
  const logger = useLogger()
  const { byId: analyticsQuery } = useAnalyticsQuery<GenericTopValuesByDim>(null, dimensions, buildContext ? buildContext(context) : context)
  const { search } = useAnalyticsSearch(null, dimensions, buildContext ? buildContext(context) : context)

  const onTimeSelectionRefreshClick = () => {
    logger.info({ action: 'click', type: 'refresh' })
  }

  const onLabelClick = (item: FilterCategoryDataItem, category: FilterCategory) => {
    const filters: { [key in Dimension]?: Filter } = JSON.parse(JSON.stringify(context.filters))
    const existingFilters: Filter | undefined = filters[category.dimension]
    let forceSelection = true

    if (existingFilters) {
      // We force selection if filter not already in existing filters
      forceSelection = !existingFilters.values.find((filter) => filter.value === item.key)

      delete filters[category.dimension]
    }
    if (forceSelection) {
      filters[category.dimension] = {
        mode: 'include',
        values: [{ label: item.label, value: item.key, sublabel: item.subLabel }],
        name: category.dimension,
      }
    }
    const newContext: Context = { ...context, filters }
    setCategories((categories) => getComputedCategories(categories, newContext))
    setContext(newContext)
  }

  const onCheckboxClick = (item: FilterCategoryDataItem, category: FilterCategory) => {
    logger.info({
      action: 'click',
      type: 'facet',
      detail: 'filter',
      name: category.dimension,
      check: !item.checked,
      value: item.key,
    })
    const filters: { [key in Dimension]?: Filter } = JSON.parse(JSON.stringify(context.filters))
    const filter: Filter = filters[category.dimension] || { mode: 'exclude', values: [], name: category.dimension }
    const addFacetInFilterList = filter.mode === 'exclude' ? item.checked : !item.checked
    if (addFacetInFilterList) {
      filter.values.push({ label: item.label, value: item.key, sublabel: item.subLabel })
      if (!filters[category.dimension]) {
        filters[category.dimension] = filter
      }
    } else {
      filter.values = filter.values.filter((mappedName) => mappedName.value !== item.key)
      if (filter.values.length === 0) {
        delete filters[category.dimension]
      }
    }

    const newContext: Context = { ...context, filters }
    setCategories((categories) => getComputedCategories(categories, newContext))
    setContext(newContext)
  }

  const onCategoryTitleClick = async (category: FilterCategory) => {
    let data = [...(category.data || [])]
    if (!category.open) {
      logger.info({ action: 'click', type: 'facet', detail: 'expand', name: category.dimension })
      setCategories((categories) =>
        (categories || []).map((c) =>
          c.dimension === category.dimension
            ? {
                ...category,
                open: category.expanded || !category.open,
                expanded: false,
                loading: true,
              }
            : c
        )
      )
      data = await getData(category, analyticsQuery.post, dashboard, currencySymbol)
    }
    setCategories((categories) => {
      const newCategories = (categories || []).map((c) =>
        c.dimension === category.dimension
          ? {
              ...category,
              open: category.expanded || !category.open,
              expanded: false,
              data: data,
              loading: false,
              searchResults: undefined,
            }
          : c
      )
      return getComputedCategories(newCategories, context)
    })
  }

  const onCategoryExpandToggle = (category: FilterCategory) =>
    setCategories(
      (categories || []).map((c) =>
        c === category
          ? {
              ...category,
              open: category.expanded || category.open,
              expanded: !category.expanded,
              searchResults: undefined,
            }
          : c
      )
    )

  const onFilterClick = (dimension: Dimension) => {
    const filters: { [key in Dimension]?: Filter } = JSON.parse(JSON.stringify(context.filters))
    delete filters[dimension]
    const newContext: Context = { ...context, filters }
    setCategories((categories) => getComputedCategories(categories, newContext))
    setContext(newContext)
  }

  const onUpdateDateRange = (label: string, timeRange: Range) => {
    const diff = Interval.fromDateTimes(timeRange.from, DateTime.now().startOf('day'))
    logger.info({ action: 'click', type: 'temporal', range: label, fromDate: timeRange.from.toSeconds(), toDate: timeRange.to.toSeconds(), nbOfDaysBetweenQueryAndToday: diff.length('days') })
    setContext({
      ...context,
      timeRange: { ...context.timeRange, ...timeRange },
    })
  }

  const onSearch = async (term: string, category: FilterCategory) => {
    const response = await search({ dimension: category.dimension, term, dashboard })
    const data = formatDataResponse(category, dashboard, currencySymbol, response)
    setCategories((categories) => {
      const newCategories = (categories || []).map((c) =>
        c.dimension === category.dimension
          ? {
              ...category,
              searchResults: data,
            }
          : c
      )
      return getComputedCategories(newCategories, context)
    })
  }

  const onSelectAll = (category: FilterCategory): void => {
    const filters: { [key in Dimension]?: Filter } = JSON.parse(JSON.stringify(context.filters))
    const values = category.searchResults?.map((item) => ({ label: item.label, value: item.key })) || []
    filters[category.dimension] = { mode: 'include', values, name: category.dimension }
    const newContext: Context = { ...context, filters }
    setCategories((categories) => getComputedCategories(categories, newContext))
    setContext(newContext)
  }

  return {
    onTimeSelectionRefreshClick,
    onLabelClick,
    onCheckboxClick,
    onCategoryTitleClick,
    onCategoryExpandToggle,
    onFilterClick,
    onUpdateDateRange,
    onSearch,
    onSelectAll,
  }
}

const formatDataResponse = (category: FilterCategory, dashboard: AnalyticsQueryDashboard, currencySymbol: CurrencySymbol, values?: GenericTopValuesByDim) => {
  let data = [...(category.data || [])]
  if (values) {
    const getValue = (index: number): number | undefined => {
      if (dashboard.filterType === 'revenues') {
        return hbImpressionRevenue.isComputable(values) ? hbImpressionRevenue.compute(values, index) : undefined
      } else {
        return auctionCount.isComputable(values) ? auctionCount.compute(values, index) : undefined
      }
    }

    data = values.name.map((name, index: number) => {
      const value = getValue(index)
      let formattedValue = '-'
      if (value) {
        formattedValue = dashboard.filterType === 'auctions' ? asShort(value) : displayWithCurrency(asShort(value), currencySymbol)
      }
      return { label: name.label, key: name.value, value, formattedValue, checked: true, subLabel: name.sublabel }
    })
  }
  return data
}

const getData = async (
  category: FilterCategory,
  post: (query: AllQuery, args: AnalyticsQueryArgs) => Promise<AnalyticsResponse<GenericTopValuesByDim>>,
  dashboard: AnalyticsQueryDashboard,
  currencySymbol: CurrencySymbol
) => {
  const response = await post('top.values.by.dim', { dimension: category.dimension, dashboard })
  return formatDataResponse(category, dashboard, currencySymbol, response?.values)
}

const AnalyticsMinorFilterToggle = () => {
  const [showMinorData, setShowMinorData] = useLocalStorage<boolean>('showMinorData', false)
  const logger = useLogger()

  const onToggle = () => {
    logger.info({ type: 'show-minor-data', value: !showMinorData, action: 'click' })
    setShowMinorData(!showMinorData)
  }
  return <FilterSidebarMinorDataToggle value={showMinorData} onToggle={onToggle} />
}

const AnalyticsCategory = ({
  category,
  queryDimensions,
  dashboard,
  buildContext,
  maxNumberOfDayInTimerangeSelected,
}: {
  category: FilterCategory
  queryDimensions: Dimension[]
  dashboard: AnalyticsQueryDashboard
  buildContext?: BuildContext
  maxNumberOfDayInTimerangeSelected: number
}) => {
  const context = useRecoilValue(contextState)
  const currencySymbol = useScopeCurrency()
  const { byId: analyticsQuery } = useAnalyticsQuery<GenericTopValuesByDim>(null, queryDimensions, buildContext ? buildContext(context) : context)
  const [, setCategories] = useRecoilState(filterSidebarState)
  const { onLabelClick, onCheckboxClick, onCategoryTitleClick, onCategoryExpandToggle, onSearch, onSelectAll } = useActions(queryDimensions, dashboard, buildContext)

  useEffect(() => {
    async function run() {
      if (category.open) {
        if (category.loading || analyticsQuery.loading) {
          analyticsQuery.abort()
        }

        setTimeout(async () => {
          if (isContextInSelectableInterval(context, maxNumberOfDayInTimerangeSelected)) {
            const response = await analyticsQuery.post('top.values.by.dim', { dimension: category.dimension, dashboard })
            if (response?.values) {
              const data = formatDataResponse(category, dashboard, currencySymbol, response?.values)
              setCategories((categories) =>
                getComputedCategories(
                  (categories || []).map((c) =>
                    c.dimension === category.dimension
                      ? {
                          ...c,
                          data,
                        }
                      : c
                  ),
                  context
                )
              )
            }
          }
        }) // TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
      }
    }
    run()
  }, [context])

  return (
    <FilterSidebarCategory
      category={category}
      onSearch={onSearch}
      onSelectAll={onSelectAll}
      loading={category.loading || analyticsQuery.loading}
      onTitleClick={onCategoryTitleClick}
      onExpandToggle={onCategoryExpandToggle}
      onLabelClick={onLabelClick}
      onCheckboxClick={onCheckboxClick}
    />
  )
}

const _AnalyticsPage: FC<PropsWithChildren<AnalyticsPageProps>> = ({
  className,
  onRefreshClick,
  comparedTo,
  children,
  timePresets: overrideTimePresets,
  selectableInterval: overrideSelectableInterval,
  filterDimensions,
  queryDimensions,
  dashboard,
  title,
  buildContext,
  maxNumberOfDayInTimerangeSelected = 93,
  showMinorDataToggle,
}) => {
  const [context, setContext] = useRecoilState(contextState)
  const toast = useToast()
  const today = DateTime.now().startOf('day')
  const timePresets = overrideTimePresets ?? getTimePresets(today)
  const selectableInterval = overrideSelectableInterval ?? Interval.fromDateTimes(today.minus({ days: 93 }), today.plus({ days: 1 }))
  const categories = useRecoilValue(filterSidebarState)
  const user = useUser()
  const filteredFilterDimensions = filterDimensionsByUserFeatures(filterDimensions, user)
  const filteredQueryDimensions = queryDimensions ? filterDimensionsByUserFeatures(queryDimensions, user) : undefined

  const { onFilterClick, onUpdateDateRange, onTimeSelectionRefreshClick } = useActions(filteredQueryDimensions ?? filteredFilterDimensions, dashboard, buildContext)
  const syncContext = useSyncContext() // TODO NRA CFO why don't use the syntax (without useEffect) : useSyncContext(context)

  const filteredCategories = categories.filter((category) => filteredFilterDimensions.includes(category.dimension))
  const analyticsCategories = filteredCategories.map((category, index) => (
    <AnalyticsCategory
      key={index}
      category={category}
      queryDimensions={filteredQueryDimensions ?? filteredFilterDimensions}
      dashboard={dashboard}
      buildContext={buildContext}
      maxNumberOfDayInTimerangeSelected={maxNumberOfDayInTimerangeSelected}
    />
  ))
  const expandedCategoryIndex = filteredCategories.findIndex(({ expanded }) => expanded)
  const expandedCategory = analyticsCategories[expandedCategoryIndex]

  useEffect(() => {
    let isMounted = true
    if (!isContextInSelectableInterval(context, maxNumberOfDayInTimerangeSelected)) {
      const { tz } = context.timeRange
      const selectable = selectableInterval.set({ start: selectableInterval.start.startOf('day'), end: selectableInterval.end.endOf('day').minus({ days: 1 }) })
      const latestDay = selectable.end
      if (isMounted) {
        toast('The time range has been replaced by "Today" as the previous selection was not available on this dashboard.')
        setContext((context) => ({ ...context, timeRange: { tz, from: latestDay.startOf('day').toLocal(), to: latestDay.endOf('day').toLocal() } }))
      }
    }

    if (isMounted) {
      syncContext(context)
    }

    return () => {
      isMounted = false
    }
  }, [context, selectableInterval])

  const [filterPinned, setFilterPinned] = useRecoilState(filterSidebarPinnedState)

  return (
    <PureAnalyticsPage
      className={className}
      onRefreshClick={() => {
        onTimeSelectionRefreshClick()
        return onRefreshClick && onRefreshClick()
      }}
      onUpdate={onUpdateDateRange}
      selectedRange={context.timeRange}
      today={today}
      timePresets={timePresets}
      comparedTo={!!comparedTo}
      filters={context.filters}
      filterDimensions={filteredFilterDimensions}
      onFilterClick={onFilterClick}
      filterSidebar={
        <FilterSidebar
          categories={
            <>
              {analyticsCategories}
              {!!showMinorDataToggle && <AnalyticsMinorFilterToggle />}
            </>
          }
          expandedCategory={expandedCategory}
          isPinned={filterPinned.pinned}
          onPin={() => {
            setFilterPinned({ pinned: !filterPinned.pinned })
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'))
            }, 400)
          }}
        />
      }
      isFilterSidebarPinned={filterPinned.pinned}
      selectableInterval={selectableInterval}
      title={title}
      timezone={context.timeRange.tz}
      maxNumberOfDayInTimerangeSelected={maxNumberOfDayInTimerangeSelected}
    >
      {/* TODO cfo 2022-11-29 - Remove once emotion is fully compatible with React18 */}
      <AnalyticsChartsTooltipHidden />
      {/* TODO END */}
      {children}
    </PureAnalyticsPage>
  )
}

export const AnalyticsPage = styled(_AnalyticsPage)``
