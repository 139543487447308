import { AllMetrics } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { shortNumber } from '~/utils/number'
import { displayWithCurrency } from '~/utils/string'

const keys = (o: Partial<AllMetrics>) => Object.keys(o) as (keyof Omit<AllMetrics, 'total'>)[]

export interface ComputeFormulaFunction<T extends Partial<AllMetrics>, X = number> {
  (values: T, index: number): X
}

export interface ComputeSumFormulaFunction<T extends Partial<AllMetrics>> {
  (values: T): number
}

export interface Formula<T extends Partial<AllMetrics>> {
  name: string
  keys: (keyof AllMetrics)[]
  optionalKeys?: (keyof AllMetrics)[]
  raw(values: { [key in keyof Omit<T, 'total'>]: number }): number
  compute: ComputeFormulaFunction<T>
  sum: ComputeSumFormulaFunction<T>
  others: ComputeSumFormulaFunction<T>
  percentage: (computedNumber: number, values: T) => number
  displayable: (number: number, currencySymbol?: CurrencySymbol) => string
  isComputable: (value: Partial<T>) => value is Required<T>
  isStackable: boolean
  tooltip?: string
  type: DisplayableTypes
}

export type DisplayableTypes = 'number' | 'shortNumber' | 'percentage' | 'monetary' | 'preciseMonetary' | 'seconds' | 'uplift' | 'identity'
export const asNumber: (v: number) => string = (v) => v.toPrecision(2)
export const asPercentage: (v: number) => string = (v) => `${v.toFixed(1)}%`
export const asShort: (v: number) => string = (v) => shortNumber(parseFloat(v.toFixed(2)))
export const asMonetary: (v: number, currencySymbol?: CurrencySymbol) => string = (v, currencySymbol) => displayWithCurrency(asShort(v), currencySymbol)
export const asSeconds: (v: number, currencySymbol?: CurrencySymbol) => string = (v) => `${asNumber(v)}s`
export const adPreciseMonetary: (v: number, currencySymbol?: CurrencySymbol) => string = (v, currencySymbol) => displayWithCurrency(v.toPrecision(2), currencySymbol)
export const asUplift: (v: number) => string = (v) => `+${v.toFixed(0)}%`
export const asIdentity: (v: number) => string = (v) => v.toString()

const DISPLAYABLES: { [key in DisplayableTypes]: (number: number, currencySymbol?: CurrencySymbol) => string } = {
  number: asNumber,
  shortNumber: asShort,
  percentage: asPercentage,
  monetary: asMonetary,
  preciseMonetary: adPreciseMonetary,
  seconds: asSeconds,
  uplift: asUplift,
  identity: asIdentity,
}

export const buildFormula = <T extends keyof AllMetrics, A extends Pick<AllMetrics, T> = Pick<AllMetrics, T>>({
  keys,
  optionalKeys,
  name,
  raw,
  type,
  tooltip,
  isStackable,
}: {
  keys: T[]
  optionalKeys?: T[]
  name: string
  raw: (v: { [key in keyof Omit<Required<A>, 'total'>]: number }) => number
  type: DisplayableTypes
  isStackable: boolean
  tooltip?: string
}): Formula<A> => {
  const formula: Formula<A> = {
    name,
    raw,
    keys,
    optionalKeys,
    isComputable: (value: Partial<A>): value is Required<A> => keys.filter((k) => (optionalKeys ?? []).indexOf(k) < 0).every((k) => k in value),
    compute: (values, index) => compute(formula, values, index),
    sum: (values) => sum(formula, values),
    others: (values) => others(formula, values),
    percentage: (computedNumber, values) => percentage(formula, computedNumber, values),
    displayable: DISPLAYABLES[type],
    tooltip,
    type,
    isStackable,
  }
  return formula
}

export const arraySum = (myArray: (number | null)[]): number => myArray.reduce((acc: number, current) => acc + (current ?? 0), 0)

const compute = <T extends Partial<AllMetrics>, V>(formula: Formula<T>, values: T, index: number): number => {
  const emptyObject = {} as { [key in keyof T]: number }
  const flattenedObject = keys(values).reduce((objectAgg, value) => ({ ...objectAgg, [value]: values[value]?.[index] ?? 0 }), emptyObject)
  return formula.raw(flattenedObject)
}

const sum = <T extends Partial<AllMetrics>, V>(formula: Formula<T>, values: T, useTotalIfPresent = true): number => {
  const emptyObject = {} as { [key in keyof T]: number }
  const flattenedObject = keys(values)
    .filter((value) => Array.isArray(values[value]))
    .reduce((objectAgg, value) => {
      if (useTotalIfPresent && values['total'] && values['total'][value]) {
        objectAgg[value] = values['total']?.[value] ?? 0
      } else {
        objectAgg[value] = arraySum(values[value] ?? [])
      }
      return objectAgg
    }, emptyObject)
  return formula.raw(flattenedObject)
}

const others = <T extends Partial<AllMetrics>, V>(formula: Formula<T>, values: T): number => {
  const emptyObject = {} as { [key in keyof T]: number }
  if (values['total']) {
    const flattenedObject = keys(values)
      .filter((value) => Array.isArray(values[value]))
      .reduce((objectAgg, value) => {
        if (values['total'] && values['total'][value]) {
          objectAgg[value] = values['total']?.[value] ?? 0
        } else {
          objectAgg[value] = 0
        }
        return objectAgg
      }, emptyObject)
    const partialSum = sum(formula, values, false)

    const multi = 1000
    const truncate = (n: number) => Math.round(n * multi)
    return Math.max(0, truncate(formula.raw(flattenedObject) - partialSum) / multi)
  }
  return 0
}

const percentage = <T extends Partial<AllMetrics>, V>(formula: Formula<T>, computedNumber: number, values: T): number => {
  return computedNumber / sum(formula, values)
}
