import styled from '@emotion/styled'
import { AdminTag } from '@pubstack/common/src/tag'
import { FunctionComponent, useState } from 'react'
import { FieldValues, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { ScrollbarStyle } from '~/assets/style/utils'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { Column, EmptyTable, handleTableSearchAndSort, onColumnSort, SortableColumn, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'

const ScopeSitesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  padding-bottom: 10px;
  overflow: hidden;
`

const SiteActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`

const SiteTableActions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

const SiteTable = styled(Table)`
  max-height: 100%;
  overflow: auto;
  ${ScrollbarStyle}
`

const ModalInput = styled(Input)`
  margin-top: 4px;
`

const UpdateForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 34px;
`

type NewSiteModalProps = { onClickPrimary: (siteName: string) => unknown }
const NewSiteModal: FunctionComponent<NewSiteModalProps> = ({ onClickPrimary }) => {
  const modal = useGlobalModal()
  const { control, getValues, reset } = useForm<FieldValues>({
    defaultValues: {
      name: '',
    },
  })

  const close = () => {
    reset()
    modal.close()
  }

  return (
    <Modal.Content>
      <Modal.Title>New site</Modal.Title>

      <Modal.Body>
        <ModalInput name={'name'} control={control} label={'Site name'} />
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            const siteName = getValues('name').trim()
            if (siteName) {
              onClickPrimary(siteName)
              close()
            }
          }}
        >
          Add site
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

type EditSiteModalProps = { adminTag: AdminTag; onClickPrimary: (adminTag: AdminTag) => unknown }
const EditSiteModal: FunctionComponent<EditSiteModalProps> = ({ adminTag, onClickPrimary }) => {
  const onSubmit: SubmitHandler<AdminTag> = async (tag) => {
    await onClickPrimary(tag)
    close()
  }
  const modal = useGlobalModal()
  const { control, handleSubmit, reset } = useForm<AdminTag>({
    defaultValues: {
      ...adminTag,
    },
    mode: 'onChange',
  })

  const close = () => {
    reset()
    modal.close()
  }

  return (
    <Modal.Content>
      <Modal.Title>Edit site</Modal.Title>

      <Modal.Body>
        <UpdateForm id={'newScopeForm'} onSubmit={handleSubmit(onSubmit)}>
          <Input name={'name'} control={control} label={'Site name'} />
          <Input name={'pbjs_adapter'} control={control} label={'PBJS Variable'} />
        </UpdateForm>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Edit site</Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

type DeleteSiteProps = { site: AdminTag; onClickPrimary: (site: AdminTag) => unknown }
const DeleteSiteModal: FunctionComponent<DeleteSiteProps> = ({ site, onClickPrimary }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Remove site</Modal.Title>

      <Modal.Body>
        <p>
          You are about to remove <strong>{site.name}</strong>.
        </p>
        <p>You won’t be able to view its data in Pubstack anymore.</p>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button
          variant={'negative'}
          iconName={'delete'}
          onClick={() => {
            onClickPrimary(site)
            close()
          }}
        >
          Delete
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const ADMIN_SITE_COLUMNS: (Column | SortableColumn<AdminTag>)[] = [
  {
    name: 'Name',
    isSortable: true,
    order: 'ascending',
    attributeSort: 'name',
  },
  {
    name: 'Site ID',
    isSortable: true,
    order: 'none',
    attributeSort: 'id',
  },
  {
    name: 'Prebid variable',
    isSortable: true,
    order: 'none',
    attributeSort: 'pbjs_adapter',
  },
  {
    name: 'Status',
    isSortable: false,
  },
  {
    name: 'Action',
    isSortable: false,
  },
]

type PureAdminScopeSitesProps = WithClassName & {
  sites: AdminTag[]
  isLoading: boolean
  onNewSite: (siteName: string) => unknown
  onDeleteSite: (siteId: string) => unknown
  onEditSite: (site: AdminTag) => unknown
  onDownload: () => unknown
}
const _PureAdminScopeSites: FunctionComponent<PureAdminScopeSitesProps> = ({ className, sites, isLoading, onNewSite, onDeleteSite, onEditSite, onDownload }) => {
  const modal = useGlobalModal()
  const { control } = useForm({
    defaultValues: {
      site: '',
    },
  })
  const siteSearch = useWatch({ control, name: 'site' })

  const [columns, setColumns] = useState<TableColumns<AdminTag>>(ADMIN_SITE_COLUMNS)

  const displayedSites = handleTableSearchAndSort(columns, sites, siteSearch, ['name'])

  const openNewSiteModal = () => {
    modal.open(NewSiteModal, { onClickPrimary: onNewSite })
  }

  const openDeleteSiteModal = (site: AdminTag) => {
    modal.open(DeleteSiteModal, { onClickPrimary: (site) => onDeleteSite(site.id), site })
  }

  const openEditSiteModal = (site: AdminTag) => {
    modal.open(EditSiteModal, { adminTag: site, onClickPrimary: (site) => onEditSite(site) })
  }

  const siteStatus = (site: AdminTag) => {
    if (site.adManagementEnabled && site.isPreAdManaged) {
      return 'Inconsistent'
    } else if (site.adManagementEnabled) {
      return 'Ad managed'
    } else if (site.isPreAdManaged) {
      return 'Pre-ADM'
    } else {
      return 'Analytics'
    }
  }

  return (
    <ScopeSitesWrapper className={className}>
      <SiteActions>
        <SiteTableActions>
          <Input iconLeft={'search'} name={'site'} control={control} label={'Search'} labelIsPlaceholder />
          <Button onClick={openNewSiteModal}>New site</Button>
        </SiteTableActions>
        <Button variant={'secondary'} iconName={'download'} onClick={onDownload} />
      </SiteActions>
      <SiteTable
        columns={columns}
        isLoading={isLoading}
        onClickHeading={(column) => {
          onColumnSort(columns, column, setColumns)
        }}
      >
        {displayedSites.length ? (
          displayedSites.map((site, index) => (
            <TableRow key={index}>
              <TableCell>{site.name}</TableCell>
              <TableCell>{site.id}</TableCell>
              <TableCell>{site.pbjs_adapter}</TableCell>
              <TableCell>{siteStatus(site)}</TableCell>
              <TableCell>
                <Button variant={'tertiary'} iconName={'edit'} onClick={() => openEditSiteModal(site)} />
                <Button variant={'tertiary'} iconName={'delete'} onClick={() => openDeleteSiteModal(site)} />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colspan={columns.length}>
              <EmptyTable>
                <div>
                  <h2>No Sites</h2>
                  <p>There is no existing site for this scope.</p>
                </div>
                <Button onClick={openNewSiteModal}>New site</Button>
              </EmptyTable>
            </TableCell>
          </TableRow>
        )}
      </SiteTable>
    </ScopeSitesWrapper>
  )
}

export const PureAdminScopeSites = styled(_PureAdminScopeSites)``
