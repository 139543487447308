import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ForwardedRef, PropsWithChildren, forwardRef } from 'react'
import LogoRaw from '~/assets/logo.svg?react'
import { Colors } from '~/assets/style/colors'
import { Sizes } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { WithClassName } from '~/types/utils'

type WrapperProps = {
  open: boolean
}

const Logo = styled(LogoRaw)`
  opacity: 1;
  height: 24px;
  width: 138px;
  margin: 0;
  line-height: 20px;
  box-sizing: border-box;
  & .logo__name {
    transition: opacity 0.15s ease-in-out;
  }
`

const LogoWrapper = styled.div`
  height: 34px;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  transition: 0.15s ease-in-out;
`

const Toggle = styled(Button)`
  opacity: 0;
  color: ${Colors.SlateGrey};
  background-color: ${Colors.White};
  &:hover {
    color: ${Colors.SlateGrey};
    background: ${Colors.Platinum};
  }
  span[name='arrow_bottom_right'] {
    transform: rotate(-90deg);
  }
`

const ToggleWrapper = styled.div``

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 18px 16px 33px 16px;
  transition: 0.3s ease-in-out;
`

const WrapperStylesClosed = ({ open }: WrapperProps) => css`
  transform: translateX(-195px);

  & ${Toggle} {
    opacity: ${open ? 0 : 1};
  }
`
const Wrapper = styled.nav<WrapperProps>`
  width: 195px;
  background-color: ${Colors.White};
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: ${Sizes[12]};
  height: calc(100% - ${Sizes[12]});
  border-right: 1px solid ${Colors.Platinum};

  transition:
    all 0.3s ease-in-out,
    height 0ms;

  &:hover ${Toggle} {
    opacity: 1;
  }

  ${({ open }) => !open && WrapperStylesClosed}
`

type PureSidebarProps = WithClassName & {
  isOpen: boolean
  onOpen: () => void
  isPinned: boolean
  onPin: () => void
}
// eslint-disable-next-line react/display-name
const _PureSidebar = forwardRef(({ isOpen, onOpen, className, isPinned, onPin, children }: PropsWithChildren<PureSidebarProps>, ref: ForwardedRef<HTMLElement>) => (
  <Wrapper className={className} open={isOpen} ref={ref}>
    <Header>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <ToggleWrapper>
        <Toggle variant={'tertiary'} iconName={isPinned ? 'menu_extend' : 'keep'} onClick={isPinned ? onOpen : onPin} iconSize={Sizes['20']} />
      </ToggleWrapper>
    </Header>
    {children}
  </Wrapper>
))

export const PureSidebar = styled(_PureSidebar)``
