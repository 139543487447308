import styled from '@emotion/styled'
import { semanticVersionValidation } from '@pubstack/common/src/input'
import { KleanadsScopeConfig, PbjsDesiredVersion } from '@pubstack/common/src/kleanadsScopeConfig'
import { FunctionComponent } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { BorderRadius } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { Input } from '~/components/Input'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding-bottom: 20px;
`

const JsonTextArea = styled.textarea`
  ${BorderRadius.style};
  outline: none;
  resize: none;
  border: 1px solid ${Colors.Platinum};
  white-space: pre;
  min-height: 200px;
  width: 100%;
  background-color: ${Colors.Ghost};
  ${Fonts.MonoSmall};
  ${Fonts.colors.Jet};

  padding: 8px;

  &:active,
  &:focus {
    border-color: ${Colors.Silver};
  }
`

const PrebidTitle = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const PrebidCurrent = styled.div`
  margin: 10px;
`

const PrebidData = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & Input {
    width: 300px;
  }
`

const ErrorMessage = styled.div`
  ${Fonts.P2}
  ${Fonts.colors.Alert}
  display: inline-flex;
  align-items: center;
  gap: 4px;
`

const JSON_SPACE_COUNT = 2

type PureAdStackScopeConfigProps = WithClassName & {
  scopeConfig?: KleanadsScopeConfig
  onCreateScopeConfig: () => Promise<void>
  onUpdatePbjsVersion: (pbjsDesiredVersion: PbjsDesiredVersion) => Promise<void>
  isLoading: boolean
}
const _PureAdStackScopeConfig: FunctionComponent<PureAdStackScopeConfigProps> = ({
  className,
  isLoading,
  scopeConfig,
  onCreateScopeConfig,
  onUpdatePbjsVersion,
  ...props
}: PureAdStackScopeConfigProps) => {
  const paramsJsonValue = JSON.stringify(scopeConfig, undefined, JSON_SPACE_COUNT)

  const newScopeConfigButton = (
    <Button
      variant={'primary'}
      onClick={() => {
        onCreateScopeConfig()
      }}
    >
      New scope config
    </Button>
  )

  const empty_pbjs_desired_version: PbjsDesiredVersion = {
    desiredVersion: '',
    desiredRatio: 100,
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: empty_pbjs_desired_version })
  const desiredVersion = useWatch({ control, name: 'desiredVersion' })
  const desiredRatio = useWatch({ control, name: 'desiredRatio' })

  const onSubmit: SubmitHandler<PbjsDesiredVersion> = ({ desiredVersion, desiredRatio }) => {
    onUpdatePbjsVersion({ desiredVersion, desiredRatio: Number(desiredRatio) })
  }

  const actualPbjsVersion = scopeConfig?.pbjs?.mainVersion
  const actualPbjsRatio = scopeConfig?.pbjs?.newRatio ? 100 - scopeConfig?.pbjs?.newRatio : 100

  return scopeConfig && scopeConfig.id ? (
    <Wrapper>
      <form id={'scopeConfigForm'} onSubmit={handleSubmit(onSubmit)}>
        <PrebidTitle>
          <h2>Prebid A/B Test</h2>
          <Tooltip
            title={
              <div>
                <div>
                  <b>How does it work ?</b>
                </div>
                Here you can set a new prebid version with a dedicated ratio. The app will just update the <i>pbjsDesired</i> field in dynamo. Then all the rest is handled by the kleanads-prebid
                lambda/ECS and the kleanads-server.
              </div>
            }
          >
            <Icon width={'16px'} name={'info'} />
          </Tooltip>
        </PrebidTitle>
        <PrebidCurrent>
          <div>
            Current prebid version is <b>{actualPbjsVersion}</b> deployed to <b>{actualPbjsRatio}</b>%
          </div>
          {scopeConfig.pbjs && scopeConfig.pbjs.newVersion && (
            <div>
              New prebid version is <b>{scopeConfig.pbjs.newVersion}</b> deployed to <b>{scopeConfig.pbjs.newRatio}</b>%
            </div>
          )}
        </PrebidCurrent>
        <PrebidData>
          <Input
            control={control}
            name={'desiredVersion'}
            label={'New Prebid desired version'}
            required={true}
            rules={{
              pattern: { value: semanticVersionValidation, message: 'Set a valid version' },
            }}
            error={errors.desiredVersion && errors.desiredVersion.type === 'pattern' ? 'Set a valid Prebid version' : undefined}
          />
          <Input
            control={control}
            type={'number'}
            name={'desiredRatio'}
            label={'New Prebid desired ratio'}
            onKeyDown={(e) => {
              if (['e', 'E', '-', '+', ',', '.'].includes(e.key)) {
                e.preventDefault()
              }
            }}
            rules={{
              required: true,
              validate: {
                max: (value: number | string) => (Number(value) <= 100 ? true : 'Should be lower or equal 100'),
                min: (value: number | string) => (Number(value) > 0 ? true : 'Should be greater than 0'),
              },
            }}
            required={true}
            min={1}
            max={100}
            iconRight={'percent'}
          />
          <Button disabled={!desiredVersion || !desiredRatio} type={'submit'}>
            Save
          </Button>
          <div>
            {errors.desiredRatio && (
              <ErrorMessage>
                <Icon name={'alert'} width={'0.875rem'} />
                {errors.desiredRatio?.message}
              </ErrorMessage>
            )}
          </div>
        </PrebidData>
      </form>
      <h2>Scope config</h2>
      <JsonTextArea {...props} spellCheck={false} className={className} value={paramsJsonValue} rows={25} disabled={true} />
    </Wrapper>
  ) : (
    <IllustrationMessage iconName={'night_sky'} iconSize={'190px'} title={'There is no scope configuration.'}>
      {newScopeConfigButton}
    </IllustrationMessage>
  )
}

export const PureAdStackScopeConfig = styled(_PureAdStackScopeConfig)``
