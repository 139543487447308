import { FunctionComponent, useEffect } from 'react'
import { useHubspotChatToken } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { useBasePath } from '~/utils/useBasePath'

const hubspotChatSrc = '//js-na1.hs-scripts.com/4565186.js'

const HUBSPOT_DIV_ID = 'hubspot-messages-iframe-container'
const HUBSPOT_CLOSE_BUTTON_ID = 'hubspot-messages-iframe-container-close'

const toggleWidget = (visibility: 'visible' | 'hidden') => {
  const hubspotButton = document.getElementById(HUBSPOT_DIV_ID)
  if (hubspotButton) {
    hubspotButton.style.visibility = visibility
  }
}

const createHubspotCloseButton = () => {
  const hubspotCloseButton = document.createElement('div')
  hubspotCloseButton.onclick = () => toggleWidget('hidden')
  hubspotCloseButton.id = HUBSPOT_CLOSE_BUTTON_ID
  hubspotCloseButton.textContent = 'x'
  hubspotCloseButton.style.display = 'none'
  hubspotCloseButton.style.visibility = 'hidden'
  hubspotCloseButton.style.position = 'absolute'
  hubspotCloseButton.style.top = '6px'
  hubspotCloseButton.style.right = '15px'
  hubspotCloseButton.style.cursor = 'pointer'
  hubspotCloseButton.style.color = 'rgb(0, 164, 189)'
  hubspotCloseButton.hidden = true
  return hubspotCloseButton
}

export const HubspotChat: FunctionComponent = () => {
  const { byId: createHubspotToken } = useHubspotChatToken(null)
  const user = useUser()
  const basePath = useBasePath()
  useEffect(() => {
    window.hsConversationsSettings = {
      loadImmediately: false,
    }

    const script = document.createElement('script')
    script.src = hubspotChatSrc
    script.async = true
    script.id = 'hs-script-loader'
    document.body.appendChild(script)
    window._hsq = window._hsq || []
    window._hsq.push([
      'identify',
      {
        email: user?.profile.email,
      },
    ])
    const getToken = async () => {
      const hubspotToken = await createHubspotToken.post({ email: user?.profile.email })
      window.hsConversationsSettings = {
        identificationEmail: user?.profile.email,
        identificationToken: hubspotToken.token,
      }

      window.HubSpotConversations.widget.load()
      window.HubSpotConversations.on('widgetLoaded', () => {
        const hubspotButton = document.getElementById(HUBSPOT_DIV_ID)
        if (hubspotButton) {
          const hubspotCloseButton = createHubspotCloseButton()
          hubspotButton.appendChild(hubspotCloseButton)
          hubspotButton.onmouseover = function () {
            hubspotCloseButton.style.display = 'inline-block'
            hubspotCloseButton.style.visibility = 'visible'
          }
          hubspotButton.onmouseleave = function () {
            hubspotCloseButton.style.display = 'none'
            hubspotCloseButton.style.visibility = 'hidden'
          }
        }
      })

      window.HubSpotConversations.on('userInteractedWithWidget', () => {
        const hubspotCloseButton = document.getElementById(HUBSPOT_CLOSE_BUTTON_ID)
        if (hubspotCloseButton) {
          hubspotCloseButton.style.display = 'none'
          hubspotCloseButton.style.visibility = 'hidden'
        }
      })
    }

    getToken()

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    // everytime the user goes to a new page, we make sure to put the widget visible again
    toggleWidget('visible')
  }, [basePath])

  return null
}
