import styled from '@emotion/styled'
import { CurrencyCode, CurrencySymbol, currencies } from '@pubstack/common/src/currency'
import { AdminScope } from '@pubstack/common/src/scope'
import { TIMEZONES } from '@pubstack/common/src/timezone'
import { ChangeEventHandler, FunctionComponent, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ApiResponse } from '~/api/api-access'
import { Colors } from '~/assets/style/colors'
import { Sizes } from '~/assets/style/tokens'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input, _Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { PageContent } from '~/components/PageContent'
import { Select } from '~/components/Select'
import { useToast } from '~/components/Toast/useToasts'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { handleTableSearchAndSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'

type PureAdminScopeProps = {
  scopes: ApiResponse<AdminScope[]>
  createScope: (scope: Omit<AdminScope, 'id' | 'enabled'>) => Promise<void>
  updateScope: (scope: AdminScope) => Promise<void>
  archiveScope: (scope: AdminScope) => Promise<void>
  getScope: (scopeId: AdminScope['id']) => Promise<AdminScope>
  onUploadMarketplace: (file: File) => void
  unarchiveScope: (scope: AdminScope) => Promise<void>
  onDeliveryClick: () => void
  onSnoozeAlarmClick: () => void
  onScopeRowClick: (scope: AdminScope) => void
  downloadScopeCSV: { onClick: () => void; isDownloading: boolean }
  downloadUserCSV: { onClick: () => void; isDownloading: boolean }
  onCreatePubstackUser: (email: string) => void

  breadcrumbs?: React.ReactNode
}

type NewPubstackUserForm = {
  userEmail: string
}

const AdminAction = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  margin: 16px 0;

  ${_Input} {
    width: 490px;
  }

  ${Button} {
    flex: 0 1 auto;
    margin-left: 20px;
  }
`

const CreateOrUpdateForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 34px;
`

const ActionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 40px);

  * ~ * {
    margin-left: 16px;
  }
`

const DataIngestionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const toCurrencySymbol = (currency: CurrencyCode): CurrencySymbol => {
  return currencies[currency]
}

type NewAdminScope = Omit<AdminScope, 'id' | 'enabled'>

const CreateOrUpdateModal: FunctionComponent<{
  scope?: AdminScope
  update: (scope: AdminScope) => Promise<void>
  create: (scope: NewAdminScope) => Promise<void>
}> = ({ scope, update, create }) => {
  const { close } = useGlobalModal()
  const currenciesT = (Object.keys(currencies) as CurrencyCode[]).map((currencyCode) => ({
    value: currencyCode,
    label: `${currencyCode} - ${currencies[currencyCode]}`,
  }))

  const defaultValues: NewAdminScope = {
    name: '',
    currencyCode: 'EUR',
    pbjs: 'pbjs',
    features: ['analytics', 'abtest'],
    viewabilityEnabled: true,
    refreshEnabled: false,
    standaloneUserSessionEnabled: true,
    sessionTrackingDisabled: true,
    kleanadsEnabled: false,
    smartEnabled: false,
    requestAnalyticsSent: false,
    gamKeyNamesForPrebid: ['hb_pb', 'hb_deal', 'hbv_pb', 'bid_cpm'],
    timezone: 'Europe/Paris',
    mfaRequired: false,
    ...scope,
  }
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AdminScope>({ defaultValues, mode: 'onChange' })

  const onSubmit: SubmitHandler<AdminScope> = async (newScope) => {
    await (scope ? update(newScope) : create(newScope))
    close()
  }
  return (
    <Modal.Content>
      <Modal.Title>{scope ? 'Update scope' : 'New scope'}</Modal.Title>

      <Modal.Body>
        <CreateOrUpdateForm id={'newScopeForm'} onSubmit={handleSubmit(onSubmit)}>
          <Input type={'text'} label={'Scope name'} control={control} name={'name'} error={errors.name?.message} rules={{ required: 'Please fill this to continue' }} />
          <Select control={control} name={'currencyCode'} options={currenciesT} label={'Scope currency'} searchable={true} />
          <Select
            control={control}
            name={'timezone'}
            label={'Timezone ID'}
            options={TIMEZONES.map((t) => ({ label: t, value: t }))}
            error={errors.timezone?.message}
            rules={{ required: 'Please fill this to continue' }}
            searchable={true}
          />
          <Input type={'text'} control={control} name={'pbjs'} label={'pbjs variable'} />
        </CreateOrUpdateForm>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>{scope ? 'Update' : 'Create scope'}</Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const ArchiveScopeModal: FunctionComponent<{
  scope: AdminScope
  onArchive: (scope: AdminScope) => Promise<void>
}> = ({ scope, onArchive }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Archive scope</Modal.Title>

      <Modal.Body>
        You are about to archive <strong>{scope.name}</strong>. We will not collect data anymore. <br />
        Do you want to archive it?
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button
          variant={'negative'}
          onClick={async () => {
            await onArchive(scope)
            close()
          }}
        >
          Archive
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const MarketplaceUpload: FunctionComponent<{
  onUploadMarketplace: (file: File) => void
}> = ({ onUploadMarketplace }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const toast = useToast()
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    if (event.target.files) {
      const data = await event.target.files[0].text()
      try {
        JSON.parse(data)
        onUploadMarketplace(event.target.files[0])
      } catch (e) {
        toast.alert('The JSON is not valid')
      }
      event.target.value = ''
    }
  }

  return (
    <>
      <Button variant={'secondary'} iconName={'upload'} onClick={() => inputRef.current?.click()}>
        Upload marketplace JSON
      </Button>
      <input style={{ display: 'none' }} onChange={handleFileChange} ref={inputRef} type={'file'} accept={'application/json'} />
    </>
  )
}
const AddPubstackUserModal: FunctionComponent<{
  onCreatePubstackUser: (email: string) => void
}> = ({ onCreatePubstackUser }) => {
  const { close } = useGlobalModal()
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<NewPubstackUserForm>({
    defaultValues: {
      userEmail: '',
    },
  })

  const onSubmitPubstackUser: SubmitHandler<NewPubstackUserForm> = ({ userEmail }) => {
    onCreatePubstackUser(userEmail)
    close()
    reset()
  }

  return (
    <Modal.Content>
      <Modal.Title>Add pubstack user</Modal.Title>

      <Modal.Body>
        <form id={'newPubstackUserForm'}>
          <Input
            type={'email'}
            control={control}
            rules={{ required: 'Please fill this to continue', pattern: /.+@pubstack\.io/ }}
            name={'userEmail'}
            error={
              (errors.userEmail && errors.userEmail.type === 'pattern' ? 'The email should end with pubstack.io' : undefined) ||
              (errors.userEmail && errors.userEmail.type === 'required' ? 'Please fill this to continue' : undefined)
            }
            label={'email'}
            required
            isMessageBlock
          />
        </form>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmitPubstackUser)}>Add user</Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

const PureAdminScopePage: FunctionComponent<PureAdminScopeProps> = ({
  scopes,
  createScope,
  updateScope,
  getScope,
  onDeliveryClick,
  onSnoozeAlarmClick,
  downloadScopeCSV,
  downloadUserCSV,
  onScopeRowClick,
  onCreatePubstackUser,
  onUploadMarketplace,
  archiveScope,
  unarchiveScope,
  breadcrumbs,
}) => {
  const modal = useGlobalModal()
  const [search, setSearch] = useState('')
  const [columns, setColumns] = useState<TableColumns<AdminScope>>([
    {
      name: 'Name',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'Currency',
      isSortable: true,
      order: 'none',
      attributeSort: 'currencyCode',
    },
    {
      name: 'Timezone',
      isSortable: true,
      order: 'none',
      attributeSort: 'timezone',
    },
    {
      name: 'Prebid var',
      isSortable: true,
      order: 'none',
      attributeSort: 'pbjs',
    },
    {
      name: 'Data Ingestion',
      isSortable: false,
    },
    {
      name: 'Action',
      isSortable: false,
    },
  ])

  const sortAndGroup = (scopes: AdminScope[]) => handleTableSearchAndSort(columns, scopes, search, ['name'])
  const groupedScopes = [...(scopes.data || [])].reduce(
    (acc, scope) => ({
      ...acc,
      [`${scope.enabled}`]: [...(acc[`${scope.enabled}`] || []), scope],
    }),
    {} as { [key: string]: AdminScope[] }
  )
  const displayedScopes = [...sortAndGroup(groupedScopes['true']), ...sortAndGroup(groupedScopes['false'])]

  const openCreateOrUpdateScopeModal = (scope?: AdminScope) => {
    modal.open(CreateOrUpdateModal, {
      scope,
      update: updateScope,
      create: createScope,
    })
  }

  const onEdit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, scope: AdminScope) => {
    event.preventDefault()
    event.stopPropagation()
    const s = await getScope(scope.id)
    openCreateOrUpdateScopeModal(s)
  }
  return (
    <PageContent
      breadcrumbs={breadcrumbs}
      headerActions={
        <>
          <MarketplaceUpload onUploadMarketplace={onUploadMarketplace} />
          <Button variant={'secondary'} iconName={'group'} onClick={() => modal.open(AddPubstackUserModal, { onCreatePubstackUser })}>
            Add pubstack user
          </Button>
          <Button
            variant={'secondary'}
            onClick={downloadUserCSV.onClick}
            disabled={downloadUserCSV.isDownloading}
            iconSpin={downloadUserCSV.isDownloading}
            iconName={downloadUserCSV.isDownloading ? 'pubstack' : 'download'}
          >
            Download CSV user
          </Button>
          <Button
            variant={'secondary'}
            onClick={downloadScopeCSV.onClick}
            disabled={downloadScopeCSV.isDownloading}
            iconSpin={downloadScopeCSV.isDownloading}
            iconName={downloadScopeCSV.isDownloading ? 'pubstack' : 'download'}
          >
            Download CSV scopes
          </Button>
          <Button variant={'secondary'} iconName={'shipping'} onClick={onDeliveryClick}>
            Delivery
          </Button>
          <Button variant={'secondary'} iconName={'snooze'} onClick={onSnoozeAlarmClick}>
            Snooze Alarm
          </Button>
        </>
      }
    >
      <AdminAction>
        <_Input type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search by name...'} onChange={(event) => setSearch(event.target.value)} />
        <Button variant={'primary'} onClick={() => openCreateOrUpdateScopeModal()}>
          New scope
        </Button>
      </AdminAction>

      <Table columns={columns} isLoading={scopes.loading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {displayedScopes.map((scope) => (
          <TableRow archived={!scope.enabled} key={scope.id} onClick={() => onScopeRowClick(scope)}>
            <TableCell>{scope.name}</TableCell>
            <TableCell>
              {toCurrencySymbol(scope.currencyCode)} ({scope.currencyCode})
            </TableCell>
            <TableCell>{scope.timezone}</TableCell>
            <TableCell>{scope.pbjs}</TableCell>
            <TableCell>
              <DataIngestionContainer>
                <Tooltip title={'Viewability'}>
                  <Icon name={'view'} fill={scope.viewabilityEnabled ? undefined : Colors.Silver} />
                </Tooltip>
                <Tooltip title={'Refresh'}>
                  <Icon name={'refresh'} fill={scope.refreshEnabled ? undefined : Colors.Silver} />
                </Tooltip>
                <Tooltip title={'User Session'}>
                  <Icon name={'group'} fill={scope.standaloneUserSessionEnabled ? undefined : Colors.Silver} />
                </Tooltip>
                <Tooltip title={'Smart'}>
                  <Icon name={'smart'} css={{ '--overrideColor': scope.smartEnabled ? undefined : Colors.Silver }} width={Sizes['20']} />
                </Tooltip>
              </DataIngestionContainer>
            </TableCell>
            <TableCell>
              <ActionGrid>
                {scope.enabled ? (
                  <>
                    <Button iconName={'edit'} variant={'tertiary'} onClick={(e) => onEdit(e, scope)} />
                    <Button
                      iconName={'archive'}
                      variant={'tertiary'}
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        modal.open(ArchiveScopeModal, { scope, onArchive: archiveScope })
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div />
                    <Button
                      iconName={'unarchive'}
                      variant={'tertiary'}
                      onClick={async (event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        await unarchiveScope(scope)
                      }}
                    />
                  </>
                )}
              </ActionGrid>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </PageContent>
  )
}

export default PureAdminScopePage
