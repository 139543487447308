import styled from '@emotion/styled'
import { PrebidModuleNames, Provider, ProviderJSON } from '@pubstack/common/src/catalogItem'
import { Site } from '@pubstack/common/src/tag'
import { ID_MODULES_DEFAULT_PARAMETERS, IdModuleNames, UserSyncUserId, UserSyncUserIdJSON } from '@pubstack/common/src/userSync'
import { ComponentType, ReactNode, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { PageContent } from '~/components/PageContent'
import { Status } from '~/components/Status'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import Table, { TableColumns, onColumnSort, handleTableSearchAndSort } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import { TableCollapsibleRow, TableCollapsibleRowChevronColumn } from '~/components/table/TableCollapsibleRow'
import { PureCatalogItemHeader } from '~/modules/adstack/inventory/catalog/PureCatalogItemHeader'
import { WithClassName } from '~/types/utils'
import { PureAdStackPrebidModuleHelpFlyout } from './PureAdStackPrebidModuleHelpFlyout'
import { JSONEditorStatus, PureAdStackPrebidModuleJSONEditor } from './PureAdStackPrebidModuleJSONEditor'

const Filter = styled.div`
  display: flex;
  margin: 10px 0;
`

const SettingsStatus = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  & > ${Tooltip} {
    display: inline-flex;
    align-items: center;

    & > ${Icon} {
      color: ${Colors.SlateGrey};
    }
  }
`
export const JSON_TEMPLATES: {
  [ModuleName in PrebidModuleNames]: ModuleName extends IdModuleNames ? UserSyncUserIdJSON<ModuleName> : ProviderJSON<ModuleName>
} = {
  ...ID_MODULES_DEFAULT_PARAMETERS,
  ias: {
    params: {
      pubId: '0',
    },
  },
  weborama: { params: {} },
  greenbidsRtdProvider: { params: { pbuid: '' } },
  permutive: { params: { acBidders: [] } },
  SirdataRTDModule: { params: { partnerId: 1, key: 1, setGptKeyValues: true, actualUrl: '', contextualMinRelevancyScore: 30, bidders: [] } },
  oxxionRtd: { params: { domain: '', threshold: false, samplingRate: 1 } },
  geoedge: { params: { key: '' } },
  anonymised: { params: { cohortStorageKey: 'cohort_ids', bidders: [], segtax: 1000 } },
}

export type DataBySite<ModuleName extends PrebidModuleNames> = {
  siteName: Site['name']
  siteId: Site['id']
  moduleData: (ModuleName extends IdModuleNames ? Omit<UserSyncUserId<ModuleName>, 'name'> : Omit<Provider<ModuleName>, 'name'>) | undefined
}

export type AdditionalComponentProps = { siteModule: DataBySite<PrebidModuleNames> }

export type PureAdStackPrebidModulePageProps<ModuleName extends PrebidModuleNames> = WithClassName & {
  code?: ModuleName
  data: DataBySite<ModuleName>[]
  breadcrumbs: ReactNode
  helpText: ReactNode
  isLoading: boolean
  updateSiteData: (code: ModuleName, data: DataBySite<ModuleName>) => void
  baseCDNUrl: string
  additionalColumns?: [TableColumns<DataBySite<ModuleName>>[number], ComponentType<AdditionalComponentProps>][]
  onBackClick: () => void
}

const _PureAdStackPrebidModulePage = <ModuleName extends PrebidModuleNames>({
  className,
  breadcrumbs,
  code,
  data,
  helpText,
  isLoading,
  updateSiteData,
  baseCDNUrl,
  additionalColumns = [],
  onBackClick,
}: PureAdStackPrebidModulePageProps<ModuleName>) => {
  const additionalHeaders = additionalColumns.map(([header]) => header)
  const additionalComponents = additionalColumns.map(([, cmp]) => cmp)
  const flyout = useGlobalFlyout()
  const [columns, setColumns] = useState<TableColumns<DataBySite<ModuleName>>>([
    {
      name: 'Site',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'siteName',
    },
    {
      name: 'JSON settings',
      isSortable: true,
      order: 'none',
      attributeSort: 'moduleData',
      sort: function (a: DataBySite<ModuleName>, b: DataBySite<ModuleName>) {
        // userId attribute is used to determine if a site identity is configured or not
        const [isA, isB] = [!!a['moduleData'], !!b['moduleData']]
        // sorting by "true" first
        return (this.order === 'ascending' ? 1 : -1) * (isA === isB ? 0 : isA ? -1 : 1)
      },
    },
    ...additionalHeaders,
    TableCollapsibleRowChevronColumn,
  ])

  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({})

  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })
  const search = useWatch({ control, name: 'search' })

  const sortedData = handleTableSearchAndSort(columns, data, search, ['siteName'])

  // if any site settings is used, the module is used
  const isUsed = data.some((data) => !!data.moduleData)

  // track errors or pending changes on each site settings
  const [siteSettingsStatus, setSiteSettingsStatus] = useState<{ [name in DataBySite<ModuleName>['siteName']]?: JSONEditorStatus }>(Object.fromEntries(sortedData.map((id) => [id.siteName, {}])))

  return (
    <PageContent
      className={className}
      breadcrumbs={breadcrumbs}
      headerActions={
        <>
          <Button variant={'tertiary'} onClick={onBackClick}>
            Back
          </Button>
          <Button
            variant={'tertiary'}
            onClick={() =>
              flyout.open(
                PureAdStackPrebidModuleHelpFlyout,
                {
                  customCheckText: helpText,
                },
                { color: Colors.Ming }
              )
            }
            iconName={'help'}
          >
            Help
          </Button>
        </>
      }
    >
      <PureCatalogItemHeader catalogItemCode={code} catalogItemType={'prebidModule'} isLoading={isLoading} isActive={isUsed} baseCDNUrl={baseCDNUrl} />

      <Filter>
        <Input type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} name={'search'} control={control} />
      </Filter>

      <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {sortedData.map((siteModule) => (
          <TableCollapsibleRow
            key={siteModule.siteName}
            isOpen={isOpen[siteModule.siteName] ?? false}
            onToggleCollapse={() => {
              setIsOpen((previousState) => ({
                ...previousState,
                [siteModule.siteName]: !previousState[siteModule.siteName],
              }))
            }}
            content={
              code
                ? () => (
                    <PureAdStackPrebidModuleJSONEditor
                      statusUpdate={(updatedStatus) =>
                        setSiteSettingsStatus({
                          ...siteSettingsStatus,
                          [siteModule.siteName]: updatedStatus,
                        })
                      }
                      params={siteModule.moduleData ?? JSON_TEMPLATES[code]}
                      onValidate={(data) => {
                        updateSiteData(code, {
                          siteName: siteModule.siteName,
                          siteId: siteModule.siteId,
                          moduleData: data as DataBySite<ModuleName>['moduleData'],
                        })
                      }}
                    />
                  )
                : null
            }
          >
            <TableCell>{siteModule.siteName}</TableCell>
            <TableCell>
              <SettingsStatus>
                <Status state={siteModule.moduleData ? 'active' : 'inactive'}>{siteModule.moduleData ? 'Used' : 'Not used'}</Status>
                {siteSettingsStatus[siteModule.siteName]?.dirty && (
                  <Tooltip title={'Pending changes'}>
                    <Icon width={'16px'} name={'draft'} />
                  </Tooltip>
                )}
                {siteSettingsStatus[siteModule.siteName]?.error && (
                  <Tooltip title={'Error in JSON settings'}>
                    <Icon width={'16px'} name={'alert'} />
                  </Tooltip>
                )}
              </SettingsStatus>
            </TableCell>
            {additionalComponents.map((Component, index) => (
              <Component siteModule={siteModule} key={index} />
            ))}
          </TableCollapsibleRow>
        ))}
      </Table>
    </PageContent>
  )
}

export const PureAdStackPrebidModulePage = _PureAdStackPrebidModulePage
