import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { ExploreDimData, MappedName } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useMemo } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsBreakdownObj, useAnalyticsBreakdownObj } from '~/modules/analytics/AnalyticsBreakdownObj'
import { AnalyticsDataTableConfigs } from '~/modules/analytics/analyticsDataTableObj.hooks'
import { ExploreFormData, ExploreFormulasConfig } from '~/modules/analytics/explore/explore'
import { WithClassName } from '~/types/utils'

type PureExploreBreakdownWidgetProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    onRowClick: (name: MappedName) => void
    onSortChange: (metric: string) => void
    rawData?: ExploreDimData<any>
    formData: ExploreFormData
    currencySymbol: CurrencySymbol
    exploreFormulasConfig: ExploreFormulasConfig
  }
const _PureExploreBreakdownWidget: FunctionComponent<PureExploreBreakdownWidgetProps> = ({ rawData, formData, currencySymbol, exploreFormulasConfig, ...props }) => {
  const metricNames = useMemo(() => formData.metrics.map((metric) => Object.entries(exploreFormulasConfig).find(([, { formula }]) => formula.name === metric)?.[0] ?? ''), [formData.metrics])

  const breakdownProps = useAnalyticsBreakdownObj<any>({
    rawData,
    currencySymbol,
    onTabChange: () => {},
    getColumnsConfigs: () => metricNames,
    getConfigs: () =>
      [
        [
          {
            displayedAs: 'text',
            sortLabel: 'Site name',
          },
          ...metricNames.map((metricName) => {
            const notApplicableDimension = (exploreFormulasConfig[metricName]?.dimensionNotApplicable || []).includes(formData.dimension as Dimension)
            return {
              propertyName: metricName,
              formula: exploreFormulasConfig[metricName].formula,
              tooltip: exploreFormulasConfig[metricName].formula.tooltip,
              isComputable: () => !notApplicableDimension,
            }
          }),
        ],
      ] as AnalyticsDataTableConfigs<any>,
    tabsConfig: [{ dimension: formData.dimension as Dimension, label: '', iconName: 'empty' }],
  })
  return <AnalyticsBreakdownObj {...props} {...breakdownProps} />
}
export const PureExploreBreakdownWidget = styled(_PureExploreBreakdownWidget)``
