import { Dimension } from '@pubstack/common/src/analytics/dimension'
import User from '~/auth/User'
import {
  auctionCountObj,
  auctionFillRateObj,
  auctionRPMObj,
  averageViewedTimeObj,
  bidCpmObj,
  bidRPMObj,
  bidRateObj,
  bidTimeoutRateObj,
  bidWinCpmObj,
  bidWinRateObj,
  bidWinRpmObj,
  eCPMObj,
  impressionCountObj,
  impressionPerPageObj,
  impressionPerSessionObj,
  impressionRPMObj,
  impressionRateObj,
  impressionRevenueObj,
  pageRpmObj,
  pageViewObj,
  pageViewPerSessionObj,
  sessionCountObj,
  sessionRpmObj,
  viewabilityMRCObj,
} from '~/modules/analytics/formulas'
import { FormulaObj } from '~/modules/analytics/formulas/operation-obj'

export type ExploreFormData = {
  metrics: string[]
  dimension: string
  type: { dimension: 'time' | 'dim'; seriesType: 'bars' | 'line' }
  limit: number
  order: 'DESC' | 'ASC'
  others?: boolean
}

export type DataDistributionType = 'percentage' | 'number'
export type ExploreFormulasConfig = { [key: string]: { formula: FormulaObj<any>; category: string; dimensionNotApplicable?: Dimension[] } }

export const getExploreFormulasConfig = (user: User | null): ExploreFormulasConfig => {
  return {
    auctionCount: { formula: auctionCountObj, category: 'Monetization' },
    auctionRPM: { formula: auctionRPMObj, category: 'Monetization' },
    impressionCount: { formula: impressionCountObj, category: 'Monetization' },
    fillRate: { formula: auctionFillRateObj, category: 'Monetization' },
    impressionCpmSum: { formula: impressionRevenueObj, category: 'Monetization' },
    impressionRPM: { formula: impressionRPMObj, category: 'Monetization' },
    impressionRate: {
      formula: impressionRateObj,
      category: 'Monetization',
      dimensionNotApplicable: ['tagId', 'adUnit', 'size', 'device', 'country', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion'],
    },
    eCPM: { formula: eCPMObj, category: 'Monetization' },
    ...(user?.hasFeature('viewability')
      ? { viewabilityMRC: { formula: viewabilityMRCObj, category: 'Monetization' }, averageViewedTime: { formula: averageViewedTimeObj, category: 'Monetization' } }
      : {}),
    bidRate: { formula: bidRateObj, category: 'Bid', dimensionNotApplicable: ['tagId', 'adUnit', 'size', 'device', 'country', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion'] },
    bidWinRate: { formula: bidWinRateObj, category: 'Bid', dimensionNotApplicable: ['tagId', 'adUnit', 'size', 'device', 'country', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion'] },
    bidTimeoutRate: { formula: bidTimeoutRateObj, category: 'Bid', dimensionNotApplicable: ['tagId', 'adUnit', 'size', 'device', 'country', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion'] },
    bidCpm: { formula: bidCpmObj, category: 'Bid', dimensionNotApplicable: ['tagId', 'adUnit', 'size', 'device', 'country', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion'] },
    bidWinCpm: { formula: bidWinCpmObj, category: 'Bid', dimensionNotApplicable: ['tagId', 'adUnit', 'size', 'device', 'country', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion'] },
    bidRPM: { formula: bidRPMObj, category: 'Bid', dimensionNotApplicable: ['tagId', 'adUnit', 'size', 'device', 'country', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion'] },
    bidWinRpm: { formula: bidWinRpmObj, category: 'Bid', dimensionNotApplicable: ['tagId', 'adUnit', 'size', 'device', 'country', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion'] },
    ...(user?.hasFeature('userSession') || user?.hasFeature('standaloneUserSession')
      ? {
          sessionCount: { formula: sessionCountObj, category: 'Traffic' },
          sessionRpm: { formula: sessionRpmObj, category: 'Traffic' },
          impressionPerSession: { formula: impressionPerSessionObj, category: 'Traffic' },
          impressionPerPage: { formula: impressionPerPageObj, category: 'Traffic' },
          pageViewPerSession: { formula: pageViewPerSessionObj, category: 'Traffic' },
          pageView: { formula: pageViewObj, category: 'Traffic' },
          pageRpm: { formula: pageRpmObj, category: 'Traffic' },
        }
      : {}),
  }
}
