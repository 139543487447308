import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { PrebidConfig } from '@pubstack/common/src/prebidConfig'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { PageContent } from '~/components/PageContent'
import { Skeleton } from '~/components/Skeleton'
import Table, { handleTableSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import { TableCollapsibleRow, TableCollapsibleRowChevronColumn } from '~/components/table/TableCollapsibleRow'
import { PureCatalogItemHeader } from '~/modules/adstack/inventory/catalog/PureCatalogItemHeader'
import { WithClassName } from '~/types/utils'
import { PureAdStackPrebidSiteConfigForm } from './PureAdStackPrebidSiteConfigForm'

const SiteGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`
const SiteTitle = styled(TableCell)`
  font-weight: bold;
`
type PureAdStackPrebidIntegrationPageProps = WithClassName & {
  breadcrumbs: React.ReactNode
  isLoading: boolean
  prebidConfig: PrebidConfig
  onCreateSiteConfig: (siteConfig: SiteConfig) => Promise<void>
  baseCDNUrl: string
  bidderAdapters: BidderAdapter[]
  onBackClick: () => void
}

const _PureAdStackPrebidIntegrationPage: FunctionComponent<PureAdStackPrebidIntegrationPageProps> = ({
  className,
  breadcrumbs,
  isLoading,
  prebidConfig,
  onCreateSiteConfig,
  baseCDNUrl,
  bidderAdapters,
  onBackClick,
}) => {
  const [columns, setColumns] = useState<TableColumns<Site>>([
    {
      name: 'Site',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    TableCollapsibleRowChevronColumn,
  ])

  const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({})

  const { reset } = useForm<PrebidConfig>({
    defaultValues: prebidConfig,
  })

  const sortedSites = handleTableSort(columns, prebidConfig.sites)

  useEffect(() => {
    reset({
      ...prebidConfig,
    })
  }, [prebidConfig])

  return (
    <PageContent
      className={className}
      breadcrumbs={breadcrumbs}
      headerActions={
        <Button variant={'tertiary'} onClick={onBackClick}>
          Back
        </Button>
      }
    >
      <PureCatalogItemHeader isLoading={isLoading} baseCDNUrl={baseCDNUrl} isActive={true} catalogItemType={'prebidWrapper'} catalogItemCode={'prebid'} />
      <SiteGroup>
        {!isLoading ? (
          prebidConfig.sites.length > 0 ? (
            <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
              {sortedSites.map((site) => {
                const siteConfig = prebidConfig.siteConfigs.find((s) => site.id === s.siteId)
                return (
                  <TableCollapsibleRow
                    key={site.id}
                    isOpen={isOpen[site.id] ?? false}
                    onToggleCollapse={() => {
                      setIsOpen((previousState) => ({ ...previousState, [site.id]: !previousState[site.id] }))
                    }}
                    content={() => (
                      <PureAdStackPrebidSiteConfigForm
                        siteId={site.id}
                        siteConfig={siteConfig}
                        scopeId={prebidConfig.scopeId}
                        onCreateSiteConfig={(data) => {
                          onCreateSiteConfig(data)
                        }}
                        bidderAdapters={bidderAdapters}
                      />
                    )}
                  >
                    <SiteTitle>{site.name}</SiteTitle>
                  </TableCollapsibleRow>
                )
              })}
            </Table>
          ) : (
            <IllustrationMessage iconName={'night_sky'} iconSize={'190px'} title={'You don’t have any sites yet.'} />
          )
        ) : (
          <Skeleton bigger width={'100%'} />
        )}
      </SiteGroup>
    </PageContent>
  )
}

export const PureAdStackPrebidIntegrationPage = styled(_PureAdStackPrebidIntegrationPage)``
