import styled from '@emotion/styled'
import { FC } from 'react'
import { Fonts } from '~/assets/style/fonts'
import { Modal } from './Modal'
import { Spinner } from './Spinner'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${Fonts.P1};
  font-weight: 500;
  height: 150px;
`
const LoadingSpinner = styled(Spinner)`
  margin-top: 20px;
  width: 43px;
  height: 43px;
`

const _LoadingModal: FC = () => {
  return (
    <Modal.Content>
      <Modal.Body>
        <ContentWrapper>
          <div>Loading changes...</div>
          <div>Please stay on the page</div>
          <LoadingSpinner isColored={true} />
        </ContentWrapper>
      </Modal.Body>
    </Modal.Content>
  )
}
export const LoadingModal = styled(_LoadingModal)``
