import { useMemo } from 'react'
import { useLocation, useMatches, useNavigate, useSearchParams } from 'react-router-dom'
import { PubstackRouteMeta } from '~/auth/ProtectedRoute'
import { useUser } from '~/auth/user.hooks'
import { BreadcrumbProps, Breadcrumbs } from '~/components/Breadcrumbs'

export const useBreadcrumbs = <T,>(additionalParams?: T) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [search] = useSearchParams()
  const matches = useMatches()
  const user = useUser()

  const breadcrumbsMatches = matches.filter((match) => {
    return !!(match.handle as PubstackRouteMeta).breadcrumbLabel || !!(match.handle as PubstackRouteMeta).breadcrumbSubtitle
  })

  const breadcrumbs: BreadcrumbProps[] = breadcrumbsMatches
    .map((match, index, full) => {
      let label = ''
      const metaData = match.handle as PubstackRouteMeta<T>
      if (metaData) {
        const breadcrumbLabel = metaData.breadcrumbLabel
        if (breadcrumbLabel) {
          if (typeof breadcrumbLabel === 'string') {
            label = breadcrumbLabel
          } else {
            label = breadcrumbLabel({ search, additionalParams })
          }
        }
      }
      return {
        label,
        route: full.length - 1 !== index ? match.pathname : undefined,
      }
    })
    .filter(({ label }) => !!label)

  const breadcrumbSubtitle = (breadcrumbsMatches.pop()?.handle as PubstackRouteMeta<T>).breadcrumbSubtitle
  const breadcrumbSubtitleComputed = breadcrumbSubtitle && (typeof breadcrumbSubtitle === 'string' ? breadcrumbSubtitle : breadcrumbSubtitle({ search, additionalParams, user: user ?? undefined }))
  return useMemo(() => <Breadcrumbs data={breadcrumbs} subtitle={breadcrumbSubtitleComputed} onClick={({ route }) => route && navigate(route)} />, [location, search, additionalParams])
}
