import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { AnalyticsQueryDashboard, ExploreDataObj, ExploreDimData } from '@pubstack/common/src/analytics/query'
import { currencies } from '@pubstack/common/src/currency'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useLocalStorage } from 'usehooks-ts'
import { useAnalyticsQuery } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { AnalyticsPage } from '~/modules/analytics/AnalyticsPage'
import { contextState, currentScopeState } from '~/state'
import { WithClassName } from '~/types/utils'
import { filterDimensionsByUserFeatures, getTimePresets, logSortBreakdownAction, onBreakdownRowClick } from '~/utils/analytics'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureExploreWidget } from './PureExploreWidget'
import { type ExploreFormData, getExploreFormulasConfig } from './explore'

type ExplorePageProps = WithClassName
const _ExplorePage: FunctionComponent<ExplorePageProps> = ({ className }) => {
  const logger = useLogger()
  const dimensions: Dimension[] = ['tagId', 'bidder', 'adUnit', 'device', 'country', 'size', 'pubstackRefresh', 'abTestPopulation', 'stackIdVersion']
  const dashboard: AnalyticsQueryDashboard = { name: 'explore', filterType: 'auctions' }
  const today = DateTime.now().startOf('day')
  const timePresets = getTimePresets(today)
  const [context, setContext] = useRecoilState(contextState)
  const [formData, setFormData] = useLocalStorage<ExploreFormData>('exploreViewDashboardDataV2', {
    metrics: [],
    dimension: 'tagId',
    type: { dimension: 'time', seriesType: 'bars' },
    limit: 10,
    order: 'DESC',
    others: true,
  })
  const [rawData, setRawData] = useState<ExploreDataObj<any> | undefined>(undefined)
  const [rawDataBreakdown, setRawDataBreakdown] = useState<ExploreDimData<any> | undefined>(undefined)
  const {
    byId: { post, loading, error, abort },
  } = useAnalyticsQuery<any>(null, dimensions, context)
  const {
    byId: { post: postBreakdown, loading: loadingBreakdown, error: errorBreakdown, abort: abortBreakdown },
  } = useAnalyticsQuery<any>(null, dimensions, context)
  const currentScope = useRecoilValue(currentScopeState)
  const currencySymbol = currencies[currentScope.currencyCode]
  const user = useUser()
  const breadcrumbs = useBreadcrumbs()
  const exploreFormulasConfig = useMemo(() => getExploreFormulasConfig(user), [user])

  const loadDataBreakdown = async (data: ExploreFormData) => {
    if (data.metrics.length === 0) {
      return
    }
    if (loadingBreakdown) {
      abortBreakdown()
    }
    const response = await postBreakdown(`explore.metrics.by.${data.type.dimension}`, data)
    return response.values
  }

  const loadDataChart = async (data: ExploreFormData) => {
    if (data.metrics.length === 0) {
      return
    }
    if (loading) {
      abort()
    }
    const response = await post(`explore.metrics.by.${data.type.dimension}`, data)
    return response.values
  }

  const loadData = async (data: ExploreFormData) => {
    const parameters: ExploreFormData = {
      ...data,
      metrics: data.metrics.map((d) => Object.entries(exploreFormulasConfig).find(([, { formula }]) => formula.name === d)?.[0] ?? ''),
    }
    if (data.type.dimension === 'dim') {
      const values = await loadDataChart({ ...parameters, limit: 10 })
      setRawData(values)
      setRawDataBreakdown(values)
    } else {
      const [values, valuesBreakdown] = await Promise.all([
        loadDataChart({ ...parameters, limit: 10 }),
        loadDataBreakdown({ ...parameters, type: { dimension: 'dim', seriesType: 'bars' }, limit: 50 }),
      ])
      setRawData(values)
      setRawDataBreakdown(valuesBreakdown)
    }
    setFormData(data)
  }

  useEffect(() => {
    setTimeout(() => {
      loadData(formData)
    }) //TODO vma cfo nra - HACK! useFetch is deleting the abort controller in the wrong order, and only has one ref to the abort controller
  }, [context])

  return (
    <AnalyticsPage
      className={className}
      onRefreshClick={() => setContext({ ...context })}
      timePresets={timePresets}
      filterDimensions={dimensions}
      dashboard={dashboard}
      comparedTo={true}
      title={breadcrumbs}
    >
      <PureExploreWidget
        onSubmit={loadData}
        rawData={rawData}
        rawDataBreakdown={rawDataBreakdown}
        formData={formData}
        exploreFormulasConfig={exploreFormulasConfig}
        dimensions={filterDimensionsByUserFeatures(dimensions, user)}
        isLoading={loading}
        error={!!error}
        empty={!rawData || !Object.keys(rawData.mappings).length}
        onRefreshClick={() => {
          /**/
        }}
        currencySymbol={currencySymbol}
        onBreakdownRowClick={onBreakdownRowClick(logger, formData.dimension as Dimension, setContext, !rawDataBreakdown)}
        onBreakdownSortChange={logSortBreakdownAction(logger)}
      />
    </AnalyticsPage>
  )
}
export const ExplorePage = styled(_ExplorePage)``
