import { AdUnit } from './adunit'
import { ConditionGroup } from './stackContext'

export type SyncCategory =
  | 'Ad units'
  | 'Refresh rule'
  | 'Lazy loading rule'
  | 'Floor price rule'
  | 'Header bidding rule'
  | 'GAM config'
  | 'Integrations rule'
  | 'Prebid module'
  | 'Prebid config'
  | 'Prebid bidder'
  | 'TAM config'
  | 'Refresh global settings'
export type SyncElement = { name: string; changes: string[] }

type StackMetaData = {
  id: string
  name: string
  stackId: string
  siteId: string
  version?: number
  status: 'live' | 'commit' | 'draft'
  elementsToSync: { category: SyncCategory; elements: SyncElement[] }[]
  enabled: boolean
}

export type StackData = {
  contextId?: string
  conditions?: ConditionGroup
  desktopAdUnits?: AdUnitConfig[] // TODO NRA LES not relevant to have optional when conversion from Dynamo give at least an empty array? (cf. toStack backend method)
  mobileAdUnits?: AdUnitConfig[] // TODO NRA LES not relevant to have optional when conversion from Dynamo give at least an empty array? (cf. toStack backend method)
  headerBiddingEnabled?: boolean
  lazyLoadingRules?: LazyLoadingRuleConfig[]
  floorsRules?: AdunitDeviceRuleConfig[]
  refreshRules?: AdunitDeviceRuleConfig[]
  headerBiddingRuleId?: string
  adCallTimeout: number
  commitMessage?: string
  siteConfigId?: string
  priority?: number
  ratio?: number
}

export type Stack = StackMetaData & StackData

export type StackGroup = {
  priority: number
  groupName: string
  stackId: string
  stacks: Stack[]
}

export type LazyLoadingRuleConfig = {
  device: string
  ruleId: string
}

export type AdUnitConfig = {
  adUnitId: string
  roadblock?: RoadblockType
}

export enum RoadblockType {
  Master = 'master',
  Companion = 'companion',
}

export type AdunitDeviceRuleConfig = {
  device: string
  ruleId: string
  adUnitIds: string[]
}

export type SaveStack = Omit<Stack, 'id' | 'stackId' | 'status' | 'version' | 'creationTime' | 'elementsToSync' | 'conditions' | 'priority' | 'enabled'> & {
  saveAsDraft: boolean
}

export type LazyLoadingRule = {
  id: string
  scopeId?: string
  creationTime: number
  name: string
  prebidAuctionViewportsPercent?: number
  googletagFetchViewportsPercent?: number
  googletagRenderViewportsPercent?: number
  googletagMobileScaling?: number
  headerBiddingEnabled?: boolean
  gamEnabled?: boolean
  stacksUse?: StacksBySite // Stacks using this rule
  technicalId: string
}

export type FloorRule = {
  id: string
  scopeId?: string
  creationTime: number
  floor: number
  name?: string
  stacksUse?: StacksBySite // Stacks using this rule
  technicalId?: string
}

export type RefreshRule = {
  id: string
  name: string
  technicalId: string
  scopeId?: string
  creationTime: number
  cappingEnabled?: boolean
  capping?: number
  timer: number
  stacksUse?: StacksBySite // Stacks using this rul
}

export type HeaderBiddingRule = {
  id: string
  scopeId: string
  creationTime: number
  bidderNames: string[]
  isBlockList: boolean
  name: string
  technicalId: string
  usedInStacks?: StacksBySite // Stacks using this rule, because stacksUse is not very explicit
}

export type AdmLiveStack = {
  id: string
  stackId: string
  scopeId: string
  siteId: string
  versions: AdmLiveStackVersion[]
  priority?: number
}

type AdmLiveStackVersion = {
  version: number
  ratio?: number
}

export type StackWithRatio = {
  id: Stack['id']
  ratio: number
}

export type StacksBySite = {
  [siteName: string]: {
    stackName: string
    version: string
    enabled: boolean
  }[]
}

export type SiteStacksByProperty = {
  [propertyId: string]: StacksBySite
}

const RESERVED_STACK_NAME = 'default'

export const isStackNameReserved = (name: string): boolean => {
  return RESERVED_STACK_NAME === name.toLowerCase().trim()
}

export type MultiStackSynchronizePayload = { siteId: string; stackId: Stack['id'] }[]
export type MultiStacksDeployPayload = {
  siteId: string
  stackId: Stack['stackId']
  id: Stack['id']
  ratio: Stack['ratio']
}[]

export type DisplayedStack = {
  id: string
  name: string
  contextName: string
  conditions?: ConditionGroup
  stackId: string
  siteName: string
  siteId?: string
  version?: number
  status: 'live' | 'commit' | 'draft'
  desktopAdUnits: AdUnit[]
  mobileAdUnits: AdUnit[]
  headerBiddingEnabled?: boolean
  adCallTimeout: number
  adServer: string
  prebidBidders?: DisplayedBidder[]
  prebidModules?: string[]
  lazyLoadingDesktopRule?: LazyLoadingRule
  lazyLoadingMobileRule?: LazyLoadingRule
  floorPriceDesktopRules?: DisplayedFloorRule[]
  floorPriceMobileRules?: DisplayedFloorRule[]
  refreshDesktopRules?: DisplayedRefreshRule[]
  refreshMobileRules?: DisplayedRefreshRule[]
  headerBiddingRule?: DisplayedHeaderBiddingRule
  commitMessage?: string
  elementsToSync?: { category: SyncCategory; elements: SyncElement[] }[]
  ratio?: number
}

export type DisplayedBidder = {
  bidderName: string
  desktop: string[]
  mobile: string[]
}

export type DisplayedRule = {
  ruleName: string
  adUnitNames: string[]
}

export type DisplayedFloorRule = DisplayedRule & {
  floor: string
}

export type DisplayedRefreshRule = DisplayedRule

export type DisplayedHeaderBiddingRule = {
  ruleName: string
  bidderNames: string[]
  isBlockList: boolean
}

export const EMPTY_DISPLAYED_STACK: DisplayedStack = {
  name: '',
  id: '',
  siteName: '',
  adCallTimeout: 0,
  stackId: '',
  status: 'draft',
  adServer: '',
  contextName: '',
  desktopAdUnits: [],
  mobileAdUnits: [],
  ratio: 0,
  elementsToSync: [],
  prebidBidders: [],
  headerBiddingRule: { ruleName: 'All active bidders', bidderNames: [], isBlockList: true },
}

export const buildStackTemplates = (stacks: Stack[]) => {
  const templates: Stack[] = []
  stacks.forEach((stack) => {
    const found = templates.find((t) => t.name === stack.name && t.siteId === stack.siteId)
    if (!found) {
      templates.push(stack)
    }
    if (found && (found.version ?? 0) < (stack.version ?? 0)) {
      templates.splice(templates.indexOf(found), 1, stack)
    }
  }) // list will contain only the last version of each stack name

  return templates.filter((a) => a.enabled) // no archived stack
}
