import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, ReactElement, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Sizes } from '~/assets/style/tokens'
import { ScrollbarStyle } from '~/assets/style/utils'
import Button from '~/components/Button'
import { WithClassName } from '~/types/utils'

const TitleWrapper = styled.div`
  margin: 0 12px;
  padding: 6px 0;
  ${Fonts.colors.Jet};
  ${Fonts.H2};
  font-weight: 500;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  border-bottom: 1px solid ${Colors.Silver};
`

const Title = styled.span`
  flex: 1 0 auto;
`

const Toggle = styled(Button)`
  opacity: 0;
  color: ${Colors.SlateGrey};
  background-color: ${Colors.White};
  &:hover {
    color: ${Colors.SlateGrey};
    background: ${Colors.Platinum};
  }
  span[name='menu_extend'] {
    transform: rotate(-180deg);
  }
`

const ToggleWrapper = styled.div``

const FilterSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-left: 1px solid ${Colors.Platinum};
  background-color: #fff;
  overflow: hidden;
  flex: 1;

  &:hover ${Toggle} {
    opacity: 1;
  }
`

const FilterSidebarCategoriesWrapper = styled.div`
  flex: 1;
  max-width: 100%;
  min-width: 100%;
`

const FilterSidebarCategoryExpandedWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  flex-direction: column;
`
const FilterSidebarContentWrapper = styled.div<{ expanded: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  ${ScrollbarStyle};
  transition: transform 0.3s ease-out;
  transform: translateX(0%);
  flex: 1;

  ${({ expanded }) =>
    expanded &&
    css`
      transform: translate(-100%);
      overflow: visible;

      ${FilterSidebarCategoriesWrapper} {
        flex: 0;
        max-height: 0;
      }
    `}
`

export type FilterSidebarProps = WithClassName & {
  categories: ReactNode
  expandedCategory?: ReactElement
  isPinned: boolean
  onPin: () => void
}
const _FilterSidebar: FunctionComponent<FilterSidebarProps> = ({ className, categories, expandedCategory, isPinned, onPin }) => {
  return (
    <FilterSidebarWrapper className={className}>
      <TitleWrapper>
        <Title>Filters</Title>
        <ToggleWrapper>
          <Toggle variant={'tertiary'} iconName={isPinned ? 'menu_extend' : 'keep'} onClick={onPin} iconSize={Sizes['20']} />
        </ToggleWrapper>
      </TitleWrapper>
      <FilterSidebarContentWrapper expanded={!!expandedCategory}>
        <FilterSidebarCategoriesWrapper>{categories}</FilterSidebarCategoriesWrapper>
        <FilterSidebarCategoryExpandedWrapper>{expandedCategory}</FilterSidebarCategoryExpandedWrapper>
      </FilterSidebarContentWrapper>
    </FilterSidebarWrapper>
  )
}

export const FilterSidebar = styled(_FilterSidebar)``
