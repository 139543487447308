import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { FallbackProps } from 'react-error-boundary/dist/declarations/src/types'
import { Colors } from '~/assets/style/colors'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { Link } from '~/components/Link'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div`
  background-color: ${Colors.Ghost};
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
`

type GlobalErrorPageProps = WithClassName &
  FallbackProps & {
    //
  }
const _GlobalErrorPage: FunctionComponent<GlobalErrorPageProps> = ({ className }) => {
  const refresh = () => window.location.reload()
  const clear = () => {
    window.localStorage.clear()
    refresh()
  }
  return (
    <Wrapper>
      <IllustrationMessage
        className={className}
        iconName={'night_sky'}
        iconSize={'190px'}
        title={'Oh no, the app is not feeling good'}
        message={
          <>
            <div>An error occurred, and we couldn't automatically recover from it.</div>
            <div>
              You can try to <Link onClick={refresh} label={'refresh'} /> the page or <Link onClick={clear} label={'clear the cache'} />.
            </div>
            <div>
              If the problem persist, contact Pubstack's support: <Link target={'_blank'} href={'mailto:support@pubstack.io'} label={'support@pubstack.io'} />
            </div>
          </>
        }
      />
    </Wrapper>
  )
}
export const GlobalErrorPage = styled(_GlobalErrorPage)``
