import styled from '@emotion/styled'
import { NonNullable } from '@pubstack/common/src/assertion'
import {
  ChangeEvent,
  ChangeEventHandler,
  type ClipboardEventHandler,
  FocusEventHandler,
  ForwardedRef,
  FunctionComponent,
  KeyboardEventHandler,
  cloneElement,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'
import { Color, ColorUtil, Colors } from '~/assets/style/colors'
import { WithClassName } from '~/types/utils'
import mergeRefs from '~/utils/mergeRefs'
import { fastHash } from '~/utils/string'
import Chip, { ChipProps } from './Chip'
import { _Input as BaseInput, BaseInputProps } from './Input'
import { SelectOptionProp, SelectableOptionsPopover } from './SelectableOptionsPopover'

const KEYCODE = {
  SPACE: 'Space',
  ENTER: 'Enter',
  NUMPADENTER: 'NumpadEnter',
  DELETE: 'Backspace',
} as const

const ChipInputWrapper = styled.div``

const ChipsWrapper = styled.div<{ disabled: boolean; charCount?: number; variant?: 'regular' | 'small' }>`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  gap: ${({ variant }) => (variant === 'small' ? '5px 2px' : '8px 4px')};
  align-items: center;
  align-content: center;
  background-color: ${({ disabled }) => (disabled ? Colors.Ghost : Colors.White)};
  padding: ${({ variant }) => (variant === 'small' ? '4px 0' : '10px 0')};
  
  & > input {
    padding: 0;
    width: ${({ charCount }) => (charCount ? `${charCount * 1}ch` : '1ch')};
  }
`

export type ChipListInputChangeEvent = Omit<ChangeEvent<HTMLInputElement>, 'target'> & { target: Omit<ChangeEvent<HTMLInputElement>['target'], 'value'> & { value: string[] } }
export type ChipListInputChangeEventHandler = (event: ChipListInputChangeEvent) => void
export type ChipListChipRenderer = (chip: ChipProps, renderingContext: { value: string; index: number }) => React.ReactElement<ChipProps>

/**
 * @param
 * @param chipRenderer Allows for custom rendering of each chip. Passes the default props and the rendering context (text value and index in the list)
 * @see PureAdStackContextKeyEditPage to see real use-case of chipRenderer
 */
type BaseChipInputProps = Omit<BaseInputProps, 'value' | 'onChange'> & {
  value: string[]
  maxChipCount?: number
  chipColor?: Color
  isColorVariable?: boolean
  colorVariability?: number
  chipRenderer?: ChipListChipRenderer
  isSpaceAllowed?: boolean
  canRemoveValues?: boolean
  forceChipsRemovableFromIndex?: number
  selectableOptions?: SelectOptionProp[]
  onChange?: ChipListInputChangeEventHandler
  showMultiLevelAsCategory?: boolean
  OptionContentComponent?: FunctionComponent<{ option: SelectOptionProp<unknown> }>
}
const _BaseChipInput = (
  {
    className,
    value,
    maxChipCount = 100,
    onChange,
    chipColor,
    isColorVariable,
    colorVariability,
    chipRenderer,
    isSpaceAllowed,
    canRemoveValues = true,
    forceChipsRemovableFromIndex,
    selectableOptions,
    showMultiLevelAsCategory,
    OptionContentComponent,
    ...props
  }: BaseChipInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [chips, setChips] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string | undefined>('')
  const [isSelectableOptionsPopoverOpen, setIsSelectableOptionsPopoverOpen] = useState(false)
  const internalRef = useRef<HTMLInputElement>(null)

  const isSelectableOptionsEnabled = !!selectableOptions
  const basicFuzzySearchPattern = `.*${inputValue
    ?.toLocaleLowerCase()
    ?.split('')
    .map((char) => ('()[]{}.-*?+.^\\|$'.includes(char) ? `\\${char}` : char))
    .join('.*')}`
  const basicFuzzyRegex = new RegExp(basicFuzzySearchPattern)
  const filter = (option: SelectOptionProp) =>
    !chips.includes(option.value as string) && (basicFuzzyRegex.test((option.value as string).toLocaleLowerCase()) || basicFuzzyRegex.test(option.label.toLocaleLowerCase()))
  const filteredSelectableOptions = selectableOptions
    ?.map((option) => {
      if (option.value instanceof Array) {
        const filtered = { ...option, value: option.value.filter(filter) }
        return filtered.value.length ? filtered : undefined
      }
      return filter(option) ? option : undefined
    })
    .filter(NonNullable)

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (chips.length < maxChipCount) {
      setInputValue(event.target.value)
      onChange && onChange({ ...event, target: { ...event.target, value: chips } })
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    switch (event.code) {
      case KEYCODE.ENTER:
      case KEYCODE.NUMPADENTER:
        event.preventDefault()
        addChip((event.target as HTMLInputElement).value)
        break
      case KEYCODE.SPACE:
        if (!isSpaceAllowed) {
          event.preventDefault()
          addChip((event.target as HTMLInputElement).value)
        }
        break
      case KEYCODE.DELETE:
        !inputValue?.length && deleteChip(chips.length - 1)
        break

      default:
        if (selectableOptions?.length) {
          setIsSelectableOptionsPopoverOpen(true)
        }
    }
  }
  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    // If the input is selectable, the onBlur is simulated by the onSelectablePopoverOpenChange method
    if (!isSelectableOptionsEnabled) {
      addChip(event.target.value)
    }

    props.onBlur && props.onBlur(event)
  }

  const handleOnPaste: ClipboardEventHandler<HTMLInputElement> = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const data = event.clipboardData.getData('text')
    const values = (data || '').trim().split(/[\r\n\t]+/g)
    addChips(values)
  }

  const canDeleteChip = (index: number): boolean => {
    return index >= 0 && (canRemoveValues || index >= (forceChipsRemovableFromIndex ?? chips.length))
  }

  const deleteChip = (index: number) => {
    if (canDeleteChip(index)) {
      setChips(chips.filter((_, i) => i !== index))
    }
  }

  const canAddChip = (value: string): boolean => {
    if (chips.length >= maxChipCount) {
      return false
    }
    if (isSelectableOptionsEnabled) {
      // can only add chips which are in options if options are enabled
      return !!filteredSelectableOptions
        ?.map((o) => (o.value instanceof Array ? o.value : o))
        .flat()
        ?.find((option) => option.value.toLowerCase() === value.toLowerCase())
    }
    return value.trim().length > 0
  }

  const addChip = (value: string) => {
    addChips([value])
  }

  const addChips = (values: string[]) => {
    if (values.every((value) => canAddChip(value))) {
      const parsedValues = values.flatMap((value) => (isSpaceAllowed ? [value.trim()] : value.trim().split(' ')))
      const v = parsedValues.map((value) => {
        // if selectable options are enabled, we want to add the value from the options
        if (isSelectableOptionsEnabled) {
          return (
            filteredSelectableOptions
              ?.map((o) => (o.value instanceof Array ? o.value : o))
              .flat()
              ?.find((option) => option.value.toLowerCase() === value.toLowerCase())?.value ?? value
          )
        }

        return value
      })

      setChips([...chips, ...v])
      clearInput()
    }
  }

  const clearInput = () => {
    setInputValue('')
  }

  const clearChips = () => {
    setChips([])
  }

  const clearAll = () => {
    clearInput()
    clearChips()
  }

  useEffect(() => {
    if (internalRef.current) {
      const newEvent = new Event('input')
      // assertion is our only avenue to type the fake event here
      onChange && onChange({ ...newEvent, target: { ...newEvent.target, ...internalRef.current, value: chips } } as unknown as ChipListInputChangeEvent)
    }
  }, [chips])

  useEffect(() => {
    // we only set the chips if the final value changes, otherwise it loops
    if ((chips ?? []).join() !== (value ?? []).join()) {
      setChips([...value])
    }
  }, [value])

  const getChipColor = (chipValue: string) => {
    if (chipColor) {
      if (isColorVariable) {
        const variation = fastHash(chipValue)
        return ColorUtil.varyColorLightness(chipColor, variation % (colorVariability ?? 30))
      }
      return chipColor
    }
    return Colors.Platinum
  }

  const defaultChipProps = (chip: string, index: number): ChipProps => ({
    color: getChipColor(chip),
    iconRight: props.disabled || !canDeleteChip(index) ? undefined : 'close',
    onRightIconClick: () => deleteChip(index),
    text: chip,
    variant: props.variant,
  })

  const chipInputWrapper = (
    <ChipInputWrapper className={className}>
      <BaseInput
        {...props}
        autoComplete={'off'}
        isLabelStatic={chips.length > 0 || isSelectableOptionsPopoverOpen}
        value={inputValue}
        onPaste={handleOnPaste}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        ref={mergeRefs([internalRef, ref])}
        onClear={canRemoveValues ? clearAll : undefined}
        isClearable={canRemoveValues && chips.length > 0}
        internalInputRenderer={(input) => (
          <ChipsWrapper charCount={inputValue?.length} disabled={!!props.disabled} variant={props.variant}>
            {chips.map((value, index) =>
              chipRenderer ? cloneElement(chipRenderer(defaultChipProps(value, index), { value, index }), { key: index }) : <Chip {...defaultChipProps(value, index)} key={index} />
            )}
            {input}
          </ChipsWrapper>
        )}
      />
    </ChipInputWrapper>
  )

  // Will add a chip if the type input correspond to one of the selectable options but the user click outside the popover
  const onSelectablePopoverOpenChange = (open: boolean) => {
    if (!open && isSelectableOptionsEnabled) {
      const find = (option: SelectOptionProp<string>) => option.value.toLowerCase() === inputValue?.toLowerCase()
      const chipValue = filteredSelectableOptions
        ?.map((o) => (o.value instanceof Array ? o.value : o))
        .flat()
        .find(find)?.value
      if (chipValue) {
        addChip(chipValue)
      }
    }
    setIsSelectableOptionsPopoverOpen(open)
  }

  return isSelectableOptionsEnabled ? (
    <SelectableOptionsPopover
      open={isSelectableOptionsPopoverOpen}
      setOpen={onSelectablePopoverOpenChange}
      onChange={(option) => {
        addChip(option.value as string)
      }}
      options={filteredSelectableOptions ?? []}
      allowMultipleSelection
      trigger={chipInputWrapper}
      showMultiLevelAsCategory={showMultiLevelAsCategory}
      OptionContentComponent={OptionContentComponent}
      maxWidthPopoverSameAsTrigger={true}
    />
  ) : (
    chipInputWrapper
  )
}

export const BaseChipInput = styled(forwardRef<HTMLInputElement, BaseChipInputProps>(_BaseChipInput))``

type ChipListInputProps<T extends FieldValues, U extends FieldPath<T>> = WithClassName &
  Omit<BaseChipInputProps, 'value' | 'onChange' | 'ref'> &
  Omit<UseControllerProps<T, U>, 'defaultValue'> & {
    onChange?: (values: string[]) => void
  }
export const ChipListInput = <T extends FieldValues, U extends FieldPath<T>>({ name, control, rules, shouldUnregister, ...props }: ChipListInputProps<T, U>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field: { ref, value, onChange, ...field } }) => {
        return (
          <BaseChipInput
            {...props}
            {...field}
            ref={ref}
            value={value}
            onChange={(v) => {
              if (v.target.value.length) {
                onChange(v.target.value)
                props.onChange?.(v.target.value)
              } else {
                if (value.length > 0) {
                  onChange([])
                  props.onChange?.([])
                }
              }
            }}
          />
        )
      }}
    />
  )
}
