import styled from '@emotion/styled'
import { AdUnit } from '@pubstack/common/src/adunit'
import { FunctionComponent, useEffect, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Flyout } from '~/components/Flyout'
import { AdUnitSelectionTable } from './components/AdUnitSelectionTable'

type PureStackSelectAdUnitsFlyoutProps = {
  title: string
  onValidate: (newAdUnits: AdUnit[]) => unknown
  selectableAdUnits: AdUnit[]
  selectedAdUnits: AdUnit[]
}

const Header = styled.div`
  ${Fonts.P1};
  ${Colors.Jet};
  font-style: italic;
  margin-bottom: 16px;
`

const _PureStackSelectAdUnitsFlyout: FunctionComponent<PureStackSelectAdUnitsFlyoutProps> = ({ title, onValidate, selectableAdUnits, selectedAdUnits }) => {
  const [selected, setSelected] = useState<AdUnit[]>(selectedAdUnits)
  useEffect(() => {
    setSelected(selectedAdUnits)
  }, [selectedAdUnits])

  return (
    <Flyout.Content>
      <Flyout.Title>{title}</Flyout.Title>
      <Flyout.Body>
        <Header>Ad unit ad server name and div ID must be unique: you can’t use 2 or more ad units sharing the same ad server name or div ID.</Header>
        <AdUnitSelectionTable selectableAdUnits={selectableAdUnits} selectedAdUnits={selected} onAdUnitsSelection={setSelected} />
      </Flyout.Body>
      <Flyout.Actions>
        <Button variant={'primary'} onClick={() => onValidate(selected)}>
          Validate selection
        </Button>
      </Flyout.Actions>
    </Flyout.Content>
  )
}

export const PureStackSelectAdUnitsFlyout = styled(_PureStackSelectAdUnitsFlyout)``
