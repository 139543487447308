import styled from '@emotion/styled'
import { BidderCatalog, BidderCatalogAlias } from '@pubstack/common/src/bidderCatalog'
import { BidderParamValidation, BidderParamValidationType } from '@pubstack/common/src/bidderParamValidation'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useAdUnits, useBidderCatalog, useBidderCatalogAlias, useBidderParams } from '~/api/adm-api.hook'
import { FetchError } from '~/api/api-access'
import { useUser } from '~/auth/user.hooks'
import { useToast } from '~/components/Toast/useToasts'
import { configurationState } from '~/state'
import { downloadCSVdata } from '~/utils/csv'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureAdStackIntegrationMapping } from './PureAdStackIntegrationMapping'
import { PureAdStackIntegrationPage } from './PureAdStackIntegrationPage'
import { PureAdStackIntegrationSpreadsheet } from './PureAdStackIntegrationSpreadsheet'
import { onBidderParamsUpdate, validateBidderParams } from './mappingTransfer/mappingUpdate'

const Wrapper = styled.div`
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const LoadingAdStackIntegrationMapping = () => {
  const emptyAlias: BidderCatalogAlias = { bidderLabel: '', bidderSource: '', bidderParams: [], isUsed: false, isOutstream: false, bidParams: [] }
  return (
    <PureAdStackIntegrationMapping
      alias={emptyAlias}
      isLoading={true}
      SpreadsheetElement={<PureAdStackIntegrationSpreadsheet alias={emptyAlias} validateBidderParams={() => []} onBidderParamsUpdate={() => null} />}
      onAliasSettingsUpdate={() => Promise.resolve()}
      isUploading={false}
      isMappingDownloading={false}
      isTemplateDownloading={false}
      onMappingDownload={() => null}
      onMappingUpload={() => null}
      isBidderSettingsLoading={false}
      canEditBidAdjustment={false}
    />
  )
}

export const getMappingUploadErrorMessages = (e: FetchError<{ bidderName: string; isUsed: boolean }>): string[] => {
  const errorPayload = JSON.parse(e.message).data
  if (typeof errorPayload === 'string') {
    return [errorPayload]
  }
  if ('validationErrors' in errorPayload && Array.isArray(errorPayload.validationErrors)) {
    return errorPayload.validationErrors.map((error: BidderParamValidation<BidderParamValidationType>) => {
      switch (error.type) {
        case BidderParamValidationType.DUPLICATE_ENTRY: {
          const e = error as BidderParamValidation<BidderParamValidationType.DUPLICATE_ENTRY>
          return `Duplicate entry on line ${e.line}. ${e.detail.siteName} - ${e.detail.device} - ${e.detail.adUnitName} already exists.`
        }
        // TODO - rbu tmu sle 2023-03-07+2024-02-27 handle error message in the front end instead of just displaying backend's
        case BidderParamValidationType.MISSING_LINES: {
          const e = error as BidderParamValidation<BidderParamValidationType.MISSING_LINES>
          return `Missing lines in the file. Expected ${e.detail.expectedLines} but got ${e.detail.actualLines}.`
        }
        case BidderParamValidationType.WRONG_TYPE: {
          const e = error as BidderParamValidation<BidderParamValidationType.WRONG_TYPE>
          return `Wrong type on line ${e.line} : ${e.detail.paramName} should be of type ${e.detail.expectedType}`
        }
        case BidderParamValidationType.MISSING_PARAMS: {
          const e = error as BidderParamValidation<BidderParamValidationType.MISSING_PARAMS>
          return `Missing a required parameter on line ${e.line} : ${e.detail.paramName}`
        }
        default:
          return `Unknown error : ${JSON.stringify(error)}`
      }
    })
  }
  return []
}

export const AdStackIntegrationPage: React.FunctionComponent = () => {
  const { STATIC_ASSETS_CDN } = useRecoilValue(configurationState)
  const [currentBidder, setCurrentBidder] = useState<BidderCatalog>()
  const [bidderAliases, setBidderAliases] = useState<(BidderCatalogAlias & { isOutstream: boolean })[]>()
  const [isIntegrationUsed, setIsIntegrationUsed] = useState<boolean>(false)

  const { integrationId } = useParams()
  const breadcrumbs = useBreadcrumbs({ integrationName: currentBidder?.displayName ?? '' })
  const toast = useToast()

  const { byId: bidderCatalogById } = useBidderCatalog(null)
  const { all: allBidderCatalogAlias, byId: bidderCatalogAliasById } = useBidderCatalogAlias(null)
  const bidderParamsAPI = useBidderParams(null)
  const { patchBiddersOutstream, outstreamLoading } = useAdUnits(null)

  const navigate = useNavigate()
  const user = useUser()
  const canEditBidAdjustment = !!(user?.getScopeRole() === 'owner' || user?.isAdmin)

  const getBidderFromCatalog = async () => {
    if (bidderCatalogById.loading) {
      bidderCatalogById.abort()
    }
    if (integrationId) {
      const res = await bidderCatalogById.get(integrationId)
      setCurrentBidder(res)
    }
  }

  const getBidderAliases = async () => {
    if (allBidderCatalogAlias.loading) {
      allBidderCatalogAlias.abort()
    }

    if (integrationId) {
      const oldBidderAliases = bidderAliases
      try {
        const aliases = await allBidderCatalogAlias.get(integrationId)
        setBidderAliases(aliases)
        setIsIntegrationUsed(aliases.some((alias) => alias.isUsed))
      } catch (e) {
        if (e instanceof FetchError) {
          // TODO tmu 2023-03-21 our error codes should be constants in common or something, no literal here
          if (e.name === 'KleanadsBuildConfig Not Found') {
            toast.alert('It seems that no configuration has been created for this scope. Please contact support team.', { icon: 'rocket' })
          } else {
            toast.alert(JSON.parse(e.message).data.message)
            setBidderAliases(oldBidderAliases)
          }
        }
      }
    }
  }

  const getPageData = async () => {
    getBidderFromCatalog()
    getBidderAliases()
  }

  const onAliasUpdate = async (updatedAlias: BidderCatalogAlias) => {
    setBidderAliases(bidderAliases?.map((alias) => (alias.bidderLabel === updatedAlias?.bidderLabel ? { ...updatedAlias } : alias)))
  }

  const onCreateAlias = async (value: string) => {
    if (integrationId) {
      if (bidderAliases?.find((ba) => ba.bidderLabel === value)) {
        toast.alert(`Duplicate bidder alias ${value}`)
        return
      }
      try {
        const res = await bidderCatalogAliasById.post(integrationId, value)
        setBidderAliases([...res, ...(bidderAliases ?? [])])
        toast.success(`Bidder alias for ${currentBidder?.displayName} created : ${value}`)
      } catch (e) {
        if (e instanceof FetchError) {
          toast.alert(JSON.parse(e.message).data.errorMessage)
        }
      }
    }
  }

  const onMappingUpload = async (alias: BidderCatalogAlias, file: File) => {
    toast.dismiss()
    try {
      const bidderName = alias.bidderLabel
      const res = await bidderParamsAPI.upload(bidderName, file)
      onAliasUpdate({ ...alias, isUsed: res.isUsed })
      toast.admSuccess(`${bidderName} integration`, true, navigate)

      if (!res.isUsed) {
        toast(`Your file contains no bid parameters. This will result in no integration for ${bidderName}.`, { duration: 10000 })
      }
      getPageData()
    } catch (e) {
      if (e instanceof FetchError) {
        const errorMessages = getMappingUploadErrorMessages(e)
        errorMessages.length > 10
          ? toast.alert('Too many errors to display. Please check you uploaded the right file and try again.')
          : errorMessages.forEach((message) => toast.alert(message, { duration: Infinity }))
      } else {
        toast.alert('An unknown error occurred while updating bidder params.', { duration: Infinity })
      }
    }
  }

  const onMappingDownload = async (alias: BidderCatalogAlias, options?: { fullMapping?: boolean }) => {
    const bidderName = alias.bidderLabel
    const res = await bidderParamsAPI.download(bidderName, options?.fullMapping)
    const downloadType = options?.fullMapping ? 'mapping' : 'template'
    downloadCSVdata(res, `${bidderName}-${downloadType}`)
  }

  const onAliasSettingsUpdate = async (alias: BidderCatalogAlias) => {
    try {
      await patchBiddersOutstream({ bidderName: alias.bidderLabel, outstream: alias.isOutstream })
      if (canEditBidAdjustment) {
        const adunitNames = Array.from(new Set(alias.bidderParams.filter((bP) => Object.keys(bP.params).length > 0).map((bP) => bP.adUnitName)))
        await bidderParamsAPI.setBidAjustment(alias.bidderLabel, adunitNames, alias.bidAdjustment)
      }
      onAliasUpdate(alias)
      toast.admSuccess(`${alias.bidderLabel} settings`, true)
    } catch (e) {
      toast.alert('An error occurred while updating bidders outstream information.')
    }
  }

  useEffect(() => {
    getPageData()
  }, [])

  useEffect(() => {
    if (bidderAliases?.length) {
      setIsIntegrationUsed(bidderAliases.some((alias) => alias.isUsed))
    } else {
      setIsIntegrationUsed(false)
    }
  }, [bidderAliases])

  const isCatalogLoading = bidderCatalogAliasById.loading || allBidderCatalogAlias.loading
  const isBidderSettingsLoading = outstreamLoading || bidderParamsAPI.bidAdjustmentLoading

  return (
    <>
      <PureAdStackIntegrationPage
        isLoading={isCatalogLoading}
        breadcrumbs={breadcrumbs}
        disableAliasing={currentBidder?.disableAliasing ?? false}
        integrationName={currentBidder?.code ?? ''}
        isIntegrationUsed={isIntegrationUsed}
        integrationAliases={bidderAliases}
        onCreateAlias={onCreateAlias}
        baseCDNUrl={STATIC_ASSETS_CDN}
        onBackClick={() => navigate(-1)}
      />
      <Wrapper>
        {bidderAliases?.length ? (
          bidderAliases.map((alias) => (
            <PureAdStackIntegrationMapping
              key={alias.bidderLabel}
              alias={alias}
              isLoading={isCatalogLoading}
              SpreadsheetElement={
                <PureAdStackIntegrationSpreadsheet
                  alias={alias}
                  validateBidderParams={(bidderParams) => validateBidderParams(bidderParams, currentBidder)}
                  onBidderParamsUpdate={(bidderParamsValue) => {
                    const result = onBidderParamsUpdate(alias, bidderParamsValue)
                    onMappingUpload(result[0], result[1])
                  }}
                />
              }
              onAliasSettingsUpdate={onAliasSettingsUpdate}
              isUploading={bidderParamsAPI.uploadLoading}
              isMappingDownloading={bidderParamsAPI.downloadMappingLoading}
              isTemplateDownloading={bidderParamsAPI.downloadTemplateLoading}
              onMappingDownload={(options) => onMappingDownload(alias, options)}
              onMappingUpload={(file) => {
                onMappingUpload(alias, file)
              }}
              isBidderSettingsLoading={isBidderSettingsLoading}
              canEditBidAdjustment={canEditBidAdjustment}
            />
          ))
        ) : (
          <LoadingAdStackIntegrationMapping />
        )}
      </Wrapper>
    </>
  )
}
