import styled from '@emotion/styled'
import { AD_UNIT_MEDIATYPES, type Mediatype } from '@pubstack/common/src/adFormat'
import { type AdUnit, getAdUnitMediatypes, isDeviceEnabled } from '@pubstack/common/src/adunit'
import type { AdUnitDevice } from '@pubstack/common/src/adunitDevice'
import type { SiteStacksByProperty } from '@pubstack/common/src/stack'
import { type FunctionComponent, type ReactNode, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { Input, _Input } from '~/components/Input'
import { PageContent } from '~/components/PageContent'
import { Qualifier } from '~/components/Qualifier'
import { Select } from '~/components/Select'
import type { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { handleTableSearch, handleTableSort, onColumnSort, type SortableColumn, type TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import type { WithClassName } from '~/types/utils'
import { PureAdstackAdUnitDetailsFlyout } from './PureAdStackAdUnitDetailsFlyout'
import { PureAdstackAdUnitHelpFlyout } from './PureAdStackAdUnitHelpFlyout'
import { PureArchiveAdUnitModal } from './PureArchiveAdUnitModal'
import { AdFormatMediatype } from './components/AdFormatCard'

type PureAdStackAdUnitsPageProps = WithClassName & {
  adUnits: AdUnit[]
  sitesStacks?: SiteStacksByProperty
  isLoading: boolean
  onCreateAdUnit: () => void
  onUpdateAdUnit: (adUnitId: string) => void
  onArchiveAdUnit: (adUnit: AdUnit) => void
  onExportAdUnits: () => void
  onOpenFlyout: () => void
  breadcrumbs: React.ReactNode
}

const ActionsTableCell = styled(TableCell)`
  width: 0px; /** necessary to get the last cell to shrink down to hug action buttons */
`

const ButtonActions = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
`

const AdUnitTableRow = styled(TableRow)<{ enabled: boolean }>`
  color: rgba(0, 0, 0, ${(props) => (props.enabled ? 1 : 0.4)});
  td {
    max-width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

const FilterAction = styled.form`
  margin: 12px 0px;
  display: flex;
  gap: 12px;
  ${_Input} {
    width: 40%;
    max-width: 592px;
  }
`

const TopActions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const TinyText = styled.p`
  ${Fonts.P3}
  Color: ${Colors.SlateGrey};
  font-weight: 400;
`

const DeviceIcons = styled.div`
  display: flex;
  gap: 8px;
`

const Settings = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

/**
 * special count for the "Devices" column sort
 */
const countDevices = (adUnit: AdUnit): number => {
  if (adUnit.devices.length === 0) return 0
  if (adUnit.devices.length === 2) return 3
  if (adUnit.devices.some((device) => device === 'desktop')) return 2
  return 1
}

/**
 * Special count for the "Settings" column
 * HB qualifier has the best weight
 * Then we look at mediatypes
 */
const countSettings = (adUnit: AdUnit): number => {
  const total = adUnit.headerBiddingEnabled ? 10 : 0
  const mediatypes = getAdUnitMediatypes(adUnit)
  if (mediatypes.length === AD_UNIT_MEDIATYPES.length) return total + AD_UNIT_MEDIATYPES.length + 1
  if (mediatypes.includes('Banner')) return total + 2
  if (mediatypes.includes('Outstream')) return total + 1
  return total
}

const _PureAdStackAdUnitsPage: FunctionComponent<PureAdStackAdUnitsPageProps> = ({
  className,
  adUnits,
  sitesStacks,
  isLoading,
  onOpenFlyout,
  onCreateAdUnit,
  onUpdateAdUnit,
  onArchiveAdUnit,
  onExportAdUnits,
  breadcrumbs,
}) => {
  const [columns, setColumns] = useState<TableColumns<AdUnit>>([
    {
      name: 'Ad unit',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'Technical ID (div ID)',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'divId',
    },
    {
      name: 'Devices',
      isSortable: true,
      order: 'none',
      attributeSort: 'devices',
    },
    {
      name: 'Settings',
      isSortable: true,
      order: 'none',
      attributeSort: 'headerBiddingEnabled',
    },
    {
      name: 'Actions',
      isSortable: false,
    },
  ])

  const flyout = useGlobalFlyout()
  const modal = useGlobalModal()
  const { control } = useForm<{
    search: string
    filteredDevices: AdUnitDevice | 'all'
    filteredMediatypes: Mediatype | 'all'
    archived: string | 'all'
  }>({
    defaultValues: {
      search: '',
      filteredDevices: 'all',
      filteredMediatypes: 'all',
      archived: 'all',
    },
  })

  const search = useWatch({ control, name: 'search' })
  const filteredDevices = useWatch({ control, name: 'filteredDevices' })
  const filteredMediatypes = useWatch({ control, name: 'filteredMediatypes' })
  const archived = useWatch({ control, name: 'archived' })

  const filterDeviceOptions: SelectOptionProp[] = [
    {
      label: 'All devices',
      value: 'all',
    },
    {
      label: 'Desktop',
      value: 'desktop',
    },
    {
      label: 'Mobile',
      value: 'mobile',
    },
  ]

  const filterMediatypesOptions: SelectOptionProp[] = [
    {
      label: 'All mediatypes',
      value: 'all',
    },
    {
      label: 'Banner',
      value: 'Banner',
    },
    {
      label: 'Outstream',
      value: 'Outstream',
    },
  ]

  const sortAndGroupAdUnits = (adUnits: AdUnit[]) => {
    const filteredByDeviceAdUnits = filteredDevices === 'all' ? adUnits : adUnits.filter((adUnit) => isDeviceEnabled(filteredDevices, adUnit.devices))
    const filteredByMediatypesAdUnits =
      filteredMediatypes === 'all' ? filteredByDeviceAdUnits : filteredByDeviceAdUnits.filter((adUnit) => getAdUnitMediatypes(adUnit).find((mediatype) => mediatype === filteredMediatypes))
    const filteredByActiveAdunits =
      archived === 'archived' ? filteredByMediatypesAdUnits.filter((s) => !s.enabled) : archived === 'active' ? filteredByMediatypesAdUnits.filter((s) => s.enabled) : filteredByMediatypesAdUnits
    const searchedAdUnits = handleTableSearch(filteredByActiveAdunits, search, ['name', 'id'])
    const sortedColumn = (columns.find((c) => c.isSortable && c.order !== 'none') || columns[0]) as SortableColumn<AdUnit>
    if (sortedColumn.attributeSort === 'devices') {
      return searchedAdUnits.sort((a, b) => (sortedColumn.order === 'ascending' ? 1 : -1) * (countDevices(b) - countDevices(a)))
    } else if (sortedColumn.attributeSort === 'headerBiddingEnabled') {
      return searchedAdUnits.sort((a, b) => (sortedColumn.order === 'ascending' ? 1 : -1) * (countSettings(b) - countSettings(a)))
    } else {
      return handleTableSort(columns, searchedAdUnits)
    }
  }
  const groupedAdUnits = [...(adUnits || [])].reduce<{
    [key: string]: AdUnit[]
  }>(
    (acc, adUnit) => ({
      ...acc,
      [`${adUnit.enabled}`]: [...(acc[`${adUnit.enabled}`] || []), adUnit],
    }),
    {}
  )
  const displayedAdUnits = [...sortAndGroupAdUnits(groupedAdUnits['true'] ?? []), ...sortAndGroupAdUnits(groupedAdUnits['false'] ?? [])]

  useEffect(() => {
    sortAndGroupAdUnits(adUnits)
  }, [])

  return (
    <PageContent
      className={className}
      breadcrumbs={breadcrumbs}
      headerActions={
        <>
          <Button variant={'tertiary'} iconName={'help'} onClick={() => flyout.open(PureAdstackAdUnitHelpFlyout, undefined, { color: Colors.Turquoise })}>
            Help
          </Button>
          {adUnits?.length > 0 && (
            <Button iconName={'add_adUnit'} variant={'primary'} onClick={onCreateAdUnit}>
              Add ad unit
            </Button>
          )}
        </>
      }
    >
      {isLoading || adUnits?.length > 0 ? (
        <>
          <TopActions>
            <FilterAction>
              <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
              <Select name={'filteredDevices'} options={filterDeviceOptions} label={''} control={control} />
              <Select name={'filteredMediatypes'} options={filterMediatypesOptions} label={''} control={control} />
              <Select
                label={'Filter'}
                labelIsPlaceholder
                name={'archived'}
                control={control}
                options={[
                  { value: 'all', label: 'All ad units' },
                  { value: 'active', label: 'Active ad units' },
                  { value: 'archived', label: 'Archived ad units' },
                ]}
                isClearable={false}
              />
            </FilterAction>
            <div>
              <Button variant={'tertiary'} onClick={onExportAdUnits} iconName={'download'}>
                Export ad units
              </Button>
            </div>
          </TopActions>

          <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
            {displayedAdUnits.map((adUnit) => (
              <AdUnitTableRow
                key={adUnit.id}
                enabled={adUnit.enabled}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  flyout.open(
                    PureAdstackAdUnitDetailsFlyout,
                    {
                      adUnit,
                      onEditAdUnit: (adUnitId) => {
                        flyout.close()
                        onUpdateAdUnit(adUnitId)
                      },
                      sitesStacks,
                    },
                    { color: Colors.Turquoise, width: `816px` }
                  )
                  onOpenFlyout()
                }}
              >
                <TableCell>
                  {adUnit.name}
                  <TinyText>{adUnit.adServerAdUnitName}</TinyText>
                </TableCell>
                <TableCell>{adUnit.divId}</TableCell>
                <TableCell>
                  <DeviceIcons>
                    <Qualifier
                      iconName={'desktop'}
                      tooltipText={isDeviceEnabled('desktop', adUnit.devices) ? 'Desktop enabled' : 'Desktop disabled'}
                      color={Colors.Petrol}
                      active={isDeviceEnabled('desktop', adUnit.devices)}
                      enabled={adUnit.enabled}
                    />
                    <Qualifier
                      iconName={'mobile'}
                      tooltipText={isDeviceEnabled('mobile', adUnit.devices) ? 'Mobile enabled' : 'Mobile disabled'}
                      color={Colors.Pool}
                      active={isDeviceEnabled('mobile', adUnit.devices)}
                      enabled={adUnit.enabled}
                    />
                  </DeviceIcons>
                </TableCell>
                <TableCell>
                  <Settings>
                    <Qualifier
                      iconName={'header_bidding'}
                      tooltipText={adUnit.headerBiddingEnabled ? 'Header bidding enabled' : 'Header bidding disabled'}
                      color={Colors.Success}
                      active={adUnit.headerBiddingEnabled}
                      enabled={adUnit.enabled}
                    />

                    {AD_UNIT_MEDIATYPES.map<ReactNode>((mediatype) => (
                      <AdFormatMediatype key={mediatype} isPresent={adUnit.enabled && getAdUnitMediatypes(adUnit).includes(mediatype)}>
                        {mediatype}
                      </AdFormatMediatype>
                    )).reduce((prev, curr) => [prev, ' | ', curr])}
                  </Settings>
                </TableCell>
                <ActionsTableCell>
                  <ButtonActions>
                    <Tooltip title={'Edit'}>
                      <Button
                        iconName={'edit'}
                        variant={'tertiary'}
                        title={'Edit'}
                        disabled={!adUnit.enabled}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          onUpdateAdUnit(adUnit.id)
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={'View'}>
                      <Button
                        iconName={'view'}
                        variant={'tertiary'}
                        title={'View'}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          flyout.open(
                            PureAdstackAdUnitDetailsFlyout,
                            {
                              adUnit,
                              onEditAdUnit: (adUnitId) => {
                                flyout.close()
                                onUpdateAdUnit(adUnitId)
                              },
                              sitesStacks,
                            },
                            { color: Colors.Turquoise, width: '816px' }
                          )
                          onOpenFlyout()
                        }}
                      />
                    </Tooltip>
                    {adUnit.enabled ? (
                      <Tooltip title={'Archive'}>
                        <Button
                          iconName={'archive'}
                          variant={'tertiary'}
                          title={'Archive'}
                          disabled={!adUnit.enabled}
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            if (sitesStacks?.[adUnit.name]) {
                              modal.open(PureArchiveAdUnitModal, {
                                onArchive: async () => {
                                  await onArchiveAdUnit(adUnit)
                                  modal.close()
                                },
                                adUnitName: adUnit.name,
                              })
                            } else {
                              onArchiveAdUnit(adUnit)
                            }
                          }}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={'Unarchive'}>
                        <Button
                          iconName={'unarchive'}
                          variant={'tertiary'}
                          title={'Unarchive'}
                          disabled={adUnit.enabled}
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            onArchiveAdUnit(adUnit)
                          }}
                        />
                      </Tooltip>
                    )}
                  </ButtonActions>
                </ActionsTableCell>
              </AdUnitTableRow>
            ))}
          </Table>
        </>
      ) : (
        <IllustrationMessage
          iconName={'night_sky'}
          iconSize={'190px'}
          title={'It seems that no ad unit has been connected to your inventory.'}
          message={'Add your ad units to the inventory so you can monetize them through Pubstack.'}
        >
          <Button iconName={'add_adUnit'} variant={'primary'} onClick={onCreateAdUnit}>
            Add ad unit
          </Button>
        </IllustrationMessage>
      )}
    </PageContent>
  )
}

export const PureAdStackAdUnitsPage = styled(_PureAdStackAdUnitsPage)``
