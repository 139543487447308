import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { BorderRadius } from '~/assets/style/tokens'
import { Flyout } from '~/components/Flyout'

type PureAdStackIdentityModuleHelpFlyoutProps = {
  customCheckText: ReactNode
}

const HelpText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    margin-bottom: 12px;
  }

  ul,
  ol {
    margin: 0;
    margin-left: 20px;
    padding: 0;
  }

  code {
    ${BorderRadius.style};
    padding: 1px 8px;
    background-color: ${Colors.Platinum};
    user-select: all;
  }
`

const _PureAdStackPrebidModuleHelpFlyout: FunctionComponent<PureAdStackIdentityModuleHelpFlyoutProps> = ({ customCheckText }) => (
  <Flyout.Content>
    <Flyout.Title>Help</Flyout.Title>
    <Flyout.Body>
      <HelpText>
        <div>
          <h2>How can I integrate a Prebid module to my site?</h2>
          <ol>
            <li>Select your site from the list to expand it.</li>
            <li>Fill the required params in the form.</li>
            <li>Validate your informations.</li>
            <li>Apply the changes to use the module in production.</li>
          </ol>
        </div>

        <div>
          <h2>How can I manage specific configuration?</h2>
          <p>If you need do go further in module integration, you can click on Edit JSON and fill all optional parameters you might use.</p>
        </div>

        <div>
          <h2>Where can I find the module information?</h2>
          <ul>
            <li>Consult corresponding documentation on modules provider’s website.</li>
            <li>Contact directly your account manager’s partner or support.</li>
          </ul>
        </div>

        <div>
          <h2>How to ensure the module is well set on my site?</h2>
          <p>
            You can visite your site, click on F12 to open browser dev tool. In console tab, type <code>kleanadsPbjs.getConfig()</code> and press enter.
          </p>
          <p>Check in {customCheckText} to display the list of module configurations.</p>
        </div>
      </HelpText>
    </Flyout.Body>
  </Flyout.Content>
)

export const PureAdStackPrebidModuleHelpFlyout = styled(_PureAdStackPrebidModuleHelpFlyout)``
