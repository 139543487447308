import styled from '@emotion/styled'
import { AdStackRefreshGlobalSettingsData, AdmRefreshGlobalSettings } from '@pubstack/common/src/adstack/refresh-global-settings'
import { formatHumanReadablePreviousDays } from '@pubstack/common/src/date'
import { DateTime } from 'luxon'
import { FunctionComponent, useEffect, useState } from 'react'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Skeleton } from '~/components/Skeleton'
import { Tooltip } from '~/components/Tooltip'
import { WithClassName } from '~/types/utils'
import { RefreshWrapper } from './PureAdStackRefreshPage'
import { GlobalSetting } from './components/GlobalSetting'
import { GlobalSettingView } from './components/GlobalSettingView'

const BottomBar = styled.div`
  border-top: 1px solid ${Colors.Platinum};
`

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const TitleWrapper = styled.div`
  display: flex;
  ${Fonts.H1};
  align-items: center;
  margin-right: auto;
`
const SubTitleWrapper = styled.h2`
  ${Fonts.H2};
  font-weight: 500;
`

const ActionsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const LoadExcludedCampaignAction = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`
const LastUpdateText = styled.span`
  ${Fonts.P3};
  ${Fonts.colors.SlateGrey};
`

const ExclusionText = styled.div`
  margin-top: 4px;
  margin-bottom: 16px;
`

const SettingsHeaderAndFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: end;
`

const GlobalSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: stretch;
`

const NoSettings = styled.div`
  font-style: italic;
  ${Fonts.colors.Ash};
`

const AddSettingButton = styled(Button)`
  margin-right: auto;
`

type PureAdStackRefreshGlobalSettingsPageProps = WithClassName & {
  onLoadExcludedCampaigns: () => void
  globalSettings: AdStackRefreshGlobalSettingsData
  onSaveGlobalSettings: (settings: AdmRefreshGlobalSettings, isFormDirty: boolean) => void
  isLoading: boolean
}

const DEFAULT_VALUE: Omit<AdmRefreshGlobalSettings, 'id'> = {
  scopeId: '',
  settings: [
    {
      bidders: [],
      sizes: [],
    },
  ],
}

const _PureAdStackRefreshGlobalSettingsPage: FunctionComponent<PureAdStackRefreshGlobalSettingsPageProps> = ({ globalSettings, onLoadExcludedCampaigns, onSaveGlobalSettings, isLoading }) => {
  const getLastUpdate = (timeMillis: number): string => `${formatHumanReadablePreviousDays(DateTime.fromMillis(timeMillis))} (Europe/Paris)`

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    reset,
    setValue,
  } = useForm<AdmRefreshGlobalSettings>({ mode: 'onSubmit', defaultValues: globalSettings.refreshGlobalSettings ?? DEFAULT_VALUE })
  const { fields, append, remove } = useFieldArray({ control, name: 'settings' })
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isLambdaLaunched, setIsLambdaLaunched] = useState<boolean>(globalSettings.excludedCampaignsData !== null && !globalSettings.excludedCampaignsData.canLambdaBeLaunched)

  const sanitize = (globalSettings: AdmRefreshGlobalSettings): AdmRefreshGlobalSettings => ({
    ...globalSettings,
    settings: globalSettings.settings.map((setting) => ({ ...setting, timer: setting.timer ? Number(setting.timer) : undefined })),
  })

  const onSubmit: SubmitHandler<AdmRefreshGlobalSettings> = async (globalSettingsForm) => {
    setIsSaving(true)
    await onSaveGlobalSettings(
      sanitize({
        ...globalSettingsForm,
        settings: globalSettingsForm.settings.map((setting) => ({ ...setting, bidders: setting.bidders.map((bidder) => globalSettings.bidders.find((b) => b.displayName === bidder)?.code ?? '') })),
      }),
      isDirty
    )
    setIsEditing(false)
    setIsSaving(false)
  }

  useEffect(() => {
    if (globalSettings.refreshGlobalSettings !== null) {
      reset(globalSettings.refreshGlobalSettings)
    }
    setIsLambdaLaunched(globalSettings.excludedCampaignsData !== null && !globalSettings.excludedCampaignsData.canLambdaBeLaunched)
  }, [globalSettings])

  const cardActions = (
    <CardActions>
      <Button
        variant={'tertiary'}
        onClick={() => {
          reset()
          setIsEditing(false)
        }}
      >
        Cancel
      </Button>
      <Button variant={'primary'} type={'submit'} disabled={isSaving}>
        Save
      </Button>
    </CardActions>
  )

  return (
    <RefreshWrapper>
      <div>Following settings will be applied on refreshed stacks.</div>
      <ContentCard color={Colors.Petrol}>
        <CardContentWrapper>
          <TitleWrapper>
            <p>Campaigns & advertisers exclusions</p>
            <Tooltip
              title={
                <>
                  <div>
                    Manage your excluded campaigns and/or advertisers in Google Ad Manager by applying the label &quot;Pubstack no refresh&quot; to the Orders and/or Line Items that you don&apos;t
                    want to refresh.
                  </div>
                  <div>This doesn&apos;t work at Creative level.</div>
                </>
              }
            >
              <Button variant={'tertiary'} iconName={'help'} iconSize={'20px'} />
            </Tooltip>
          </TitleWrapper>
          <BottomBar />
          <div>
            <SubTitleWrapper>No refresh</SubTitleWrapper>

            <ExclusionText>
              The exclusion list is automatically updated everyday between 11pm and 12am (Europe/Paris timezone). Update it manually if a running campaign needs to be excluded immediately.
            </ExclusionText>
            <ActionsWrapper>
              <LoadExcludedCampaignAction>
                <Button
                  variant={'secondary'}
                  disabled={isLoading || isLambdaLaunched || globalSettings.excludedCampaignsData === null}
                  iconSpin={isLambdaLaunched}
                  iconName={isLambdaLaunched ? 'pubstack' : 'restore'}
                  onClick={() => {
                    setIsLambdaLaunched(true)
                    onLoadExcludedCampaigns()
                  }}
                >
                  Load excluded campaigns
                </Button>
                <LastUpdateText>
                  {isLoading || globalSettings.excludedCampaignsData === null ? (
                    ''
                  ) : isLambdaLaunched ? (
                    <span>Updating... This process can take few minutes. You can leave the page.</span>
                  ) : (
                    globalSettings.excludedCampaignsData.lastModified && <span>Last update: {getLastUpdate(globalSettings.excludedCampaignsData.lastModified)}</span>
                  )}
                </LastUpdateText>
              </LoadExcludedCampaignAction>
              <a href={`${globalSettings.excludedCampaignsData?.url}`} download rel={'noreferrer'} target={'_blank'}>
                <Button variant={'tertiary'} disabled={globalSettings.excludedCampaignsData === null || !globalSettings.excludedCampaignsData.lastModified} iconName={'download'}>
                  See campaign list
                </Button>
              </a>
            </ActionsWrapper>
          </div>
        </CardContentWrapper>
      </ContentCard>
      <ContentCard color={Colors.Petrol}>
        {isEditing ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContentWrapper>
              <SettingsHeaderAndFooter>
                <TitleWrapper>Settings</TitleWrapper>
                {cardActions}
              </SettingsHeaderAndFooter>

              <BottomBar />
              <div>
                <div>Define specific refresh settings for bidders, sizes, or bidders x sizes.</div>
                <div>If an impression matches several rules, the longest timer will be used, which will ensure an optimal ad experience on your website.</div>
              </div>
              <GlobalSettingsWrapper>
                {fields.map((field, index) => (
                  <GlobalSetting
                    key={field.id}
                    control={control}
                    errors={errors}
                    selectableBidders={globalSettings.bidders}
                    onDelete={(indexToDelete: number) => {
                      remove(indexToDelete)
                    }}
                    index={index}
                    setValue={setValue}
                  />
                ))}
              </GlobalSettingsWrapper>
              <SettingsHeaderAndFooter>
                <AddSettingButton variant={'secondary'} iconName={'add'} onClick={() => append(DEFAULT_VALUE.settings, { shouldFocus: false })}>
                  Add setting
                </AddSettingButton>

                {cardActions}
              </SettingsHeaderAndFooter>
            </CardContentWrapper>
          </form>
        ) : (
          <CardContentWrapper>
            <SettingsHeaderAndFooter>
              <TitleWrapper>Settings</TitleWrapper>
              <CardActions>
                <Button variant={'primary'} onClick={() => setIsEditing(true)} disabled={isLoading}>
                  Edit
                </Button>
              </CardActions>
            </SettingsHeaderAndFooter>

            <BottomBar />
            <div>
              <div>Define specific refresh settings for bidders, sizes, or bidders x sizes.</div>
              <div>If an impression matches several rules, the longest timer will be used, which will ensure an optimal ad experience on your website.</div>
            </div>
            {isLoading ? (
              <Skeleton bigger={true} />
            ) : globalSettings.refreshGlobalSettings !== null ? (
              <GlobalSettingsWrapper>
                {globalSettings.refreshGlobalSettings?.settings.map((setting, index) => (
                  <GlobalSettingView key={`GlobalSettingView${index}`} refreshGlobalSetting={setting} selectableBidders={globalSettings.bidders} />
                ))}
              </GlobalSettingsWrapper>
            ) : (
              <NoSettings>No settings yet.</NoSettings>
            )}
          </CardContentWrapper>
        )}
      </ContentCard>
    </RefreshWrapper>
  )
}

export const PureAdStackRefreshGlobalSettingsPage = styled(_PureAdStackRefreshGlobalSettingsPage)``
