import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { CodeSnippet } from '~/components/CodeSnippet'
import { Flyout } from '~/components/Flyout'

const HelpText = styled.div`
  h2 {
    padding: 12px 0 12px 0;
  }
  code {
    width: 500px;
  }
`

const _PureAdstackContextKeysHelpFlyout: FunctionComponent = () => {
  return (
    <Flyout.Content>
      <Flyout.Title>Help</Flyout.Title>
      <Flyout.Body>
        <HelpText>
          <h2>What is a context?</h2>
          <p>
            Contexts allow you to define different typologies of inventory for which you want to apply a dedicated stack. A context is composed of logical conditions, which are identified by at least
            one context key and its value.
          </p>
          <h2>What are context keys?</h2>
          <p>
            Context keys allow you to define a page. With context keys, you have full control on the ad stack and ad layout: the right ad environment will be pushed to the right user, on the right
            page,... Context keys are also used to build ad unit path.
          </p>
          <h2>How to define a context key?</h2>
          <p>
            A context key is defined by its name and values. For each key, a default value can be set in case of missing information or undefined value.{' '}
            <i>Once a context key is created, name and values cannot be edited but new values can be added.</i>
          </p>
          <h2>How is it implemented on a page?</h2>
          <p>Each context key is defined with a ‘meta’ tag in the page code, following this structure:</p>
          <CodeSnippet copyButtonHidden contentText={`<meta name=”pbstck_context:context_key_name” content=”your_value”/>`} />
          <p>Example to identify an article, on mobile, on a specific site :</p>

          <CodeSnippet
            copyButtonHidden
            contentText={`<head>
  <meta name=”pbstck_context:site_name” content=”mysite”/>
  <meta name=”pbstck_context:device_type” content=”mobile”/>
  <meta name=”pbstck_context:page_type” content=”article”/>
</head>`}
          />
        </HelpText>
      </Flyout.Body>
    </Flyout.Content>
  )
}

export const PureAdstackContextKeysHelpFlyout = styled(_PureAdstackContextKeysHelpFlyout)``
