export interface RefreshGroup {
  id: string
  name: string
  timer: number
  capping?: number
  enabled: boolean
  preventCLS?: boolean
  tagIds: Array<{ id: string; name: string }>
  adUnits: Array<{ id: string; name: string }>
  devices: Array<{ id: string; name: string }>
  specificRules?: Array<{ bidderName: string; timer: number }>
  blockerEnabled: boolean
  populationSplit?: number
  keyValueTargetings?: Array<{ key: string; value: string[] }> // TODO 2022-10-24 TMU NRA rename value to values
}

export const EMPTY_REFRESH_GROUP: RefreshGroup = {
  id: '',
  name: '',
  timer: 30,
  enabled: false,
  tagIds: [],
  adUnits: [],
  devices: [],
  blockerEnabled: false,
}

export interface GroupOverlap {
  groups: Array<{ id: string; name: string }>
  tagIds: Array<string>
  adUnits: Array<string>
  devices: Array<string>
}

export const EMPTY_GROUP_OVERLAP: GroupOverlap = { devices: [], adUnits: [], groups: [], tagIds: [] }

export interface RefreshException {
  id?: string
  exclusionBidders?: string[]
  exclusionSizes?: string[]
  exclusionFiles?: ExclusionFiles
}
export interface ExclusionFiles {
  mode: string
  lineItemIdFile?: string
  orderIdFile?: string
}

export type ExclusionType = 'lineItemId' | 'orderId'

export type ExclusionFileUploadAction = 'preserve' | 'upload' | 'delete'
