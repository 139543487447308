import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import Button from '~/components/Button'
import { PageContent } from '~/components/PageContent'
import { WithClassName } from '~/types/utils'

type PureSettingsPageProps = WithClassName & { breadcrumbs: ReactNode; hideNavigateBack?: boolean; onNavigateBack: () => void }
const _PureSettingsPage: FunctionComponent<PropsWithChildren<PureSettingsPageProps>> = ({ className, children, breadcrumbs, hideNavigateBack, onNavigateBack }) => {
  return (
    <PageContent
      className={className}
      breadcrumbs={breadcrumbs}
      headerActions={
        !hideNavigateBack && (
          <Button onClick={onNavigateBack} variant={'tertiary'}>
            Back
          </Button>
        )
      }
    >
      {children}
    </PageContent>
  )
}

export const PureSettingsPage = styled(_PureSettingsPage)``
