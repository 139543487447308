import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div`
  padding: 20px;
`

const TitleBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

const TitleButtons = styled.div`
  display: flex;
  gap: 20px;
`

type PageContentProps = WithClassName &
  PropsWithChildren & {
    breadcrumbs: ReactNode
    headerActions?: ReactNode
  }
const _PageContent: FunctionComponent<PageContentProps> = ({ className, breadcrumbs, headerActions, children }) => (
  <Wrapper className={className}>
    <TitleBar>
      {breadcrumbs}
      {!!headerActions && <TitleButtons>{headerActions}</TitleButtons>}
    </TitleBar>
    {children}
  </Wrapper>
)
export const PageContent = styled(_PageContent)``
