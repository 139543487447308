import styled from '@emotion/styled'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DisplayedStack } from '@pubstack/common/src/stack'
import { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { Flyout } from '~/components/Flyout'
import { Tooltip } from '~/components/Tooltip'
import { PureStackDetails } from './stack/PureStackDetails'

type PureAdstackStackDetailsFlyoutProps = {
  detailedStack: DisplayedStack
  canEdit: boolean
  onEditStack: (stackId: string, siteId?: string) => void
  currencySymbol: CurrencySymbol
  hasAdmRefresh: boolean
}

const _PureAdstackStackDetailsFlyout: FunctionComponent<PureAdstackStackDetailsFlyoutProps> = ({ detailedStack, canEdit, onEditStack, currencySymbol, hasAdmRefresh }) => {
  return (
    <Flyout.Content>
      <Flyout.Title>
        {' '}
        {detailedStack.name} - {detailedStack.version ? 'v' + detailedStack.version : 'draft'} details`
      </Flyout.Title>
      <Flyout.Body>
        <PureStackDetails currencySymbol={currencySymbol} detailedStack={detailedStack} hasAdmRefresh={hasAdmRefresh} onBack={() => true} />
      </Flyout.Body>
      <Flyout.Actions>
        <Tooltip title={canEdit ? 'Edit stack' : 'This stack already has 2 versions.'} positions={['left']}>
          <Button
            variant={'secondary'}
            onClick={() => {
              onEditStack(detailedStack.stackId, detailedStack.siteId)
            }}
            iconName={'edit'}
            disabled={!canEdit}
          >
            Edit
          </Button>
        </Tooltip>
      </Flyout.Actions>
    </Flyout.Content>
  )
}

export const PureAdstackStackDetailsFlyout = styled(_PureAdstackStackDetailsFlyout)``
