import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Flyout } from '~/components/Flyout'

const HelpText = styled.div`
  h2 {
    padding: 24px 0 12px 0;
  }
`

const _PureAdstackLazyLoadingHelpFlyout: FunctionComponent = () => {
  return (
    <Flyout.Content>
      <Flyout.Title>Tips & help</Flyout.Title>
      <Flyout.Body>
        <HelpText>
          <h2>What’s the impact on header bidding?</h2>
          <p>If header bidding is lazy loaded, you can choose when to start the auction. If not, the auction will start when the user arrives on the page.</p>
          <h2>How to choose the number of viewports?</h2>
          <p>To start an event right before being visible, choose a number close to 0. To start an event early, choose a number far from 0.</p>
        </HelpText>
      </Flyout.Body>
    </Flyout.Content>
  )
}

export const PureAdstackLazyLoadingHelpFlyout = styled(_PureAdstackLazyLoadingHelpFlyout)``
