import styled from '@emotion/styled'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { useUser } from '~/auth/user.hooks'
import { configurationState, currentScopeState } from '~/state'
import { WithClassName } from '~/types/utils'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { useSiteWithRoute } from '../../useSiteWithRoute'
import { PureSiteStack } from './PureSiteStack'
import { SitePageContext } from './SitePage'

type SiteStacksProps = WithClassName

const getStackConfigUrlPrefix = (site: Site) => {
  const { STACK_CONFIG_URL_PREFIX } = useRecoilValue(configurationState)
  const { id } = useRecoilValue(currentScopeState)
  const shortScopeId = id.split('-')[0]
  const shortSiteId = site.id.split('-')[0]
  const suffix = `${shortScopeId}/${shortSiteId}`

  return `${STACK_CONFIG_URL_PREFIX}/${suffix}`
}

const _SiteStacks: FunctionComponent<SiteStacksProps> = ({ className }) => {
  const user = useUser()
  const currencySymbol = useScopeCurrency()
  const { site } = useSiteWithRoute()

  const {
    stacks,
    stackDetails,
    templates,
    isStackDetailsLoading,
    contexts,
    sites,
    currentSite,
    onEdit,
    onEditPriorities,
    onCancelEditPriorities,
    onValidatePriorities,
    onCreate,
    onDeploy,
    onSynchronize,
    onConnectToPubstack,
    onNewContext,
    getDetailedStack,
    isLoading,
    hasNoContext,
    isEditingPriorities,
    onArchive,
    onUnarchive,
  } = useOutletContext<SitePageContext>()
  const props = {
    stacks,
    stackDetails,
    templates,
    isStackDetailsLoading,
    contexts,
    sites,
    currentSite,
    canDeploy: !!(user?.getScopeRole() === 'owner' || user?.isAdmin),
    canEditPriorities: !!(user?.getScopeRole() === 'owner' || user?.isAdmin),
    canSeeStackLinks: !!user?.isAdmin,
    stackConfigUrl: getStackConfigUrlPrefix(site),
    onEdit,
    onEditPriorities,
    onCancelEditPriorities,
    onValidatePriorities,
    onCreate,
    onDeploy,
    onSynchronize,
    onConnectToPubstack,
    onNewContext,
    getDetailedStack,
    isLoading,
    hasNoContext,
    isEditingPriorities,
    hasAdmRefresh: !!user?.hasFeature('admRefresh'),
    currencySymbol,
    onArchive,
    onUnarchive,
  }
  return <PureSiteStack className={className} {...props} />
}
export const SiteStacks = styled(_SiteStacks)``
