import styled from '@emotion/styled'
import { AdmQaReport } from '@pubstack/common/src/adm-qa-reports'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { useState } from 'react'
import { DataColors } from '~/assets/style/colors'
import { IconName } from '~/components/Icon'
import { AdmQaReportsDimName, UserSessionData } from '~/modules/admin/adm-qa/admQaReports'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { hbAuctionRpm, hbImpressionCount, impressionPerPage, impressionPerSession, pageViewPerSession, sessionCount } from '~/modules/analytics/formulas'
import { ChartMetrics, useAdmQAReportChartData } from './admQaChartsData'

const CHART_METRICS: ChartMetrics<UserSessionData> = {
  impressions: {
    label: 'Impressions',
    compute: (data, index, site) => data.impressionCount[index][site],
    display: (data, index, site) => hbImpressionCount.displayable(data.impressionCount[index][site]),
  },
  pages: {
    label: 'Pages',
    compute: (data, index, site) => data.pageCount[index][site],
    display: (data, index, site) => hbImpressionCount.displayable(data.pageCount[index][site]),
  },
  sessions: {
    label: sessionCount.name,
    compute: (data, index, site) => data.sessionCount[index][site],
    display: (data, index, site) => hbImpressionCount.displayable(data.sessionCount[index][site]),
  },
  impressionsPerSession: {
    label: impressionPerSession.name,
    compute: (data, index, site) => impressionPerSession.raw({ impressionCount: data.impressionCount[index][site], sessionCount: data.sessionCount[index][site] }),
    display: (data, index, site) => hbImpressionCount.displayable(impressionPerSession.raw({ impressionCount: data.impressionCount[index][site], sessionCount: data.sessionCount[index][site] })),
  },
  pageView: {
    label: pageViewPerSession.name,
    compute: (data, index, site) => pageViewPerSession.raw({ pageCount: data.pageCount[index][site], sessionCount: data.sessionCount[index][site] }),
    display: (data, index, site) => hbImpressionCount.displayable(pageViewPerSession.raw({ pageCount: data.pageCount[index][site], sessionCount: data.sessionCount[index][site] })),
  },
  impressionPerPage: {
    label: impressionPerPage.name,
    compute: (data, index, site) => impressionPerPage.raw({ impressionCount: data.impressionCount[index][site], pageCount: data.pageCount[index][site] }),
    display: (data, index, site) => hbAuctionRpm.displayable(impressionPerPage.raw({ impressionCount: data.impressionCount[index][site], pageCount: data.pageCount[index][site] })),
  },
}

type PureUserSessionChartProps = {
  rawData: UserSessionData
  sites: string[]
  isLoading: boolean
  report: AdmQaReport
  currencySymbol: CurrencySymbol
  dimension: AdmQaReportsDimName
  iconName: IconName
}

const _PureUserSessionChart = ({ rawData, iconName, dimension, sites, isLoading, report, currencySymbol }: PureUserSessionChartProps) => {
  const [bidderChartMetric, setBidderChartMetric] = useState('impressions')
  const dimensionIsMissing = !rawData[dimension]

  const { chartData, legends } = useAdmQAReportChartData(rawData, dimension, sites, CHART_METRICS[bidderChartMetric], report, currencySymbol)
  return (
    <AnalyticsChartWidget
      title={`User sessions per ${dimension}`}
      empty={(!isLoading && rawData.biddercode.length === 0) || dimensionIsMissing}
      error={false}
      onRefreshClick={() => {}}
      icon={iconName}
      isLoading={isLoading}
      legends={legends}
      metrics={Object.keys(CHART_METRICS).map((key) => {
        const metric = CHART_METRICS[key]
        return {
          label: metric.label,
          active: bidderChartMetric === key,
          onClick: () => setBidderChartMetric(key),
        }
      })}
      chart={{
        chartType: 'ColumnChart',
        options: {
          ...AnalyticsDefaultChartOptions,
          colors: [DataColors.Kaiminus, DataColors.Pumpkin],
          isStacked: 'false',
        },
        data: chartData,
      }}
    />
  )
}

export const PureUserSessionChart = styled(_PureUserSessionChart)``
