import { AdUnit } from '@pubstack/common/src/adunit'
import { ExtraScript, ExtraScriptWithUrl } from '@pubstack/common/src/extraScript'
import { SiteSpaConfig } from '@pubstack/common/src/kleanadsScopeConfig'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { DisplayedStack, EMPTY_DISPLAYED_STACK, Stack } from '@pubstack/common/src/stack'
import { StackContext } from '@pubstack/common/src/stackContext'
import { Site } from '@pubstack/common/src/tag'
import { useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useAdUnits, useContexts, useDetailedStacks, useExtraScripts, useSiteConfigs, useSitesStacks, useSpaConfig, useStacks } from '~/api/adm-api.hook'
import { useSites } from '~/api/api.hook'
import { useUser } from '~/auth/user.hooks'
import { TabProp } from '~/components/Tabs'
import { useToast } from '~/components/Toast/useToasts'
import { useSiteWithRoute } from '~/modules/adstack/useSiteWithRoute'
import { useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { PureSitePage } from './PureSitePage'

type TabLabel = 'Activation' | 'Stacks' | 'Mapping' | 'GAM & wrappers' | 'Modules' | 'Connect to Pubstack' | 'Extra-scripts'

const TabUrls: { [key in TabLabel]: string } = {
  Activation: 'activation',
  Stacks: 'stacks',
  Mapping: 'mapping',
  'GAM & wrappers': 'gamwrappers',
  Modules: 'modulesBySite',
  'Connect to Pubstack': 'connect-to-pubstack',
  'Extra-scripts': 'extra-scripts',
}

export type SitePageContext = {
  sites: Site[]
  currentSite: Site
  stacks: Stack[]
  stackDetails: DisplayedStack
  templates: Stack[]
  isStackDetailsLoading: boolean
  contexts: StackContext[]
  siteConfig: SiteConfig | undefined
  adUnits: AdUnit[]
  spaConfig: SiteSpaConfig
  extraScripts: ExtraScriptWithUrl[]
  onAdManagementEnable: () => void
  onSpaUpdate: (spaConfig: SiteSpaConfig) => void
  onUseAnalyticsScriptsClick: () => void
  onEdit: (stackId: string) => void
  onEditPriorities: () => void
  onCancelEditPriorities: () => void
  onValidatePriorities: (changedStacks: { stackId: string; priority: number }[]) => Promise<void>
  onCreate: (template: string) => Promise<void>
  onDeploy: (stack: Stack, abTest: number) => Promise<void>
  onSynchronize: (stack: Stack) => Promise<void>
  onConnectToPubstack: () => void
  onNewContext: () => void
  onDetailedView: (id: string, siteId: string) => void
  getDetailedStack: (id: string, siteId: string) => Promise<DisplayedStack>
  onArchive: (stack: Stack) => Promise<void>
  onUnarchive: (stack: Stack) => Promise<void>
  onExtraScriptCreate: (extraScript: ExtraScript, file?: File | null) => Promise<void>
  onExtraScriptUpdate: (extraScript: ExtraScript) => Promise<void>
  isLoading: boolean
  hasNoContext: boolean
  isEditingPriorities: boolean
}

export const SitePage = () => {
  const logger = useLogger()
  const user = useUser()
  const location = useLocation()
  const navigate = useNavigate()
  const { site: currentSite, setSite, siteId } = useSiteWithRoute()
  const [stacks, setStacks] = useState<Stack[]>([])
  const [stackDetails, setStackDetails] = useState<DisplayedStack>(EMPTY_DISPLAYED_STACK)
  const [templates, setTemplates] = useState<Stack[]>([])
  const [contexts, setContexts] = useState<StackContext[]>([])
  const [siteConfig, setSiteConfig] = useState<SiteConfig>()
  const [adUnits, setAdUnits] = useState<AdUnit[]>([])
  const [sites, setSites] = useState<Site[]>([])
  const [spaConfig, setSpaConfig] = useState<SiteSpaConfig>({ enabled: false, mode: 'manual' })
  const [extraScripts, setExtraScripts] = useState<ExtraScriptWithUrl[]>([])
  const { byId: sitesById } = useSites(null)
  const { details } = useDetailedStacks(null)
  const { all: allStacks, deploy: deployStack, updatePriorities, synchronize: synchronizeStack, archive, unarchive } = useSitesStacks(null, siteId ?? '')
  const { all: allContexts } = useContexts(null)
  const { byId: siteConfigsById } = useSiteConfigs(null)
  const { byId: spaConfigById } = useSpaConfig(null)
  const { all: allAdUnits } = useAdUnits(null)
  const { all: allSites } = useSites(null)
  const { all: allTemplates } = useStacks(null)
  const toast = useToast()
  const breadcrumbs = useBreadcrumbs(currentSite)
  const [isEditingPriorities, setIsEditingPriorities] = useState(false)
  const [hasNoContext, setHasNoContext] = useState(false)
  const { upload: createExtraScript, update: updateExtraScript, all: allExtraScripts } = useExtraScripts(null, siteId ?? '')

  const tabs: TabProp[] = useMemo(() => {
    const tabs = [
      {
        label: 'Activation',
        active: /activation\/?$/.test(location?.pathname ?? ''),
      },
      {
        label: 'Stacks',
        active: /stacks\/?$/.test(location?.pathname ?? ''),
        disabled: stacks.length === 0,
      },
      {
        label: 'Mapping',
        active: /mapping\/?$/.test(location?.pathname ?? ''),
        disabled: !currentSite.adManagementEnabled,
      },
      {
        label: 'GAM & wrappers',
        active: /gamwrappers\/?$/.test(location?.pathname ?? ''),
        disabled: !currentSite.adManagementEnabled,
      },
      {
        label: 'Modules',
        active: /modulesBySite\/?$/.test(location?.pathname ?? ''),
      },
      {
        label: 'Connect to Pubstack',
        active: /connect-to-pubstack\/?$/.test(location?.pathname ?? ''),
        disabled: !currentSite.adManagementEnabled,
      },
      ...(user?.isAdmin
        ? [
            {
              label: 'Extra-scripts',
              active: /extra-scripts\/?$/.test(location?.pathname ?? ''),
            },
          ]
        : []),
    ]
    return tabs
  }, [location, stacks, currentSite, user])

  async function loadSiteData(siteId: string) {
    try {
      if (currentSite.adManagementEnabled) {
        await Promise.all([loadStacks(), loadSiteConfig(siteId), loadSpaConfig(siteId), tabs.find((tab) => tab.active)?.label === 'Activation' && loadAdUnits(), loadSites(), loadStackTemplates()])
      }
    } catch (exception) {
      toast.alert('An error occurred while fetching this site.')
      throw exception
    }
  }

  async function loadSite(siteId?: string) {
    if (siteId) {
      await loadSiteData(siteId)
    }
  }

  async function loadStacks() {
    const stacks = await allStacks.get()
    setStacks(stacks)
  }

  async function loadStackTemplates() {
    const stacks = await allTemplates.get()
    setTemplates(stacks)
  }

  async function loadContexts() {
    const contexts = await allContexts.get()
    setContexts(contexts)
    const enabledContexts = contexts.filter((c) => c.enabled)
    const alreadyUsedContexts = enabledContexts?.filter((c) => stacks.find((s) => s.contextId === c.id))
    const hasNoContexts = enabledContexts.length === 0 || alreadyUsedContexts.length === enabledContexts.length
    setHasNoContext(hasNoContexts)
  }

  async function loadSiteConfig(siteId: string) {
    const siteConfig = await siteConfigsById.get(siteId)
    setSiteConfig(siteConfig)
  }

  async function loadSpaConfig(siteId: string) {
    const spaConfig = await spaConfigById.get(siteId)
    setSpaConfig(spaConfig)
  }

  async function loadAdUnits() {
    const adUnits = await allAdUnits.get()
    setAdUnits(adUnits)
  }

  async function loadSites() {
    const sites = await allSites.get({ getDisabledSites: false })
    setSites(sites.filter((s) => s.adManagementEnabled).sort((a, b) => a.name.localeCompare(b.name)))
  }

  async function loadExtraScripts() {
    const extraScripts = await allExtraScripts.get()
    setExtraScripts(extraScripts)
  }

  useEffect(() => {
    if (currentSite.id) {
      loadSite(currentSite.id)
    }
  }, [currentSite.id])

  useEffect(() => {
    loadStacks()
    if (location.pathname.includes('/extra-scripts')) {
      loadExtraScripts()
    }
  }, [location])

  useEffect(() => {
    loadContexts()
  }, [stacks])

  async function enableAdManagement() {
    try {
      const updatedSite = await sitesById.put({ ...currentSite, adManagementEnabled: true, pbjs_adapter: 'kleanadsPbjs' })
      toast.success('Ad management has been successfully enabled.')
      setSite(updatedSite)
      await loadSiteData(updatedSite.id)
    } catch (exception) {
      toast.alert('An error occurred while activating ad management on this site.')
      throw exception
    }
  }

  async function updateSpaConfig(spaConfig: SiteSpaConfig) {
    try {
      await spaConfigById.put(currentSite.id, spaConfig)
      toast.success('SPA configuration has been updated.')
    } catch (exception) {
      toast.alert('An error occurred while updating SPA configuration on this site.')
      throw exception
    }
  }

  const onTabChange = (tab: TabProp) => {
    navigate(TabUrls[tab.label as TabLabel])
    if (tab.label === 'Activation' && adUnits.length === 0) {
      loadAdUnits()
    }
  }
  const onAdManagementToggle = () => {
    enableAdManagement()
  }

  const onSpaUpdate = (spaConfig: SiteSpaConfig) => {
    updateSpaConfig(spaConfig)
  }

  const onUseAnalyticsScriptsClick = () => {
    navigate('/settings/sites')
  }

  const onEditStack = (stackId: string) => {
    navigate(`stacks/${stackId}`)
  }

  const onCreateStack = async (template: string) => {
    navigate(`stacks/new?template=${template}`)
  }

  const onNewContext = () => {
    navigate('/adstack/context/contexts/new')
  }

  const onEditPriority = () => {
    setIsEditingPriorities(true)
  }

  const onCancelPriority = async () => {
    setIsEditingPriorities(false)
    loadStacks()
    loadStackTemplates()
  }

  const onValidatePriorities = async (changedStacks: { stackId: string; priority: number }[]) => {
    setIsEditingPriorities(false)
    try {
      await updatePriorities(changedStacks)
      await loadStacks()
      await loadStackTemplates()
    } catch (exception) {
      toast.alert(`An error occurred while updating the stack priorities.`)
    }
  }

  const onDeployStack = async (stack: Stack, abTest: number) => {
    try {
      await deployStack(stack.stackId, stack.id, abTest)
      await loadStacks()
      await loadStackTemplates()
      toast.success(
        <span>
          <b>
            {stack.name} v{stack.version}
          </b>{' '}
          will be live in a few minutes.
        </span>
      )
    } catch (exception) {
      toast.alert(`An error occurred while deploying the ${stack.name} stack.`)
      throw exception
    }
  }

  const onSynchronizeStack = async (stack: Stack) => {
    try {
      await synchronizeStack(stack.id)
      await loadStacks()
      await loadStackTemplates()
      toast.success(
        <span>
          An updated version of <b>{stack.name}</b> is created, deploy it to have the changes on your site.
        </span>
      )
    } catch (exception) {
      toast.alert(`An error occurred while synchronizing the ${stack.name} stack.`)
      throw exception
    }
  }

  const onConnectToPubstack = () => {
    navigate(TabUrls['Connect to Pubstack'])
  }

  const onDetailedView = async (id: string, siteId: string) => {
    if (details.loading) {
      details.abort()
    }
    try {
      const detailsStack = await details.get(id, siteId)
      setStackDetails(detailsStack)
      await logger.info({ action: 'click', type: 'detailed-view-flyout', actionName: 'stack', from: 'stacks-page' })
    } catch (exception) {
      toast.alert(`An error occurred while getting the detailed stack.`)

      throw exception
    }
  }

  const getDetailedStack = async (id: string, siteId: string): Promise<DisplayedStack> => {
    if (details.loading) {
      details.abort()
    }
    try {
      const detailsStack = await details.get(id, siteId)
      await logger.info({ action: 'click', type: 'detailed-view-flyout', actionName: 'stack', from: 'stacks-page' })
      return detailsStack
    } catch (exception) {
      toast.alert(`An error occurred while getting the detailed stack.`)

      throw exception
    }
  }

  const onArchive = async (stack: Stack) => {
    try {
      await archive(stack)
      loadStacks()
      await loadStackTemplates()
      toast.success(`Stack ${stack.name} has been archived along with all its versions.`)
    } catch (exception) {
      toast.alert(`An error occurred while archiving the stack.`)
    }
  }

  const onUnarchive = async (stack: Stack) => {
    try {
      await unarchive(stack)
      toast.success(`Stack ${stack.name} has been unarchived. Please check the stack settings before using it.`)
    } catch (exception) {
      toast.alert(`An error occurred while unarchiving the stack.`)
    }
  }

  const onExtraScriptCreate = async (extraScript: ExtraScript, file?: File | null) => {
    if (!siteId) {
      toast.alert('You should wait till the site was loaded.')
      return
    }
    try {
      await createExtraScript(extraScript, file)
      toast.success(`The extra-script ${extraScript.name} was uploaded.`)
      await loadExtraScripts()
    } catch (exception) {
      toast.alert(`An error occurred while the extra-script ${extraScript.name} was being uploaded.`)
    }
  }
  const onExtraScriptUpdate = async (extraScript: ExtraScript) => {
    if (!siteId) {
      toast.alert('You should wait till the site was loaded.')
      return
    }
    try {
      await updateExtraScript(extraScript)
      toast.success(`The extra-script ${extraScript.name} was updated.`)
      await loadExtraScripts()
    } catch (exception) {
      toast.alert(`An error occurred while the extra-script ${extraScript.name} was being updated.`)
    }
  }

  return (
    <PureSitePage breadcrumbs={breadcrumbs} tabs={tabs} onTabChange={onTabChange}>
      <Outlet
        context={
          {
            sites,
            currentSite,
            stacks,
            stackDetails,
            templates,
            isStackDetailsLoading: details.loading,
            contexts,
            siteConfig,
            spaConfig,
            adUnits,
            extraScripts,
            isLoading: sitesById.loading || allStacks.loading || allAdUnits.loading || siteConfigsById.loading,
            hasNoContext,
            onAdManagementEnable: onAdManagementToggle,
            onSpaUpdate: onSpaUpdate,
            onEdit: onEditStack,
            onEditPriorities: onEditPriority,
            onCancelEditPriorities: onCancelPriority,
            onValidatePriorities: onValidatePriorities,
            onCreate: onCreateStack,
            onDeploy: onDeployStack,
            onSynchronize: onSynchronizeStack,
            onConnectToPubstack: onConnectToPubstack,
            onNewContext: onNewContext,
            onDetailedView: onDetailedView,
            getDetailedStack,
            onUseAnalyticsScriptsClick: onUseAnalyticsScriptsClick,
            onExtraScriptCreate,
            onExtraScriptUpdate,
            isEditingPriorities: isEditingPriorities,
            onArchive,
            onUnarchive,
          } satisfies SitePageContext
        }
      />
    </PureSitePage>
  )
}
