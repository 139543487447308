import {
  allAuctionFillRate,
  allAuctionRpm,
  allAverageViewedTime,
  allECPM,
  allImpressionCount,
  allImpressionRevenue,
  allViewabilityMRC,
  auctionCount,
  bidCpm,
  bidRate,
  bidRpm,
  bidTimeoutRate,
  bidWinCpm,
  bidWinRpm,
  hbVideoCompletionRate,
  hbVideoFirstQuartile,
  hbVideoMidpoint,
  hbVideoStart,
  hbVideoThirdQuartile,
  hbVideoViewershipComplete,
  hbVideoViewershipTotalErrorCount,
  hbWinRate,
  impressionPerPage,
  impressionPerSession,
  impressionRate,
  impressionRpm,
  pageRpm,
  pageView,
  pageViewPerSession,
  sessionCount,
  sessionRpm,
} from './monitoring'
import { buildFormulaObj } from './operation-obj'

export const auctionCountObj = buildFormulaObj({
  name: auctionCount.name,
  pivot: 'auctionCount',
  formula: auctionCount,
})

export const auctionFillRateObj = buildFormulaObj({
  name: allAuctionFillRate.name,
  pivot: 'auctionCount',
  formula: allAuctionFillRate,
})

export const auctionRPMObj = buildFormulaObj({
  name: allAuctionRpm.name,
  pivot: 'auctionCount',
  formula: allAuctionRpm,
})

export const bidRateObj = buildFormulaObj({
  name: bidRate.name,
  pivot: 'bidRequestCount',
  formula: bidRate,
})

export const bidRPMObj = buildFormulaObj({
  name: bidRpm.name,
  pivot: 'bidRequestCount',
  formula: bidRpm,
})

export const bidTimeoutRateObj = buildFormulaObj({
  name: bidTimeoutRate.name,
  pivot: 'bidRequestCount',
  formula: bidTimeoutRate,
})

export const bidWinRateObj = buildFormulaObj({
  name: hbWinRate.name,
  pivot: 'bidResponseCount',
  formula: hbWinRate,
})

export const bidCpmObj = buildFormulaObj({
  name: bidCpm.name,
  pivot: 'bidResponseCount',
  formula: bidCpm,
})

export const bidWinCpmObj = buildFormulaObj({
  name: bidWinCpm.name,
  pivot: 'bidWinCpmSum',
  formula: bidWinCpm,
})

export const bidWinRpmObj = buildFormulaObj({
  name: bidWinRpm.name,
  pivot: 'bidRequestCount',
  formula: bidWinRpm,
})

export const impressionRPMObj = buildFormulaObj({
  name: impressionRpm.name,
  pivot: 'bidRequestCount',
  formula: impressionRpm,
})

export const impressionRevenueObj = buildFormulaObj({
  name: allImpressionRevenue.name,
  pivot: 'impressionCpmSum',
  formula: allImpressionRevenue,
})

export const impressionCountObj = buildFormulaObj({
  name: allImpressionCount.name,
  pivot: 'impressionCount',
  formula: allImpressionCount,
})

export const impressionRateObj = buildFormulaObj({
  name: impressionRate.name,
  pivot: 'impressionCount',
  formula: impressionRate,
})

export const viewabilityMRCObj = buildFormulaObj({
  name: allViewabilityMRC.name,
  pivot: 'measurableImpressionCount',
  formula: allViewabilityMRC,
})

export const averageViewedTimeObj = buildFormulaObj({
  name: allAverageViewedTime.name,
  pivot: 'measuredImpressionViewedTimeSum',
  formula: allAverageViewedTime,
})

export const eCPMObj = buildFormulaObj({
  name: allECPM.name,
  pivot: 'impressionCpmSum',
  formula: allECPM,
})

export const videoCompletionRate = buildFormulaObj({
  name: hbVideoCompletionRate.name,
  pivot: 'videoViewershipComplete',
  formula: hbVideoCompletionRate,
})

export const videoViewershipTotalErrorCount = buildFormulaObj({
  name: hbVideoViewershipTotalErrorCount.name,
  pivot: 'videoErrorCount',
  formula: hbVideoViewershipTotalErrorCount,
})

export const videoStart = buildFormulaObj({
  name: hbVideoStart.name,
  pivot: 'videoStart',
  formula: hbVideoStart,
})

export const videoFirstQuartile = buildFormulaObj({
  name: hbVideoFirstQuartile.name,
  pivot: 'videoFirstQuartile',
  formula: hbVideoFirstQuartile,
})

export const videoMidpoint = buildFormulaObj({
  name: hbVideoMidpoint.name,
  pivot: 'videoMidpoint',
  formula: hbVideoMidpoint,
})

export const videoThirdQuartile = buildFormulaObj({
  name: hbVideoThirdQuartile.name,
  pivot: 'videoThirdQuartile',
  formula: hbVideoThirdQuartile,
})

export const videoViewershipComplete = buildFormulaObj({
  name: hbVideoViewershipComplete.name,
  pivot: 'videoViewershipComplete',
  formula: hbVideoViewershipComplete,
})

export const sessionCountObj = buildFormulaObj({
  name: sessionCount.name,
  pivot: 'sessionCount',
  formula: sessionCount,
})

export const sessionRpmObj = buildFormulaObj({
  name: sessionRpm.name,
  pivot: 'impressionCpmSum',
  formula: sessionRpm,
})

export const pageRpmObj = buildFormulaObj({
  name: pageRpm.name,
  pivot: 'impressionCpmSum',
  formula: pageRpm,
})

export const impressionPerSessionObj = buildFormulaObj({
  name: impressionPerSession.name,
  pivot: 'impressionCount',
  formula: impressionPerSession,
})

export const impressionPerPageObj = buildFormulaObj({
  name: impressionPerPage.name,
  pivot: 'impressionCount',
  formula: impressionPerPage,
})

export const pageViewPerSessionObj = buildFormulaObj({
  name: pageViewPerSession.name,
  pivot: 'pageCount',
  formula: pageViewPerSession,
})

export const pageViewObj = buildFormulaObj({
  name: pageView.name,
  pivot: 'pageCount',
  formula: pageView,
})
