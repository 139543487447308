import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import Button from '~/components/Button'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { Link } from '~/components/Link'
import { WithClassName } from '~/types/utils'

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MailLink: FunctionComponent<{ to: string }> = ({ to }) => <Link href={`mailto:${to}`} label={to} />

type PureForbiddenProps = WithClassName & { onClick: () => void }
const _PureForbidden: FunctionComponent<PureForbiddenProps> = ({ className, onClick }) => {
  return (
    <ErrorWrapper>
      <IllustrationMessage
        className={className}
        iconName={'lock_round'}
        iconSize={'190px'}
        title={'You are not allowed to access this page'}
        message={
          <>
            Please ask your administrator to grant you the rights, or contact <MailLink to={'support@pubstack.io'} />
          </>
        }
      >
        <Button onClick={onClick} variant={'primary'}>
          Go to Overview
        </Button>
      </IllustrationMessage>
    </ErrorWrapper>
  )
}

export const PureForbidden = styled(_PureForbidden)``
