import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { AdStackSite } from '@pubstack/common/src/site'
import { FunctionComponent, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { Input } from '~/components/Input'
import { PageContent } from '~/components/PageContent'
import { Tooltip } from '~/components/Tooltip'
import Table, { handleTableSearch, handleTableSort, onColumnSort, SortableColumn, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'

type PureSitesPageProps = WithClassName & {
  sites: AdStackSite[]
  isLoading: boolean
  canEditSite: boolean
  onEditSite: (siteSelected: AdStackSite) => void
  onAddSite: () => void
  breadcrumbs: React.ReactNode
}

const SearchBar = styled.form`
  margin-bottom: 12px;
  display: flex;
`
const ButtonActions = styled.div`
  display: flex;
  justify-content: flex-end;
  ${Button} {
    margin-right: 16px;
  }
`

const LiveStack = styled.div`
  display: flex;
  gap: 4px;
`

const NoSiteTableRow = styled(TableRow)`
  background-color: ${Colors.White};
  width: 100%;
  text-align: center;
`

const SiteName = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${Tooltip} {
    display: inline-flex;
  }
`

const AdManagementIconWrapper = styled.div`
  display: flex;
`

/**
 * special count for the "Stack status" column sort
 */
const countOutOfSync = (stack: AdStackSite): number => {
  if (stack.adManagementEnabled) {
    // OoS
    if (stack.outOfSyncStacks) {
      return stack.outOfSyncStacks + 1
    }
    // success
    if (stack.draftStacks || stack.liveStacks || stack.readyToDeployStacks) {
      return 1
    }
    // product want to see "no stack" sites at first
    else {
      return 1000
    }
  }
  // -
  else {
    return 0
  }
}
const _PureSitesPage: FunctionComponent<PureSitesPageProps> = ({ sites, isLoading, canEditSite, onEditSite, onAddSite, breadcrumbs }) => {
  const [columns, setColumns] = useState<TableColumns<AdStackSite>>([
    {
      name: 'Site',
      isSortable: true,
      order: 'none',
      attributeSort: 'name',
    },
    {
      name: 'Ad management',
      isSortable: true,
      order: 'none',
      attributeSort: 'adManagementEnabled',
    },
    {
      name: 'Stacks status',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'outOfSyncStacks',
    },
    {
      name: 'Live stacks',
      isSortable: true,
      order: 'none',
      attributeSort: 'liveStacks',
    },
    {
      name: 'Stacks ready to deploy',
      isSortable: true,
      order: 'none',
      attributeSort: 'readyToDeployStacks',
    },
    {
      name: 'Action',
      isSortable: false,
    },
  ])
  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })
  const search = useWatch({ control, name: 'search' })
  const searchedSites = handleTableSearch(sites, search, ['name'])
  const sortedColumn = (columns.find((c) => c.isSortable && c.order !== 'none') || columns[0]) as SortableColumn<AdStackSite>
  let displayedSites: AdStackSite[] = []
  if (sortedColumn.attributeSort === 'outOfSyncStacks') {
    displayedSites = searchedSites.sort((a, b) => (sortedColumn.order === 'ascending' ? 1 : -1) * (countOutOfSync(b) - countOutOfSync(a)))
  } else {
    displayedSites = handleTableSort(columns, searchedSites)
  }

  const addSiteButton = (
    <Button iconName={'add_site'} variant={'primary'} onClick={onAddSite}>
      Add site
    </Button>
  )

  return (
    <PageContent breadcrumbs={breadcrumbs} headerActions={addSiteButton}>
      <SearchBar>
        <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
      </SearchBar>
      <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {displayedSites.length ? (
          displayedSites.map((site) => (
            <TableRow key={site.id} onClick={() => canEditSite && onEditSite(site)}>
              <TableCell>
                <SiteName>
                  {site.name}
                  {site.draftStacks > 0 && (
                    <Tooltip title={`${site.draftStacks} draft stack${site.draftStacks > 1 ? 's' : ''}`}>
                      <Icon name={'draft'} width={'20px'} height={'20px'} fill={Colors.SlateGrey} />
                    </Tooltip>
                  )}
                </SiteName>
              </TableCell>
              <TableCell>
                <AdManagementIconWrapper>{site.adManagementEnabled ? <Icon name={'check'} fill={Colors.Success} /> : <Icon name={'close'} fill={Colors.Ash} />}</AdManagementIconWrapper>
              </TableCell>
              <TableCell>
                {site.adManagementEnabled ? (
                  <LiveStack>
                    {(site.outOfSyncStacks ?? 0) > 0 ? (
                      <>
                        <Icon name={'sync_problem'} fill={Colors.Warning} />
                        {site.outOfSyncStacks} out-of-sync
                      </>
                    ) : site.liveStacks || site.draftStacks || site.readyToDeployStacks ? (
                      <Icon name={'check_circled'} fill={Colors.Success} />
                    ) : (
                      <>
                        <Icon name={'warning'} fill={Colors.Warning} />
                        no stack
                      </>
                    )}
                  </LiveStack>
                ) : (
                  <>-</>
                )}
              </TableCell>
              <TableCell>{site.adManagementEnabled ? site.liveStacks : <>-</>}</TableCell>
              <TableCell>{site.adManagementEnabled ? site.readyToDeployStacks : <>-</>}</TableCell>
              <TableCell
                css={css`
                  width: 100px;
                `}
              >
                {canEditSite && (
                  <ButtonActions>
                    <Tooltip title={'Edit'}>
                      <Button
                        iconName={'edit'}
                        variant={'tertiary'}
                        title={'Edit'}
                        onClick={() => {
                          onEditSite(site)
                        }}
                      />
                    </Tooltip>
                  </ButtonActions>
                )}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <NoSiteTableRow>
            <TableCell colspan={5}>
              <h3>No sites</h3>
              <p>No sites found for this query. Try a different search term or create a new site.</p>
              {addSiteButton}
            </TableCell>
          </NoSiteTableRow>
        )}
      </Table>
    </PageContent>
  )
}

export const PureSitesPage = styled(_PureSitesPage)``
