import styled from '@emotion/styled'
import { BidderCatalogAlias, BidderParam, MappedBidder } from '@pubstack/common/src/bidderCatalog'
import { FunctionComponent } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { Input } from '~/components/Input'
import { Select } from '~/components/Select'
import { Skeleton } from '~/components/Skeleton'
import { Status, StatusState } from '~/components/Status'
import { Tooltip } from '~/components/Tooltip'
import { PureAdStackIntegrationSpreadsheet } from '~/modules/adstack/inventory/bidderParams/PureAdStackIntegrationSpreadsheet'
import { MappingTransfer } from '~/modules/adstack/inventory/bidderParams/mappingTransfer/MappingTransfer'
import { onBidderParamsUpdate, validateBidderParams } from '~/modules/adstack/inventory/bidderParams/mappingTransfer/mappingUpdate'
import { WithClassName } from '~/types/utils'
import { generateStackNameList } from '../../rules/PureUsedStackTooltip'

const MappingLabel = styled.span`
  ${Fonts.H2};
  width: 30%;
`

const BidderList = styled.div`
  display: grid;
  gap: 10px;
`

const BidderPanel = styled.div`
  width: 100%;
  display: inline-flex;
`

const Title = styled.h2`
  ${Fonts.H2};
  font-weight: 500;
`
const AliasIntro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
`
const SearchBar = styled.form`
  margin-bottom: 12px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  gap: 12px;
  ${Button} {
    margin-top: 4px;
  }
`

type PureSiteMappingBidderViewProps = WithClassName & {
  bidderList: MappedBidder[]
  isLoading: boolean
  onCreateNewBidder: () => void
  isUploading: boolean
  isTemplateDownloading: boolean
  isMappingDownloading: boolean
  onMappingDownload: (alias: BidderCatalogAlias, options?: { fullMapping?: boolean }) => void
  onMappingUpload: (alias: BidderCatalogAlias, file: File, options?: { partialUpload?: boolean }) => void
  siteId: string
}

type MappedBidderWithStatus = MappedBidder & {
  totalMappings: number
  usedMappings: number
  statusState: {
    label: 'active' | 'inactive' | 'error' | 'warning'
    order: number
  }
}

const getStatusState = (mappingThatShouldBeUsed: BidderParam[]): { label: StatusState; order: number } => {
  const actuallyUsedCount = mappingThatShouldBeUsed.filter((bp) => Object.keys(bp.params).length).length

  if (actuallyUsedCount === 0) {
    return { label: 'inactive', order: 2 }
  }

  if (actuallyUsedCount < mappingThatShouldBeUsed.length) {
    return { label: 'warning', order: 1 }
  }

  return { label: 'active', order: 0 }
}

function enrichAndSort(filteredBidders: MappedBidder[]): MappedBidderWithStatus[] {
  return filteredBidders
    .map((mb) => {
      const allBidderParams = mb.alias.bidderParams
      const usedMappings = allBidderParams.filter((bp) => Object.keys(bp.params).length).length
      return {
        ...mb,
        usedMappings,
        totalMappings: allBidderParams.length,
        statusState: getStatusState(allBidderParams),
      }
    })
    .filter((mb) => mb.totalMappings > 0)
    .sort((a, b) => a.statusState.order - b.statusState.order || a.alias.bidderLabel.localeCompare(b.alias.bidderLabel))
}

const _PureSiteMappingBidderView: FunctionComponent<PureSiteMappingBidderViewProps> = ({
  className,
  isLoading,
  bidderList,
  isUploading,
  isMappingDownloading,
  isTemplateDownloading,
  onMappingDownload,
  onMappingUpload,
  onCreateNewBidder,
}) => {
  const {
    resetField,
    control,
    formState: { isDirty },
  } = useForm<{ search: string; usedOnThisSite: boolean; onlyUsedBidders: boolean }>({
    defaultValues: {
      search: '',
      usedOnThisSite: true,
      onlyUsedBidders: true,
    },
  })
  const search = useWatch({ control, name: 'search' })
  const usedOnThisSite = useWatch({ control, name: 'usedOnThisSite' })
  const onlyUsedBidders = useWatch({ control, name: 'onlyUsedBidders' })
  const searchedBidders = bidderList.filter((mb) => !search || mb.alias.bidderLabel.toLowerCase().includes(search.toLowerCase()))
  const filterByAdUnits = usedOnThisSite
    ? searchedBidders.map((mb) => {
        const bidderParams = mb.alias.bidderParams.filter((bp) => mb.usedAdUnitNames.includes(bp.adUnitName))
        return {
          ...mb,
          alias: {
            ...mb.alias,
            bidderParams,
          },
        }
      })
    : searchedBidders
  const usedBidders = onlyUsedBidders ? filterByAdUnits.filter((mb) => mb.alias.bidderParams.some((bp) => Object.keys(bp.params).length)) : filterByAdUnits
  const bidderMapping: MappedBidderWithStatus[] = enrichAndSort(usedBidders)
  const pipeSeparator = `\xa0 | \xa0`

  return (
    <>
      <SearchBar>
        <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search bidder'} control={control} />
        <Select
          control={control}
          name={'onlyUsedBidders'}
          options={[
            { label: 'Used on this site', value: true },
            { label: 'Active bidders and aliases', value: false },
          ]}
          label={'Bidders and aliases'}
        />
        <Select
          control={control}
          name={'usedOnThisSite'}
          options={[
            { label: 'Used on this site', value: true },
            { label: 'All ad units', value: false },
          ]}
          label={'Ad units'}
        />
        {isDirty && (
          <Button
            iconName={'close'}
            variant={'tertiary'}
            onClick={() => {
              resetField('search')
              resetField('onlyUsedBidders')
              resetField('usedOnThisSite')
            }}
          />
        )}
      </SearchBar>
      <BidderList>
        {isLoading ? (
          <>
            {[0, 1, 2].map((e) => (
              <CollapsiblePanel
                key={e}
                hasBorderWhenOpened
                isCollapsible={true}
                className={className}
                title={
                  <BidderPanel>
                    <MappingLabel>
                      <Skeleton width={`15ch`} />
                    </MappingLabel>
                    <MappingLabel>
                      <Skeleton width={`15ch`} />
                    </MappingLabel>
                    <MappingLabel>
                      <Skeleton width={`15ch`} />
                    </MappingLabel>
                  </BidderPanel>
                }
              />
            ))}
          </>
        ) : bidderMapping.length > 0 ? (
          bidderMapping.map((bidder) => {
            return (
              <CollapsiblePanel
                key={bidder.alias.bidderLabel}
                hasBorderWhenOpened
                isCollapsible={true}
                forceState={bidder.totalMappings === 0 ? 'closed' : undefined}
                className={className}
                title={
                  <BidderPanel>
                    <MappingLabel>{bidder.alias.bidderLabel}</MappingLabel>
                    <MappingLabel>
                      {
                        <Status state={bidder.statusState.label}>
                          {bidder.usedMappings} of {bidder.totalMappings} mappings
                        </Status>
                      }
                    </MappingLabel>
                    {bidder.mappedStacks.length > 0 ? (
                      <Tooltip title={generateStackNameList(bidder.mappedStacks).reduce((stack, acc) => [acc, pipeSeparator, stack])} positions={['left', 'bottom', 'top', 'right']}>
                        <MappingLabel>
                          {bidder.mappedStacks.length !== bidder.totalStacks ? (
                            <span>
                              {bidder.mappedStacks.length} stack{bidder.mappedStacks.length > 1 && 's'}
                            </span>
                          ) : (
                            <span>All stacks ({bidder.totalStacks})</span>
                          )}
                        </MappingLabel>
                      </Tooltip>
                    ) : (
                      <MappingLabel>0 stack</MappingLabel>
                    )}
                  </BidderPanel>
                }
              >
                <AliasIntro>
                  <Title>Mapping by csv file</Title>
                </AliasIntro>
                {isLoading ? (
                  <Skeleton bigger width={'150px'} />
                ) : (
                  <>
                    <MappingTransfer
                      isMapped={bidder.alias.isUsed}
                      isUploading={isUploading}
                      isTemplateDownloading={isTemplateDownloading}
                      isMappingDownloading={isMappingDownloading}
                      onDownload={(options) => {
                        onMappingDownload(bidder.alias, options)
                      }}
                      onUpload={(file) => onMappingUpload(bidder.alias, file)}
                    />
                    <AliasIntro>
                      <Title>Mapping</Title>
                    </AliasIntro>

                    <PureAdStackIntegrationSpreadsheet
                      alias={bidder.alias}
                      validateBidderParams={(bidderParams) => validateBidderParams(bidderParams, { bidParams: bidder.alias.bidParams })}
                      onBidderParamsUpdate={(bidderParamsValue) => {
                        const result = onBidderParamsUpdate(bidder.alias, bidderParamsValue)
                        onMappingUpload(result[0], result[1], { partialUpload: true })
                      }}
                    />
                  </>
                )}
              </CollapsiblePanel>
            )
          })
        ) : (
          <IllustrationMessage iconName={'night_sky'} iconSize={'190px'} title={'It seems that no bidder mapping exist on your site.'} message={'Add bidder mapping to your inventory.'}>
            <Button iconName={'add_bidder'} variant={'primary'} onClick={onCreateNewBidder}>
              Add new bidder
            </Button>
          </IllustrationMessage>
        )}
      </BidderList>
    </>
  )
}

export const PureSiteMappingBidderView = styled(_PureSiteMappingBidderView)``
