import styled from '@emotion/styled'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { Site } from '@pubstack/common/src/tag'
import { TamConfig } from '@pubstack/common/src/tam/tamConfig'
import { FunctionComponent, useEffect, useState } from 'react'
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { Input } from '~/components/Input'
import { PageContent } from '~/components/PageContent'
import { Skeleton } from '~/components/Skeleton'
import { Toggle } from '~/components/Toggle'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import Table, { handleTableSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { PureCatalogItemHeader } from '~/modules/adstack/inventory/catalog/PureCatalogItemHeader'
import { WithClassName } from '~/types/utils'
import { PureAdStackTamIntegrationHelpFlyout } from './PureAdStackTamIntegrationHelpFlyout'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`

const PubIdForm = styled.form`
  display: inline-flex;
  align-items: center;
  gap: 16px;
`
const SiteGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`
const SiteTitle = styled(TableCell)`
  font-weight: bold;
`
type PureAdStackTamIntegrationPageProps = WithClassName & {
  breadcrumbs: React.ReactNode
  tamConfig: TamConfig
  onCreatePublisherId: (publisherId: string) => Promise<void>
  onCreateSiteConfig: (siteConfig: SiteConfig) => Promise<void>
  isLoading: boolean
  baseCDNUrl: string
  onBackClick: () => void
}

export const _PureAdStackTamIntegrationPage: FunctionComponent<PureAdStackTamIntegrationPageProps> = ({
  className,
  isLoading,
  breadcrumbs,
  tamConfig,
  onCreatePublisherId,
  onCreateSiteConfig,
  baseCDNUrl,
  onBackClick,
}) => {
  const flyout = useGlobalFlyout()
  const [isEditing, setIsEditing] = useState<boolean>(!tamConfig.apsPubId)
  const [columns, setColumns] = useState<TableColumns<Site>>([
    {
      name: 'Site',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'TAM enabled',
      isSortable: false,
    },
  ])
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<TamConfig>({
    defaultValues: tamConfig,
  })

  const onPublisherIdEdit: SubmitHandler<TamConfig> = () => {
    setIsEditing(!isEditing)
  }

  const onPublisherIdSubmit: SubmitHandler<TamConfig> = (formValue) => {
    onCreatePublisherId(formValue.apsPubId)
    setIsEditing(!isEditing)
  }

  const getErrorMessage = (errors: FieldErrors<TamConfig>) => {
    if (errors.apsPubId?.type === 'required') {
      return 'Publisher ID is required'
    }
  }
  const onSiteConfigSubmit: SubmitHandler<SiteConfig> = (formValue) => {
    onCreateSiteConfig(formValue)
  }

  const sortedSites = handleTableSort(columns, tamConfig.sites)
  useEffect(() => {
    reset(tamConfig)
    setIsEditing(!tamConfig.apsPubId)
  }, [tamConfig])

  return (
    <PageContent
      className={className}
      breadcrumbs={breadcrumbs}
      headerActions={
        <>
          <Button variant={'tertiary'} onClick={onBackClick}>
            Back
          </Button>

          <Button variant={'tertiary'} onClick={() => flyout.open(PureAdStackTamIntegrationHelpFlyout, undefined, { color: Colors.Violet })} iconName={'help'}>
            Help
          </Button>
        </>
      }
    >
      <PureCatalogItemHeader catalogItemCode={'tam'} catalogItemType={'wrapper'} isLoading={isLoading} isActive={!!tamConfig.apsPubId} baseCDNUrl={baseCDNUrl} />
      <ContentCard color={Colors.Pumpkin}>
        <PubIdForm onSubmit={handleSubmit(onPublisherIdSubmit)}>
          <Input disabled={!isEditing} type={'text'} name={'apsPubId'} rules={{ required: true }} label={'Publisher ID'} control={control} error={getErrorMessage(errors)} />
          {!isEditing ? (
            <Button variant={'primary'} onClick={handleSubmit(onPublisherIdEdit)}>
              Edit
            </Button>
          ) : (
            <Button disabled={!isDirty} variant={'primary'} onClick={handleSubmit(onPublisherIdSubmit)}>
              Save
            </Button>
          )}
        </PubIdForm>
      </ContentCard>
      <SiteGroup>
        {!isLoading ? (
          tamConfig.apsPubId !== '' && tamConfig.sites.length > 0 ? (
            <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
              {sortedSites.map((site) => {
                const siteConfig = tamConfig.siteConfigs.find((s) => site.id === s.siteId) || { siteId: site.id, scopeId: '', isTamEnabled: !!tamConfig.apsPubId }
                return (
                  <TableRow key={site?.id}>
                    <SiteTitle>{site.name}</SiteTitle>
                    <TableCell>
                      <Toggle
                        id={site.id}
                        value={siteConfig?.isTamEnabled === undefined ? !!tamConfig.apsPubId : siteConfig?.isTamEnabled}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          const data = siteConfig as SiteConfig
                          data.isTamEnabled = !data?.isTamEnabled
                          onSiteConfigSubmit(data)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </Table>
          ) : (
            <IllustrationMessage iconName={'night_sky'} iconSize={'190px'} title={'You don’t have any sites yet or your Publisher Id is not set.'} />
          )
        ) : (
          <Skeleton bigger width={'100%'} />
        )}
      </SiteGroup>
    </PageContent>
  )
}

export const PureAdStackTamIntegrationPage = styled(_PureAdStackTamIntegrationPage)``
