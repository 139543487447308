import styled from '@emotion/styled'
import React, { ComponentProps, FC, Fragment } from 'react'
import ReactModal from 'react-modal'
import { ColorUtil, Colors } from '~/assets/style/colors'
import { ElevationLevelValues, Sizes } from '~/assets/style/tokens'
import { ScrollbarStyle } from '~/assets/style/utils'
import { WithClassName } from '~/types/utils'
import Button from './Button'

type ModalProps = WithClassName & {
  onRequestClose: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void
  showCloseButton?: boolean
}

const ContentWrapper = styled.div`
  display: grid;
  row-gap: 18px;
  padding: 18px 24px 24px;
  grid-template-areas:
    'title close'
    'body body'
    'actions actions';
  grid-template-columns: 1fr min-content;
  grid-template-rows: min-content 1fr min-content;
`

const Title = styled.h4`
  grid-area: title;
  display: flex;
  align-items: center;
  margin: 0;
  padding-bottom: -${Sizes[4]};
`

const Body = styled.div`
  grid-area: body;
  padding-top: ${Sizes[4]};
  overflow: auto;
  min-width: 544px;
  width: 100%;
  max-height: 600px;
  ${ScrollbarStyle}
`

const Actions = styled.div`
  grid-area: actions;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`

const CloseWrapper = styled.div`
  grid-area: close;
`

const CLOSE_TIMEOUT_MS = 250
// eslint-disable-next-line react/prop-types
const Container: FC<ComponentProps<typeof ReactModal> & ModalProps> = ({ children, onRequestClose, showCloseButton = true, ...props }) => {
  ReactModal.setAppElement('body')

  const customStyles: ReactModal.Styles = {
    content: {
      top: 'calc(50% - 65px)', //middle of the screen minus header's height
      left: 'calc(50%)',
      transform: 'translate(-50%, -50%)',
      bottom: 'auto',
      right: 'auto',
      border: 'none',
      padding: 0,
    },
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: ColorUtil.hexOpacity(Colors.Jet, 0.4),
      zIndex: ElevationLevelValues.high,
    },
  }
  return (
    <ReactModal closeTimeoutMS={CLOSE_TIMEOUT_MS} style={customStyles} onRequestClose={onRequestClose} {...props}>
      <ContentWrapper>
        {showCloseButton && (
          <CloseWrapper>
            <Button variant={'tertiary'} iconName={'close'} onClick={onRequestClose} />
          </CloseWrapper>
        )}
        {children}
      </ContentWrapper>
    </ReactModal>
  )
}
export const Modal = {
  Container,
  Content: Fragment,
  Title,
  Body,
  Actions,
  CLOSE_TIMEOUT_MS,
}
