export const ANALYTICS_TOOLTIPS = {
  REVENUE: 'Sum of all revenue',
  GAM_REVENUE: 'Data might be inaccurate if user-entered line item CPMs don’t match actual payment.',
  PREBID_REVENUE: 'Sum of all Prebid revenue',
  PREBID_IMPRESSIONS: 'Number of Prebid impressions',
  AUCTION_COUNT: 'Number of ad calls for all ad units',
  ECPM: 'Sum of all revenue / Number of impressions * 1000',
  PREBID_ECPM: 'Sum of all Prebid revenue / Number of Prebid impressions * 1000',
  BID_CPM: 'CPM sum of all Prebid Bid Responses / Number of Bid Responses * 1000',
  WINNING_BIDS_CPM: 'CPM sum of all Prebid Winning Bids / Number of Prebid Winning Bids  * 1000',
  AUCTION_FILL_RATE: 'Number of impressions / Number of auctions',
  AUCTION_RPM: 'Sum of all revenue / Number of auctions * 1000',
  VIEWABILITY: 'Number of viewable impressions / Number of measurable impressions, according to the MRC definition',
  VIEWABLE_IMPRESSIONS: 'Number of viewable impressions, according to the MRC definition.',
  AVG_VIEWABLE_TIME: 'Average time in seconds that an individual impression was deemed viewable.',
  BID_REQUESTS: 'Number of ad calls to a Prebid bidder',
  BID_RESPONSES: 'Number of Prebid bid responses containing a valid impression',
  BID_RATE: 'Number of Prebid bid responses / Number of Prebid bid requests',
  BID_NO_RATE: 'Number of Prebid no responses / Number of Prebid bid requests',
  BID_TIMEOUT_RATE: 'Number of Prebid timeouts / Number of Prebid Bid requests',
  PREBID_WIN_RATE: 'Number of Prebid Winning bids / Number of Prebid bid responses',
  PREBID_WINNING_BIDS: 'Number of Prebid bids where the bid was the highest one.',
  PREBID_DENSITY: 'Average number of bid responses per auction',
  SESSIONS: 'Number of user sessions',
  SESSIONS_RPM: 'Sum of all revenue / Number of user sessions * 1000',
  SESSIONS_IMPRESSIONS: 'Number of impressions / Number of user sessions',
  SESSIONS_PAGEVIEW: 'Number of pages / Number of user sessions',
  PAGE_IMPRESSIONS: 'Number of impressions / Number of pages',
  PAGES_RPM: 'Sum of all revenue / Number of pages * 1000',
  BIDDER_DETAILS_PREBID_REVENUE: 'Header bidding revenues',
  BIDDER_DETAILS_PREBID_WIN_BIDS: 'Sum of HB winning bids before competition with the Adserver',
  BIDDER_DETAILS_NO_BID_RATE: 'Total number of empty bid responses coming from a bidder divided by total number of bid requests sent to the bidder',
  BIDDER_DETAILS_BID_RATE: 'Total number of bid responses coming from a bidder divided by total number of bid requests sent to the bidder',
  BIDDER_DETAILS_TIMEOUT_RATE: "Percentage of ad auctions for which a bidder didn't provide a bid within the time specified in your Prebid.js timeout settings",
  BIDDER_DETAILS_NO_BID: 'Total number of empty bid responses coming from a bidder',
  BIDDER_DETAILS_BID_RESPONSES: 'Total number of valid bid responses coming from a bidder',
  BIDDER_DETAILS_BID_TIMEOUT: 'Total number of ad auctions for which a bidder did not answer within the time specified in your Prebid.js timeout settings',
  BIDDER_DETAILS_PREBID_ECPM: 'Average CPM of Impressions served in Header Bidding after competition with the adserver',
  BIDDER_DETAILS_WIN_CPM: 'Average CPM of winning bids from the Header Bidding auction before competition with the adserver',
  BIDDER_DETAILS_BID_CPM: 'Average CPM of Bid Responses',
  BIDDER_DETAILS_IMP_RPM: 'Average RPM of Impressions served in Header Bidding after competition with the adserver',
  BIDDER_DETAILS_WIN_RPM: 'Average RPM of winning bids from the Header Bidding auction before competition with the adserver',
  BIDDER_DETAILS_BID_RPM: 'Average RPM of Bid Responses',
  BIDDER_DETAILS_BID_REQUESTS: 'Number of ad calls',
  BIDDER_DETAILS_PREBID_IMPRESSIONS: 'Header bidding impressions effectively renderer',
  VIDEO_COMPLETION_RATE: 'Percentage of all started video ads that played through their entire duration to completion',
  VIDEO_ERROR_RATE: 'Percentage of all video ads that experienced errors, such as a VAST redirect error, a video playback error, or an invalid response error.',
  VIDEO_START: 'Number of impressions where the video was played.',
  VIDEO_MIDPOINT: 'The number of times the video played to 50% of its length.',
  VIDEO_FIRST_QUARTILE: 'The number of times the video played to 25% of its length.',
  VIDEO_THIRD_QUARTILE: 'The number of times the video played to 75% of its length.',
  VIDEO_COMPLETE: 'The number of times the video played to 100% of its length.',
  ALL_IMPRESSIONS: 'Number of impressions',
}

export const SUPPORT_MAIL = 'support@pubstack.io' as const // TODO nra tmu 2022-10-11 use it everywhere
