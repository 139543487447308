import { ExploreTimelineData } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DateTime } from 'luxon'
import * as ReactDOMServer from 'react-dom/server'
import { ReactGoogleChartProps } from 'react-google-charts'
import { Color, DataColors } from '~/assets/style/colors'
import { ChartTooltip, ChartTooltipMetricProps } from '~/components/ChartTooltip'
import { LegendProps } from '~/components/Legend'
import { AnalyticsDefaultChartOptions, getAnalyticsChartFormatConfig } from '~/modules/analytics/AnalyticsCharts'
import { FormulaObj } from '~/modules/analytics/formulas/operation-obj'
import { ANALYTICS_CHART_COLOR_CONFIG, getChartTooltipDateFormat } from '~/utils/analytics'
import { DataDistributionType } from './explore'

const colors: { [key: string | number]: Color } = {
  0: DataColors.Lilac,
  1: DataColors.Pool,
  2: DataColors.Milka,
  3: DataColors.Forest,
  4: DataColors.Frog,
  5: DataColors.Sun,
  6: DataColors.Kaiminus,
  7: DataColors.Rose,
  8: DataColors.Violet,
  9: DataColors.Lava,
  'impression-v1': ANALYTICS_CHART_COLOR_CONFIG.hb.color,
  'impression-adx-v1': ANALYTICS_CHART_COLOR_CONFIG.adx.color,
  'impression-open-bidding-v1': ANALYTICS_CHART_COLOR_CONFIG.openBidding.color,
  firstCall: ANALYTICS_CHART_COLOR_CONFIG.firstCall.color,
  refresh: ANALYTICS_CHART_COLOR_CONFIG.refresh.color,
  others: DataColors.SlateGrey,
}

const getChartTooltip = (
  epoch: DateTime,
  formula: FormulaObj<any>,
  rawData: RawData,
  index: number,
  currentEpoch: DateTime,
  dateFormat: string,
  currencySymbol: CurrencySymbol,
  type: DataDistributionType
) => {
  if (epoch > currentEpoch) {
    return '<div style="display: none"></div>'
  }

  const metricsName = Object.keys(rawData.mappings)
  const hbAndAdxLines: ChartTooltipMetricProps[] = metricsName
    .map((name, indexMetric) => {
      const computed = (type === 'percentage' ? formula.percentage : formula.compute)(rawData, index)
      const value = computed[name]
      const display = (type === 'percentage' ? formula.displayablePercentage : formula.displayable)(value, currencySymbol)
      const color = colors[name] ?? colors[indexMetric]
      if (value >= 0) {
        return {
          label: rawData.mappings[name].label,
          iconColor: color,
          value: display,
        } as ChartTooltipMetricProps
      }
      return undefined
    })
    .filter((v): v is ChartTooltipMetricProps => !!v)
  const metrics: ChartTooltipMetricProps[][] = [hbAndAdxLines]
  const tooltipContent = <ChartTooltip date={epoch.toFormat(dateFormat)} metrics={metrics} />
  return ReactDOMServer.renderToString(tooltipContent)
}

const convertData = (rawData: RawData, formula: FormulaObj<any>, currencySymbol: CurrencySymbol, type: DataDistributionType): any[] => {
  return rawData.epoch.map((epoch, index) => {
    const dateFormat = getChartTooltipDateFormat(rawData.epoch)
    return [
      DateTime.fromISO(epoch).toJSDate(),
      getChartTooltip(DateTime.fromISO(epoch), formula, rawData, index, DateTime.now(), dateFormat, currencySymbol, type),
      ...Object.entries((type === 'percentage' ? formula.percentage : formula.compute)(rawData, index)).flatMap(([key, value], index) => [value, colors[key] ?? colors[index]]),
    ]
  })
}

type RawData = ExploreTimelineData<any>
type PureExploreTimelineChartProps = {
  rawData?: RawData
  formula: FormulaObj<any>
  currencySymbol: CurrencySymbol
  type: DataDistributionType
  seriesType: 'bars' | 'line'
}

export const getExploreTimeline = ({ rawData, formula, currencySymbol, type, seriesType }: PureExploreTimelineChartProps): { chart: ReactGoogleChartProps; legends: LegendProps[] } => {
  if (!rawData || Object.keys(rawData.mappings ?? {}).length === 0) {
    return { chart: { data: undefined, chartType: 'ComboChart' }, legends: [] }
  }

  const legends: LegendProps[] = Object.entries(rawData.mappings).map(([key, { label }], index) => ({ label: label, iconColor: colors[key] ?? colors[index] }))
  const chartOptions = {
    ...AnalyticsDefaultChartOptions,
    colors: Object.values(colors),
    seriesType,
    isStacked: true,
    vAxis: { ...AnalyticsDefaultChartOptions.vAxis, ...getAnalyticsChartFormatConfig(type === 'percentage' ? type : formula.type, currencySymbol) },
  }
  const chartData = [
    ['Date', { type: 'string', role: 'tooltip', p: { html: true } }, ...Object.values(rawData.mappings).flatMap((mapping) => [mapping, { role: 'style' }])],
    ...convertData(rawData, formula, currencySymbol, type),
  ]
  return {
    chart: {
      chartType: 'ComboChart',
      options: chartOptions,
      data: chartData,
    },
    legends,
  }
}
