import { AdminTag } from '@pubstack/common/src/tag'
import { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAdminScopes, useAdminTags } from '~/api/admin-api.hook'
import { useToast } from '~/components/Toast/useToasts'
import { downloadCSVdata } from '~/utils/csv'
import { PureAdminScopeSites } from './PureAdminScopeSites'

export const AdminScopeSites: FunctionComponent = () => {
  const { scopeId } = useParams()
  const { all: allAdminTags, byId: adminTagsById } = useAdminTags(null, scopeId ?? '')
  const { byId: adminScopeById } = useAdminScopes(null)
  const [sites, setSites] = useState<AdminTag[]>([])
  const toast = useToast()

  const loadSites = async () => {
    if (scopeId) {
      if (allAdminTags.loading) {
        allAdminTags.abort()
      }
      const sites = await allAdminTags.get()
      setSites(sites)
    }
  }

  const createSite = async (name: string) => {
    if (name && scopeId) {
      if (adminTagsById.loading) {
        adminTagsById.abort()
      }
      const scope = await adminScopeById.get(scopeId)
      const newSite: AdminTag = {
        scopeId,
        name,
        pbjs_adapter: scope.pbjs,
        enabled: true,
      } as AdminTag
      try {
        const createdSite = await adminTagsById.post(newSite)
        setSites([createdSite, ...sites])
        toast.success(
          <>
            <strong>{name}</strong> was successfully created
          </>
        )
      } catch (e) {
        toast.alert(`Something went wrong when trying to create ${name}`)
      }
    }
  }

  const editSite = async (site: AdminTag) => {
    if (site.id && scopeId) {
      if (adminTagsById.loading) {
        adminTagsById.abort()
      }
      await adminTagsById.put(site.id, site)
      toast.success(
        <>
          <strong>{site?.name}</strong> was successfully updated
        </>
      )
      await loadSites()
    } else {
      toast.alert(`Something went wrong when trying to update this site`)
    }
  }

  const deleteSite = async (id: string) => {
    if (id && scopeId) {
      if (adminTagsById.loading) {
        adminTagsById.abort()
      }
      const toBeDeleted = sites.find((s) => s.id === id)
      if (toBeDeleted) {
        setSites([...sites.filter((s) => s.id !== id)])
        await adminTagsById.del(id)
        toast.success(
          <>
            <strong>{toBeDeleted?.name}</strong> was successfully deleted
          </>
        )
      } else {
        toast.alert(`Something went wrong when trying to delete this site`)
      }
    }
  }

  const downloadCSV = () => {
    const csvContent = 'data:text/csv;charset=utf-8,Name,Id,PrebidJS,Loader\n' + sites.map((tag) => [tag.name, tag.id, tag.pbjs_adapter, tag.loader].join(',')).join('\n')
    downloadCSVdata(csvContent, `sites-${scopeId}`)
  }

  useEffect(() => {
    loadSites()
  }, [scopeId])

  return (
    <PureAdminScopeSites
      sites={sites}
      isLoading={allAdminTags.loading || (adminTagsById.loading && !allAdminTags.error && !adminTagsById.error)}
      onNewSite={createSite}
      onDeleteSite={deleteSite}
      onEditSite={editSite}
      onDownload={downloadCSV}
    />
  )
}
