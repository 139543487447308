import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { Color } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Icon, IconName } from '~/components/Icon'
import { WithClassName } from '~/types/utils'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  h1 {
    ${Fonts.H1}
    font-weight: 400;
    margin-bottom: 12px;
  }
  div {
    ${Fonts.P1}
  }
`

type IllustrationMessageProps = WithClassName & {
  iconName: IconName
  iconSize: `${number}px`
  iconColor?: Color
  title?: ReactNode
  message?: ReactNode
}
const _IllustrationMessage: FunctionComponent<PropsWithChildren<IllustrationMessageProps>> = ({ className, iconName, iconSize, iconColor, message, title, children }) => (
  <Wrapper className={className}>
    <Icon width={iconSize} name={iconName} fill={iconColor} />
    {!!(title || message) && (
      <div>
        {title && <h1>{title}</h1>}
        {message && <div>{message}</div>}
      </div>
    )}
    {children}
  </Wrapper>
)
export const IllustrationMessage = styled(_IllustrationMessage)``
