import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { positiveIntegerRegExp } from '@pubstack/common/src/input'
import { HeaderBiddingRule } from '@pubstack/common/src/stack'
import { FunctionComponent, useEffect, useState } from 'react'
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Input, _Input } from '~/components/Input'
import { Modal } from '~/components/Modal'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { Toggle } from '~/components/Toggle'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { HeaderBiddingRuleWithBidders } from '~/modules/adstack/rules/header-bidding/AdStackHeaderBiddingPage'
import { PureAdStackHeaderBiddingDetailsFlyout } from '~/modules/adstack/rules/header-bidding/PureAdStackHeaderBiddingDetailsFlyout'
import { WithClassName } from '~/types/utils'
import { StackFormData } from './PureStackEdit'

const Subtitle = styled.div`
  ${Fonts.P1};
  ${Colors.Jet};
  margin-bottom: 20px;
`

const Title = styled.h2`
  ${Fonts.H2};
  ${Colors.Jet};
  font-weight: 500;
`

const HeaderBiddingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > ${_Input} {
    width: 464px;
  }
`

const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 4px;
`

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 464px;
`

const BidderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

type EditBidderRuleConfirmModalProps = {
  onConfirm: () => void
}

const EditBidderRuleConfirmModal: FunctionComponent<EditBidderRuleConfirmModalProps> = ({ onConfirm }) => {
  const { close } = useGlobalModal()
  return (
    <Modal.Content>
      <Modal.Title>Leaving the page</Modal.Title>

      <Modal.Body>
        <div>
          <p>You are about to leave this page. All stack changes will be lost.</p>
          <p>Do you confirm ?</p>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button variant={'tertiary'} onClick={close}>
          Cancel
        </Button>
        <Button variant={'primary'} onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal.Content>
  )
}

type PureStackHeaderBiddingRulesStepProps = WithClassName & {
  control: Control<StackFormData>
  errors: FieldErrors<StackFormData>
  selectableHeaderBiddingRules: HeaderBiddingRule[]
  bidderAdapters: BidderAdapter[]
  onEdit: (id: string) => void
}
const _PureStackHeaderBiddingRulesStep: FunctionComponent<PureStackHeaderBiddingRulesStepProps> = ({ className, control, errors, selectableHeaderBiddingRules, bidderAdapters, onEdit }) => {
  const NO_RULE = { label: 'All active bidders', value: 'NO_RULE' }
  const biddersSelectOptions: SelectOptionProp<string>[] = [...selectableHeaderBiddingRules.map((l) => ({ label: l.name, value: l.id })), NO_RULE]
  const [ruleDetail, setRuleDetail] = useState<Omit<HeaderBiddingRuleWithBidders, 'numberOfBidderAdapters'>>()
  const { headerBiddingRuleId } = useWatch({ control })
  const modal = useGlobalModal()
  const flyout = useGlobalFlyout()

  useEffect(() => {
    const hbRule = selectableHeaderBiddingRules.find((hb) => hb.id === headerBiddingRuleId)
    if (hbRule) {
      setRuleDetail({ ...hbRule, bidderAdapters })
    }
  }, [headerBiddingRuleId])

  return (
    <div className={className}>
      <Title>Header bidding</Title>
      <Subtitle>Define header bidding settings.</Subtitle> {/* Todo: update this subtitle */}
      <HeaderBiddingWrapper>
        <Controller
          control={control}
          name={'headerBiddingEnabled'}
          render={({ field: { value: headerBiddingEnabled, onChange } }) => {
            return (
              <>
                <ToggleWrapper>
                  <Toggle
                    value={!!headerBiddingEnabled}
                    onClick={() => {
                      onChange(!headerBiddingEnabled)
                    }}
                  />
                  <Subtitle>Header bidding</Subtitle>
                </ToggleWrapper>
                {!!headerBiddingEnabled && (
                  <>
                    <Input
                      type={'number'}
                      control={control}
                      name={'adCallTimeout'}
                      label={'Timeout'}
                      iconRight={'millisecond'}
                      error={errors.adCallTimeout?.message}
                      min={0}
                      rules={{
                        required: 'Timeout required',
                        pattern: { value: positiveIntegerRegExp, message: 'Incorrect value' },
                      }}
                      isMessageBlock={true}
                    />
                    <BidderWrapper>
                      <SelectWrapper>
                        <Select type={'select'} control={control} name={'headerBiddingRuleId'} label={'Bidder rule'} options={biddersSelectOptions} />
                      </SelectWrapper>
                      {ruleDetail && headerBiddingRuleId !== NO_RULE.value && (
                        <>
                          <Button
                            variant="tertiary"
                            onClick={() =>
                              flyout.open(
                                PureAdStackHeaderBiddingDetailsFlyout,
                                {
                                  headerBiddingRule: ruleDetail,
                                  onEdit: () => {
                                    modal.open(EditBidderRuleConfirmModal, {
                                      onConfirm: () => {
                                        onEdit(ruleDetail.id)
                                        modal.close()
                                        flyout.close()
                                      },
                                    })
                                  },
                                },
                                { color: Colors.Forest, width: `816px` }
                              )
                            }
                          >
                            See rule details
                          </Button>
                        </>
                      )}
                    </BidderWrapper>
                  </>
                )}
              </>
            )
          }}
        />
      </HeaderBiddingWrapper>
    </div>
  )
}

export const PureStackHeaderBiddingRulesStep = styled(_PureStackHeaderBiddingRulesStep)``
