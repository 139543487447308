import styled from '@emotion/styled'
import { ControlCenter, ControlCenterSite } from '@pubstack/common/src/controlCenter'
import { DisplayedStack, EMPTY_DISPLAYED_STACK, MultiStackSynchronizePayload, MultiStacksDeployPayload, Stack } from '@pubstack/common/src/stack'
import { FunctionComponent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useControlCenter, useDetailedStacks } from '~/api/adm-api.hook'
import { useUser } from '~/auth/user.hooks'
import { useToast } from '~/components/Toast/useToasts'
import { WithClassName } from '~/types/utils'
import { ControlCenterProductAction, useLogger } from '~/utils/logger'
import { useBreadcrumbs } from '~/utils/useBreadcrumbs'
import { useScopeCurrency } from '~/utils/useScopeCurrency.hooks'
import { PureAdStackControlCenterPage } from './PureAdStackControlCenterPage'

type AdStackControlCenterPageProps = WithClassName
const _AdStackControlCenterPage: FunctionComponent<AdStackControlCenterPageProps> = ({ className }) => {
  const { all, synchronize, deploy } = useControlCenter(null)
  const { details } = useDetailedStacks(null)
  const navigate = useNavigate()
  const [controlCenter, setControlCenter] = useState<ControlCenter>()
  const [stackDetails, setStackDetails] = useState<DisplayedStack>(EMPTY_DISPLAYED_STACK)
  const breadcrumbs = useBreadcrumbs()
  const toast = useToast()
  const logger = useLogger()
  const currencySymbol = useScopeCurrency()
  const hasAdmRefresh = !!useUser()?.hasFeature('admRefresh')

  const loadControlCenter = async () => {
    const controlCenter = await all.get()
    setControlCenter(controlCenter)
  }

  useEffect(() => {
    loadControlCenter()
  }, [])

  const sendLog = async (body: MultiStackSynchronizePayload, actionName: ControlCenterProductAction['actionName'], allControlCenterSites: ControlCenterSite[] | undefined) => {
    const stackCount = new Set((allControlCenterSites ?? []).flatMap((site) => site.stacks.map((stack) => stack.stackId))).size
    const bodyCount = new Set(body.flatMap((payload) => payload.stackId)).size

    await logger.info({
      type: 'control-center',
      action: 'click',
      actionName,
      allSelected: stackCount === bodyCount,
      selectedCount: bodyCount,
      allStacksCount: stackCount,
    })
  }

  const splitPayload = (body: any[]): any[] => Array.from({ length: Math.ceil(body.length / 10) }, (_, index) => body.slice(index * 10, (index + 1) * 10))

  const synchronizeStacks = async (body: MultiStackSynchronizePayload) => {
    try {
      const splittedBody = splitPayload(body)
      const results = await Promise.allSettled(splittedBody.map((bodyPart) => synchronize.post(bodyPart)))
      const hasError = results.some((result) => result.status === 'rejected')
      if (hasError) {
        toast.alert(`Some stacks couldn't be updated.`)
      } else {
        toast.success('Updated versions are now created!')
      }
    } catch (_e) {
      toast.alert(`Some stacks couldn't be updated.`)
    } finally {
      sendLog(body, 'sync', controlCenter?.outOfSync)
      await loadControlCenter()
    }
  }

  const deployStacks = async (body: MultiStacksDeployPayload) => {
    try {
      const splittedBody = splitPayload(body)
      const results = await Promise.allSettled(splittedBody.map((bodyPart) => deploy.post(bodyPart)))
      const hasError = results.some((result) => result.status === 'rejected')
      if (hasError) {
        toast.alert(`Some stacks couldn't be deployed.`)
      } else {
        toast.success('All stacks will be live in few minutes.')
      }
    } catch (_e) {
      toast.alert(`Some stacks couldn't be deployed.`)
    } finally {
      sendLog(body, 'deploy', controlCenter?.waitingForDeployment)
      await loadControlCenter()
    }
  }

  const getDetailedStack = async (stack: Stack): Promise<DisplayedStack> => {
    if (details.loading) {
      details.abort()
    }
    try {
      const detailsStack = await details.get(stack.id, stack.siteId)
      await logger.info({ action: 'click', type: 'detailed-view-flyout', actionName: 'stack', from: 'control-center' })
      return detailsStack
    } catch (exception) {
      toast.alert(`An error occurred while getting the detailed stack.`)
      throw exception
    }
  }

  const onEditStack = (stackId: string, siteId?: string) => {
    navigate(`/adstack/sites/${siteId}/stacks/${stackId}`)
  }

  return (
    <PureAdStackControlCenterPage
      className={className}
      breadcrumbs={breadcrumbs}
      isLoading={all.loading || synchronize.loading || deploy.loading}
      outOfSyncList={controlCenter?.outOfSync ?? []}
      toDeployList={controlCenter?.waitingForDeployment ?? []}
      upToDateList={controlCenter?.upToDate ?? []}
      onCreateUpdatedVersion={synchronizeStacks}
      onDeploy={deployStacks}
      getDetailedStack={getDetailedStack}
      onEditStack={onEditStack}
      currencySymbol={currencySymbol}
      hasAdmRefresh={hasAdmRefresh}
    />
  )
}
export const AdStackControlCenterPage = styled(_AdStackControlCenterPage)``
