import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { ExploreDataObj, ExploreDimData, MappedName } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useCallback, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { FallbackProps } from 'react-error-boundary/dist/declarations/src/types'
import { Widget, WidgetProps } from '~/components/Widget'
import { PureExploreBreakdownWidget } from '~/modules/analytics/explore/PureExploreBreakdownWidget'
import { PureExploreChartWidget } from '~/modules/analytics/explore/PureExploreChartWidget'
import { PureExploreQueryForm } from '~/modules/analytics/explore/PureExploreQueryForm'
import { WithClassName } from '~/types/utils'
import { ExploreFormData, ExploreFormulasConfig } from './explore'

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 16px;
  }
`

const Content = ({
  className,
  onSubmit,
  onBreakdownRowClick,
  onBreakdownSortChange,
  rawData,
  rawDataBreakdown,
  dimensions,
  superError,
  resetSuperError,
  setMetric,
  ...props
}: PureExploreWidgetProps & {
  superError?: boolean
  resetSuperError?: () => void
  setMetric: (value: string) => void
}) => {
  const exploreEmptyQuery = (props.formData.metrics || []).length === 0

  return (
    <>
      {!superError ? (
        <>
          <PureExploreChartWidget {...props} rawData={rawData} exploreEmptyQuery={exploreEmptyQuery} onMetricChange={setMetric} />
          <PureExploreBreakdownWidget {...props} rawData={rawDataBreakdown} exploreEmptyQuery={exploreEmptyQuery} onRowClick={onBreakdownRowClick} onSortChange={onBreakdownSortChange} />
        </>
      ) : (
        <>
          <Widget icon={'empty'} title={''} exploreSuperError onResetSuperError={resetSuperError} onRefreshClick={() => {}} />
          <Widget icon={'empty'} title={''} exploreSuperError onResetSuperError={resetSuperError} onRefreshClick={() => {}} />
        </>
      )}
    </>
  )
}

type PureExploreWidgetProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    onSubmit: (data: ExploreFormData) => Promise<void>
    onBreakdownRowClick: (name: MappedName) => void
    onBreakdownSortChange: (metric: string) => void
    rawData?: ExploreDataObj<any>
    rawDataBreakdown?: ExploreDimData<any>
    formData: ExploreFormData
    currencySymbol: CurrencySymbol
    dimensions: Dimension[]
    exploreFormulasConfig: ExploreFormulasConfig
  }

const _PureExploreWidget: FunctionComponent<PureExploreWidgetProps> = (props) => {
  const [metric, setMetric] = useState('')
  const fallbackComponent = useCallback((propsFallback: FallbackProps) => <Content {...props} setMetric={setMetric} superError={true} resetSuperError={propsFallback.resetErrorBoundary} />, [props])
  return (
    <PageContent>
      <PureExploreQueryForm onSubmit={props.onSubmit} formData={props.formData} dimensions={props.dimensions} exploreFormulasConfig={props.exploreFormulasConfig} currentMetric={metric} />
      <ErrorBoundary FallbackComponent={fallbackComponent}>
        <Content {...props} setMetric={setMetric} />
      </ErrorBoundary>
    </PageContent>
  )
}

export const PureExploreWidget = styled(_PureExploreWidget)``
