import { posthog } from 'posthog-js'
import { FunctionComponent, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { useUser } from '~/auth/user.hooks'
import { currentScopeState } from '~/state'

export const ProductTrackingIntegration: FunctionComponent = () => {
  const user = useUser()
  const scope = useRecoilValue(currentScopeState)
  useEffect(() => {
    if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1' && location.hostname !== '') {
      posthog.init('phc_jdFlMGykhi91rjl3bwXQVlPa92GaOOkh31qsbFQieoP', { api_host: 'https://eu.i.posthog.com' })
      posthog.identify(user?.profile.sub, { email: user?.profile.email, role: user?.getScopeRole(), scopeId: scope.id })
    }
    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: user?.profile.sub,
          email: user?.profile.email,
          role: user?.getScopeRole(),
        },

        account: {
          id: scope.id,
          name: scope.name,
        },
      })
    }
  }, [])

  return null
}
