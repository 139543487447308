import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Flyout } from '~/components/Flyout'

const HelpText = styled.div`
  h2 {
    padding: 24px 0 12px 0;
  }
`

const _PureAdstackAdUnitHelpFlyout: FunctionComponent = () => {
  return (
    <Flyout.Content>
      <Flyout.Title>Help</Flyout.Title>
      <Flyout.Body>
        <HelpText>
          <h2>How to connect ad units to the ad management system?</h2>
          <p>1. Click on “Add ad units”.</p>
          <p>2. Define the ad unit and settings in the form.</p>
          <h2>Why should I add the ad units to the inventory?</h2>
          <p>To monetize ad units with the ad management, they must be declared.</p>
          <h2>Can I use one ad unit on several sites?</h2>
          <p>One ad unit can be used for one or several sites as long as the technical ID (or div ID) is the same on each site.</p>
          <br />
          <p>If you need an ad unit to have a specific behaviour for one context or site, we recommend to create a new one, which can have the same technical ID as another ad unit. </p>
        </HelpText>
      </Flyout.Body>
    </Flyout.Content>
  )
}

export const PureAdstackAdUnitHelpFlyout = styled(_PureAdstackAdUnitHelpFlyout)``
