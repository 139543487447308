import styled from '@emotion/styled'
import { ContextKey } from '@pubstack/common/src/contextKey'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import Chip from '~/components/Chip'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { Input } from '~/components/Input'
import Table, { handleTableSearchAndSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'

type PureAdStackContextKeysPageProps = WithClassName & {
  contextKeys: ContextKey[]
  isLoading: boolean
  scopeId?: string
  onCreateContextKey: () => void
  onUpdateContextKey: (contextKey: ContextKey) => void
  breadcrumbs: React.ReactNode
}

const ContextKeysWrapper = styled.div`
  padding: 0px;
`

const ActionsTableCell = styled(TableCell)`
  width: 0px; /** necessary to get the last cell to shrink down to hug action buttons */
`
const ChipTableCell = styled(TableCell)`
  width: 75%;
`
const ButtonActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

const FilterAction = styled.form`
  margin: 12px 0px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const ValueChipsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`

const _PureAdStackContextKeysPage: FunctionComponent<PureAdStackContextKeysPageProps> = ({ className, contextKeys, isLoading, onCreateContextKey, onUpdateContextKey, breadcrumbs }) => {
  const MAX_DISPLAYED_VALUE = 10
  const [columns, setColumns] = useState<TableColumns<ContextKey>>([
    {
      name: 'Name',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'Values',
      isSortable: false,
    },
    {
      name: 'Action',
      isSortable: false,
    },
  ])

  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })

  const search = useWatch({ control, name: 'search' })

  const sortAndGroupContextKeys = (ContextKeys: ContextKey[]) => handleTableSearchAndSort(columns, ContextKeys, search, ['name'])
  const displayedContextKeys = [...sortAndGroupContextKeys(contextKeys)]
  const newContextKeyButton = (
    <Button
      variant={'primary'}
      onClick={() => {
        onCreateContextKey()
      }}
    >
      New context key
    </Button>
  )

  useEffect(() => {
    sortAndGroupContextKeys(contextKeys)
  }, [])

  return (
    <ContextKeysWrapper className={className}>
      {isLoading || contextKeys?.length > 0 ? (
        <>
          <FilterAction>
            <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
            {contextKeys?.length > 0 && newContextKeyButton}
          </FilterAction>
          <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
            {displayedContextKeys.map((contextKey) => (
              <TableRow key={contextKey.name} onClick={() => onUpdateContextKey(contextKey)}>
                <TableCell>{contextKey.name}</TableCell>
                <ChipTableCell>
                  <ValueChipsWrapper>
                    {contextKey.values.map(
                      (value, i) =>
                        value &&
                        i < MAX_DISPLAYED_VALUE && (
                          <Chip iconLeft={value === contextKey.defaultValue ? 'star' : undefined} key={i} text={value} color={value === contextKey.defaultValue ? Colors.Violet : Colors.Milka} />
                        )
                    )}
                    {contextKey.values.length > MAX_DISPLAYED_VALUE && <span>+ {contextKey.values.length - MAX_DISPLAYED_VALUE} values</span>}
                  </ValueChipsWrapper>
                </ChipTableCell>
                <ActionsTableCell>
                  <ButtonActions>
                    <Button
                      iconName={'edit'}
                      variant={'tertiary'}
                      title={'Edit'}
                      onClick={() => {
                        onUpdateContextKey(contextKey)
                      }}
                    />
                  </ButtonActions>
                </ActionsTableCell>
              </TableRow>
            ))}
          </Table>
        </>
      ) : (
        <IllustrationMessage iconName={'night_sky'} iconSize={'190px'} title={'There is no context key.'}>
          {newContextKeyButton}
        </IllustrationMessage>
      )}
    </ContextKeysWrapper>
  )
}

export const PureAdStackContextKeysPage = styled(_PureAdStackContextKeysPage)``
