import styled from '@emotion/styled'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FloorRule } from '@pubstack/common/src/stack'
import { FunctionComponent, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { PageContent } from '~/components/PageContent'
import { Tooltip } from '~/components/Tooltip'
import Table, { handleTableSort, onColumnSort, SortableColumn, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { PureUsedStackTooltip } from '~/modules/adstack/rules/PureUsedStackTooltip'
import { countStacksUse } from '~/modules/adstack/rules/countStacksUsed'
import { WithClassName } from '~/types/utils'
import { displayWithCurrency } from '~/utils/string'

type PureAdStackFloorPricePageProps = WithClassName & {
  floorPriceRules: FloorRule[]
  currencySymbol: CurrencySymbol
  isLoading: boolean
  breadcrumbs: React.ReactNode
  onCreateRule: () => void
  onEditRule: (id: string) => void
}

const ActionsTableCell = styled(TableCell)`
  width: 0px; /** necessary to get the last cell to shrink down to hug action buttons */
`

const ButtonActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

const FilterAction = styled.form`
  margin: 12px 0px;
  display: flex;
`

type FloorRuleListed = Omit<FloorRule, 'creationTime' | 'technicalId'>

const _PureAdStackFloorPricePage: FunctionComponent<PureAdStackFloorPricePageProps> = ({ className, floorPriceRules, currencySymbol, isLoading, breadcrumbs, onCreateRule, onEditRule }) => {
  const [columns, setColumns] = useState<TableColumns<FloorRuleListed>>([
    {
      name: 'Rule',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'name',
    },
    {
      name: 'Floor price',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'floor',
    },
    {
      name: 'Used on',
      isSortable: true,
      order: 'ascending',
      attributeSort: 'stacksUse',
    },
    {
      name: 'Actions',
      isSortable: false,
    },
  ])

  const { control } = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  })

  const prettyPrintFloorPrice = (floor: number) => displayWithCurrency((floor / 100).toFixed(2), currencySymbol)

  const search = useWatch({ control, name: 'search' })
  const searchAttributes: (keyof FloorRuleListed)[] = ['name', 'floor']
  const floorPriceRulesWithNoRule: FloorRuleListed[] = [...floorPriceRules, { id: 'NO_RULE', floor: 0, name: 'No floor price' }]
  const searchedFloorPriceRules = floorPriceRulesWithNoRule.filter((f) => {
    return searchAttributes.some((attr) => {
      const value = f[attr]
      if (value) {
        if (attr === 'floor') {
          const floorPrice = prettyPrintFloorPrice(f.floor)
          return floorPrice.includes(search.toLowerCase()) || floorPrice.replace('.', ',').includes(search.toLowerCase())
        } else {
          return value.toString().toLowerCase().includes(search.toLowerCase())
        }
      }
    })
  })
  const sortedColumn = (columns.find((c) => c.isSortable && c.order !== 'none') || columns[0]) as SortableColumn<FloorRuleListed>
  let sortedFloorPriceRules: FloorRuleListed[] = []
  if (sortedColumn.attributeSort === 'stacksUse') {
    sortedFloorPriceRules = searchedFloorPriceRules.sort((a, b) => (sortedColumn.order === 'ascending' ? 1 : -1) * (countStacksUse(b.stacksUse) - countStacksUse(a.stacksUse)))
  } else {
    sortedFloorPriceRules = handleTableSort(columns, searchedFloorPriceRules)
  }

  return (
    <PageContent
      className={className}
      breadcrumbs={breadcrumbs}
      headerActions={
        <Button variant={'primary'} onClick={onCreateRule}>
          New rule
        </Button>
      }
    >
      <FilterAction>
        <Input name={'search'} type={'text'} iconLeft={'search'} labelIsPlaceholder label={'Search'} control={control} />
      </FilterAction>
      <Table columns={columns} isLoading={isLoading} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {sortedFloorPriceRules.map((floorRule) => (
          <TableRow key={floorRule.id} onClick={floorRule.name !== 'No floor price' ? () => onEditRule(floorRule.id) : undefined}>
            <TableCell>{floorRule.name}</TableCell>
            <TableCell>{floorRule.id !== 'NO_RULE' ? prettyPrintFloorPrice(floorRule.floor) : '-'}</TableCell>
            <TableCell>{floorRule.id === 'NO_RULE' ? '-' : floorRule.stacksUse ? <PureUsedStackTooltip stacksBySite={floorRule.stacksUse} /> : <span>0 stack</span>}</TableCell>
            <ActionsTableCell>
              <ButtonActions>
                <Tooltip title={floorRule.scopeId ? 'Edit' : `Can't edit a Pubstack rule`} positions={['left', 'bottom', 'top', 'right']}>
                  <Button iconName={'edit'} variant={'tertiary'} title={'Edit'} onClick={() => onEditRule(floorRule.id)} disabled={!floorRule.scopeId} />
                </Tooltip>
              </ButtonActions>
            </ActionsTableCell>
          </TableRow>
        ))}
      </Table>
    </PageContent>
  )
}

export const PureAdStackFloorPricePage = styled(_PureAdStackFloorPricePage)``
