import { IdModuleNames } from './userSync'

export type CatalogItemType = 'prebidModule' | 'prebidBidder' | 'wrapper' | 'adServer' | 'otherModules' | 'prebidWrapper'

export const CATALOG_ITEM_TYPE: { [key in CatalogItemType]: string } = {
  prebidModule: 'Prebid module',
  prebidBidder: 'Prebid bidder',
  wrapper: 'Wrapper',
  adServer: 'Ad Server',
  otherModules: 'Other module',
  prebidWrapper: 'Prebid Wrapper',
} as const

/**
 * Generic object for catalog
 */
export interface CatalogItem {
  id: string
  displayName: string
  code: string
  isUsed: boolean
  type: CatalogItemType
  category?: string
}

/** GAM Ad Server */
export const GAM_AD_SERVER_ITEM: CatalogItem = {
  id: 'gam',
  displayName: 'Google Ad Manager (GAM)',
  code: 'gam',
  isUsed: true,
  type: 'adServer',
} as const

/** Prebid Wrapper */
export const PREBID_WRAPPER: CatalogItem = {
  id: 'prebid',
  displayName: 'Prebid',
  code: 'prebid',
  isUsed: true,
  type: 'wrapper',
} as const

/** TAM */
export const TAM_WRAPPER_ITEM: CatalogItem = {
  id: 'tam',
  displayName: 'Amazon TAM',
  code: 'tam',
  isUsed: true,
  type: 'wrapper',
}

/** Prebid Module **/
export type PrebidModuleNames = ModuleRTDNames | IdModuleNames

/** Module Real Time Data **/
export const REAL_TIME_DATA_MODULES_CONFIG = [
  {
    id: 'e6419d23-a9b8-460a-8bf9-167e66eb77e0',
    name: 'ias',
    displayName: 'Integral Ad Science',
  },
  {
    id: 'c0af2113-5834-4dcf-b3a5-ee7bdf3ad98f',
    name: 'weborama',
    displayName: 'Weborama',
  },
  {
    id: 'fe066a17-9eff-4f76-aef4-a446e747a6a5',
    name: 'permutive',
    displayName: 'Permutive',
  },
  {
    id: 'cdfa6edb-4a66-481b-84f5-88bddbf99110',
    name: 'greenbidsRtdProvider',
    displayName: 'GreenBids',
  },
  {
    id: '9e0a21c6-2d3e-46a5-b0c2-715553a5b870',
    name: 'SirdataRTDModule',
    displayName: 'Sirdata',
  },
  {
    id: 'a580eea0-e8cf-4431-b255-037bda4ebade',
    name: 'oxxionRtd',
    displayName: 'Oxxion',
  },
  {
    id: '40e4074b-733c-46e4-8874-6a75539044d5',
    name: 'geoedge',
    displayName: 'Geoedge',
  },
  {
    id: '8942d01d-f810-4d1d-a470-9dedc407d9f8',
    name: 'anonymised',
    displayName: 'Anonymised',
  },
] as const
export type ModuleRTDNames = (typeof REAL_TIME_DATA_MODULES_CONFIG)[number]['name']

export type ProviderIAS = {
  name: 'ias'
  waitForIt?: boolean
  params: { pubId: string; pageUrl?: string; keyMappings?: { [key in string]: string }; adUnitPath?: { [key in string]: string } }
}
export type ProviderWeborama = {
  name: 'weborama'
  waitForIt?: boolean
  params: {
    setPrebidTargeting?: boolean
    sendToBidders?: boolean | string[]
    weboCtxConf?: {
      [key in string]: string
    }
    weboUserDataConf?: {
      [key in string]: string
    }
    sfbxLiteDataConf?: {
      [key in string]: string
    }
    onData?: string
  }
}

export type ProviderGeoedge = {
  name: 'geoedge'
  waitForIt?: boolean
  params: {
    key: string
  }
}
export type ProviderOxxionRtd = {
  name: 'oxxionRtd'
  waitForIt?: boolean
  params: {
    domain: string
    threshold: boolean
    samplingRate: number
  }
}

export type ProviderPermutive = {
  name: 'permutive'
  waitForIt?: boolean
  params: {
    acBidders: string[]
  }
}

export type ProviderGreenbids = {
  name: 'greenbidsRtdProvider'
  waitForIt?: boolean
  params: {
    pbuid: string
  }
}

export type ProviderSirdata = {
  name: 'SirdataRTDModule'
  waitForIt?: boolean
  params: {
    partnerId: number
    key: number
    setGptKeyValues?: boolean
    contextualMinRelevancyScore?: number
    actualUrl?: string
    bidders?: object[]
  }
}

export type ProviderAnonymized = {
  name: 'anonymised'
  waitForIt?: boolean
  params: {
    cohortStorageKey: 'cohort_ids'
    bidders: string[]
    segtax: 1000
  }
}

export const RTD_MODULES_PBJS_NAMES: { [ModuleName in ModuleRTDNames]: string } = {
  ias: 'iasRtdProvider',
  weborama: 'weboramaRtdProvider',
  permutive: 'permutiveRtdProvider',
  greenbidsRtdProvider: 'greenbidsRtdProvider',
  SirdataRTDModule: 'sirdataRtdProvider',
  oxxionRtd: 'oxxionRtdProvider',
  geoedge: 'geoedgeRtdProvider',
  anonymised: 'anonymisedRtdProvider',
}

export type Provider<Name> = Name extends 'ias'
  ? ProviderIAS
  : Name extends 'weborama'
    ? ProviderWeborama
    : Name extends 'permutive'
      ? ProviderPermutive
      : Name extends 'greenbidsRtdProvider'
        ? ProviderGreenbids
        : Name extends 'SirdataRTDModule'
          ? ProviderSirdata
          : Name extends 'oxxionRtd'
            ? ProviderOxxionRtd
            : Name extends 'geoedge'
              ? ProviderGeoedge
              : Name extends 'anonymised'
                ? ProviderAnonymized
                : never

/** Type for the properties useful in a JSON edition of the module */
export type ProviderJSON<Name> = Omit<Provider<Name>, 'name'>

export type SiteModuleRTDConfigurable<Name> = { siteId: string; siteName: string; dataProvider: Provider<Name> | undefined }
export type ModuleRTD<Name extends ModuleRTDNames> = { id: string; name: ModuleRTDNames; displayName: string; type: 'prebidModule'; config: SiteModuleRTDConfigurable<Name>[] }
export type DataProviderRTD<Name> = Provider<Name> & { waitForIt?: boolean }
