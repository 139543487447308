import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { AdStackRefreshGlobalSettingsData, AdmRefreshGlobalSettings } from '@pubstack/common/src/adstack/refresh-global-settings'
import { AdUnit } from '@pubstack/common/src/adunit'
import { BidderCatalog, BidderCatalogAlias, BidderParam, MappedBidder } from '@pubstack/common/src/bidderCatalog'
import { CatalogItem, DataProviderRTD, ModuleRTD, ModuleRTDNames } from '@pubstack/common/src/catalogItem'
import { ContextKey } from '@pubstack/common/src/contextKey'
import { ControlCenter } from '@pubstack/common/src/controlCenter'
import { ExtraScript, ExtraScriptWithUrl } from '@pubstack/common/src/extraScript'
import { GamConfig } from '@pubstack/common/src/gam/gamConfig'
import { SiteSpaConfig } from '@pubstack/common/src/kleanadsScopeConfig'
import { PrebidConfig } from '@pubstack/common/src/prebidConfig'
import { AdStackSite } from '@pubstack/common/src/site'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import {
  DisplayedStack,
  FloorRule,
  HeaderBiddingRule,
  LazyLoadingRule,
  MultiStackSynchronizePayload,
  MultiStacksDeployPayload,
  RefreshRule,
  SaveStack,
  SiteStacksByProperty,
  Stack,
} from '@pubstack/common/src/stack'
import { StackContext } from '@pubstack/common/src/stackContext'
import { TamConfig } from '@pubstack/common/src/tam/tamConfig'
import { IdModuleNames, ScopeModuleIdentification, UserSyncUserId } from '@pubstack/common/src/userSync'
import { useFetchWithException } from './api-access'

export const useAdStackSites = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<AdStackSite[]>(`/adm/sites`, deps || undefined)
  return { all: { ...fetchAll } }
}

export const useSiteConfigs = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<SiteConfig>(`/adm/sites`, deps || undefined)
  const getSiteConfig = (siteId: string) => fetch.get(`${siteId}/site-config`)
  return { byId: { ...fetch, get: getSiteConfig } }
}

export const useSpaConfig = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<SiteSpaConfig>(`/adm/sites`, deps || undefined)
  const getSpaConfig = (siteId: string) => fetch.get(`${siteId}/spa-config`)
  const putSpaConfig = (siteId: string, spaConfig: SiteSpaConfig) => fetch.put(`${siteId}/spa-config`, spaConfig)
  return { byId: { ...fetch, get: getSpaConfig, put: putSpaConfig } }
}

export const useFloorRules = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<FloorRule[]>(`/adm/floor-rules`, deps || undefined)
  const fetch = useFetchWithException<FloorRule>(`/adm/floor-rules`, deps || undefined)
  return { all: { ...fetchAll }, byId: { ...fetch } }
}

export const useLazyLoadingRules = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<LazyLoadingRule[]>(`/adm/lazy-loading-rules`, deps || undefined)
  const fetch = useFetchWithException<LazyLoadingRule>(`/adm/lazy-loading-rules`, deps || undefined)
  return { all: { ...fetchAll }, byId: { ...fetch } }
}

export const useDetailedStacks = (deps: unknown[] | null = []) => {
  const details = useFetchWithException<DisplayedStack>({}, deps || undefined)
  const getDetailedStack = (stackId: string, siteId: string) => details.get(`/adm/sites/${siteId}/stacks/${stackId}/details`)
  return {
    details: { ...details, get: getDetailedStack },
  }
}

export const useHeaderBiddingRules = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<HeaderBiddingRule[]>(`/adm/header-bidding-rules`, deps || undefined)
  const fetch = useFetchWithException<HeaderBiddingRule>(`/adm/header-bidding-rules`, deps || undefined)
  return { all: { ...fetchAll }, byId: { ...fetch } }
}

export const useSitesStacks = (deps: unknown[] | null = [], siteId: string) => {
  const baseUrl = `/adm/sites/${siteId}/stacks`
  const fetchAll = useFetchWithException<Stack[]>(baseUrl, deps || undefined)
  const validate = useFetchWithException<boolean>(`${baseUrl}/validate`, {}, deps || undefined)
  const fetch = useFetchWithException<Stack>(baseUrl, deps || undefined)
  const fetchSiteStacks = useFetchWithException<SiteStacksByProperty>('/adm/sites/stacks/by-adunits', deps || undefined)
  const put = (stackId: string, stack: SaveStack) => fetch.put(stackId, stack)
  const post = (stack: SaveStack) => fetch.post(stack)
  const deploy = (stackId: Stack['stackId'], id: Stack['id'], abTest: number) => fetch.post(`${stackId}/deploy`, { id, abTest })
  const updatePriorities = (changedStacks: { stackId: Stack['stackId']; priority: number }[]) => fetch.post(`/priorities`, changedStacks)
  const synchronize = (id: Stack['id']) => fetch.post(`/${id}/synchronize`)
  const getById = (id: Stack['id']) => fetch.get(id)
  const validateName = (stackName: string) => validate.get(stackName)

  const archive = (stack: Stack) => fetch.post(`/${stack.stackId}/archive`, stack)
  const unarchive = (stack: Stack) => fetch.post(`/${stack.stackId}/unarchive`, stack)
  return { all: { ...fetchAll, fetchSiteStacks: fetchSiteStacks.get }, byId: { ...fetch, put, post, get: getById }, validateName, deploy, updatePriorities, synchronize, archive, unarchive }
}

export const useAdUnits = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<AdUnit>(`/adm/adunits`, {}, deps || undefined)
  const validate = useFetchWithException<boolean>(`/adm/adunits/validate`, {}, deps || undefined)
  const fetchAll = useFetchWithException<AdUnit[]>(`/adm/adunits`, {}, deps || undefined)
  const updateBiddersOutstream = useFetchWithException<void>(`/adm/adunits/bidders-with-outstream`, {}, deps || undefined)
  const getById = (id: AdUnit['id']) => fetch.get(id)
  const put = (adUnit: AdUnit) => fetch.put(adUnit.id, adUnit)
  const validateName = (adUnitName: string) => validate.get(adUnitName)
  const patchBiddersOutstream = (body: { bidderName: string; outstream: boolean }) => updateBiddersOutstream.patch(body)
  return { all: { ...fetchAll }, byId: { ...fetch, get: getById, put }, validateName, patchBiddersOutstream, outstreamLoading: updateBiddersOutstream.loading }
}

export const useContextKeys = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<ContextKey>(`/adm/context-keys`, {}, deps || undefined)
  const validate = useFetchWithException<boolean>(`/adm/context-keys/validate`, {}, deps || undefined)
  const fetchAll = useFetchWithException<ContextKey[]>(`/adm/context-keys`, {}, deps || undefined)
  const put = (contextKey: ContextKey) => fetch.put(contextKey)
  const getByName = (name: ContextKey['name']) => fetch.get(name)
  const validateName = (contextKeyName: string) => validate.get(contextKeyName)
  return { all: { ...fetchAll }, byId: { ...fetch, put, get: getByName }, validateName }
}

export const useContexts = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<StackContext>(`/adm/contexts`, {}, deps || undefined)
  const fetchAll = useFetchWithException<StackContext[]>(`/adm/contexts`, {}, deps || undefined)
  const post = (context: StackContext) => fetch.post(context)
  const put = (context: StackContext) => fetch.put(context.id, context)
  const getById = (id: StackContext['id']) => fetch.get(id)
  return { all: { ...fetchAll }, byId: { ...fetch, post, put, get: getById } }
}

export const useBidderCatalog = (deps: unknown[] | null = []) => {
  const bidderCatalogFetch = useFetchWithException<BidderCatalog>(`/adm/catalog/bidder`, {}, deps || undefined)
  const getById = (id: BidderCatalog['id']) => bidderCatalogFetch.get(id)
  return { byId: { ...bidderCatalogFetch, get: getById } }
}

export const useBidderCatalogAlias = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<BidderCatalogAlias[]>(`/adm/catalog/bidder`, {}, deps || undefined)
  const fetch = useFetchWithException<BidderCatalogAlias>(`/adm/catalog/bidder`, {}, deps || undefined)
  const addAlias = (id: BidderCatalog['id'], label: string) => fetchAll.post(`/${id}/aliases`, { bidderLabel: label })
  const getAliases = (id: BidderCatalog['id']) => fetchAll.get(`/${id}/aliases`)
  return { all: { ...fetchAll, get: getAliases }, byId: { ...fetch, post: addAlias } }
}

export const useSiteMapping = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<MappedBidder[]>(`/adm/site-mapping`, {}, deps || undefined)
  const getMappedBiddersBySiteId = (id: string) => fetchAll.get(`/${id}`)
  return { bySiteId: getMappedBiddersBySiteId, loading: fetchAll.loading }
}

export const useBidderParams = (deps: unknown[] | null = []) => {
  const downloadTemplateFetch = useFetchWithException<string>(`/adm/bidder-params`, {}, deps || undefined)
  const downloadMappingFetch = useFetchWithException<string>(`/adm/bidder-params`, {}, deps || undefined)
  const uploadFetch = useFetchWithException<{ bidderName: string; siteId: string; isUsed: boolean }>(`/adm/bidder-params`, {}, deps || undefined)
  const setBidAdjustmentFetch = useFetchWithException<void>(`/adm/bidder-params`, {}, deps || undefined)
  const download = (bidderName: string, fullMapping?: boolean) => (fullMapping ? downloadMappingFetch.get(`${bidderName}/mapping`) : downloadTemplateFetch.get(`${bidderName}/template`))
  const downloadBySite = (bidderName: string, siteId: string, fullMapping?: boolean) =>
    fullMapping ? downloadMappingFetch.get(`${bidderName}/mapping?siteId=${siteId}`) : downloadTemplateFetch.get(`${bidderName}/template?siteId=${siteId}`)
  const upload = (bidderName: string, csv: File) => {
    const data = new FormData()
    data.append('csv', csv)
    return uploadFetch.put(`/${bidderName}`, data)
  }
  const uploadBySite = (bidderName: string, csv: File, siteId: string, partialUpload?: boolean) => {
    const data = new FormData()
    data.append('csv', csv)
    const url = `/${bidderName}?siteId=${siteId}${partialUpload ? '&partial=true' : ''}`
    return uploadFetch.put(url, data)
  }
  const uploadBySiteAndAdunit = (params: BidderParam[], siteId: string, adUnitName: string) => {
    const url = `?siteId=${siteId}&adUnitName=${adUnitName}`
    return uploadFetch.put(url, params)
  }
  const setBidAjustment = (bidderName: string, adUnitNames: string[], bidAdjustment?: number) => setBidAdjustmentFetch.put(`${bidderName}/bidAdjustment`, { bidAdjustment, adUnitNames })
  return {
    download,
    downloadBySite,
    upload,
    uploadBySite,
    uploadBySiteAndAdunit,
    downloadMappingLoading: downloadMappingFetch.loading,
    downloadMappingAbort: downloadMappingFetch.abort,
    downloadTemplateLoading: downloadTemplateFetch.loading,
    downloadTemplateAbort: downloadTemplateFetch.abort,
    uploadLoading: uploadFetch.loading,
    uploadAbort: uploadFetch.abort,
    setBidAjustment,
    bidAdjustmentLoading: setBidAdjustmentFetch.loading,
  }
}

export const useCatalogItems = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<CatalogItem[]>(`/adm/catalog`, {}, deps || undefined)
  return { all: { ...fetchAll } }
}

export const useGamConfig = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<GamConfig>(`/adm/gam-config`, {}, deps || undefined)
  const putNetworkId = (networkId: string) => fetch.put(networkId)
  return { byId: { ...fetch, putNetworkId } }
}

export const useGamSiteConfig = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<SiteConfig>(`/adm/gam-config/site-config`, {}, deps || undefined)
  const putSiteConfig = (siteConfig: SiteConfig) => fetch.put(siteConfig.siteId, siteConfig)
  return { byId: { ...fetch, put: putSiteConfig } }
}

export const usePrebidConfig = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<PrebidConfig>(`/adm/prebid-config`, {}, deps || undefined)
  return { prebidConfig: { loading: fetch.loading, get: fetch.get } }
}

export const usePrebidSiteConfig = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<SiteConfig>('/adm/prebid-config/site-config', {}, deps || undefined)
  const putSiteConfig = (siteConfig: SiteConfig) => fetch.put(siteConfig.siteId, siteConfig)
  return { prebidSiteConfig: { loading: fetch.loading, put: putSiteConfig } }
}

export const usePrebidModulesConfig = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<(DataProviderRTD<ModuleRTDNames> | UserSyncUserId<IdModuleNames>)[]>('/adm/prebid-config/modules', {}, deps || undefined)
  const get = (siteId: string) => fetch.get(`/${siteId}`)
  return { prebidModulesConfig: { get, loading: fetch.loading } }
}

export const useIdentityModule = <ModuleName extends IdModuleNames>(deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<ScopeModuleIdentification<ModuleName>>(`/adm/catalog/identity`, {}, deps || undefined)
  const fetch = useFetchWithException<ScopeModuleIdentification<ModuleName>['userIdData']>(`/adm/catalog/identity`, {}, deps || undefined)
  const put = (id: string, siteId: string, data: UserSyncUserId<ModuleName>) => fetch.put(`/${id}/${siteId}`, data)
  const get = (id: string) => fetchAll.get(`/${id}`)
  return { all: { ...fetchAll, get }, byId: { ...fetch, put } }
}

export const useRealTimeDataModule = <ModuleName extends ModuleRTDNames>(deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<ModuleRTD<ModuleName>>(`/adm/catalog/real-time-data`, {}, deps || undefined)
  const fetch = useFetchWithException<ModuleRTD<ModuleName>['config']>(`/adm/catalog/real-time-data`, {}, deps || undefined)
  const put = (id: string, siteId: string, data: DataProviderRTD<ModuleName>) => fetch.put(`/${id}?siteId=${siteId}`, data)
  const get = (id: string) => fetchAll.get(`/${id}`)
  return { all: { ...fetchAll, get }, byId: { ...fetch, put } }
}

export const useControlCenter = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<ControlCenter>(`/adm/control-center`, {}, deps || undefined)
  const fetchAction = useFetchWithException<void>(`/adm/control-center`, {}, deps || undefined)
  const synchronize = (body: MultiStackSynchronizePayload) => fetchAction.post(`/synchronize`, body)
  const deploy = (body: MultiStacksDeployPayload) => fetchAction.post(`/deploy`, body)
  return { all: { ...fetchAll }, synchronize: { ...fetchAction, post: synchronize }, deploy: { ...fetchAction, post: deploy } }
}

export const useTamConfig = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<TamConfig>(`/adm/tam-config`, {}, deps || undefined)
  const putPubId = (publisherId: TamConfig) => fetch.put(publisherId)
  return { byId: { ...fetch, putPubId } }
}

export const useTamSiteConfig = (deps: unknown[] | null = []) => {
  const fetch = useFetchWithException<SiteConfig>(`/adm/tam-config/site-config`, {}, deps || undefined)
  const putSiteConfig = (siteConfig: SiteConfig) => fetch.put(siteConfig.siteId, siteConfig)
  return { byId: { ...fetch, put: putSiteConfig } }
}

export const useBidderAdapters = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<BidderAdapter[]>('/adm/bidder-adapters', {}, deps || undefined)
  return { all: { ...fetchAll } }
}

export const useRefreshRules = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<RefreshRule[]>(`/adm/refresh-rules`, deps || undefined)
  const fetch = useFetchWithException<RefreshRule>(`/adm/refresh-rules`, deps || undefined)
  const loadExcludedCampaignsAction = useFetchWithException<void>(`/adm/refresh-rules`, {}, deps || undefined)
  const loadExcludedCampaigns = () => loadExcludedCampaignsAction.post('/global-settings/load-excluded-campaigns')
  const fetchGlobalSettings = useFetchWithException<AdStackRefreshGlobalSettingsData>(`/adm/refresh-rules/global-settings`, {}, deps || undefined)
  const saveGlobalSettings = useFetchWithException<AdmRefreshGlobalSettings>(`/adm/refresh-rules/global-settings`, {}, deps || undefined)
  return { all: { ...fetchAll }, byId: { ...fetch }, loadExcludedCampaigns, fetchGlobalSettings, saveGlobalSettings }
}

export const useStacks = (deps: unknown[] | null = []) => {
  const fetchAll = useFetchWithException<Stack[]>(`/adm/stacks`, {}, deps || undefined)
  const fetch = useFetchWithException<Stack>(`/adm/stacks`, {}, deps || undefined)
  const getById = (id: Stack['id']) => fetch.get(id)
  return { all: { ...fetchAll }, byId: { ...fetch, getById } }
}

export const useExtraScripts = (deps: unknown[] | null = [], siteId: string) => {
  const edit = useFetchWithException<ExtraScript>(`adm/sites/${siteId}/extra-scripts`, deps || undefined)
  const upload = (extraScript: ExtraScript, extraScriptContent?: File | null) => {
    const data = new FormData()
    data.append('extraScript', JSON.stringify(extraScript))
    if (extraScriptContent) {
      data.append('content', extraScriptContent)
    }
    return edit.post(data)
  }
  const fetchAll = useFetchWithException<ExtraScriptWithUrl[]>(`adm/sites/${siteId}/extra-scripts`, deps || undefined)
  return { all: { ...fetchAll }, upload, update: edit.put }
}
