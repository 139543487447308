import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { ExploreDataObj, MappedName } from '@pubstack/common/src/analytics/query'
import { NonNullable } from '@pubstack/common/src/assertion'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { ReactGoogleChartProps } from 'react-google-charts'
import Button from '~/components/Button'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { TabProp, Tabs } from '~/components/Tabs'
import { TimelineMetricProps } from '~/components/TimelineMetric'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { DataDistributionType, ExploreFormData, ExploreFormulasConfig } from '~/modules/analytics/explore/explore'
import { getExploreLeaderboard } from '~/modules/analytics/explore/exploreLeaderboardChart'
import { getExploreTimeline } from '~/modules/analytics/explore/exploreTimelineChart'
import { WithClassName } from '~/types/utils'
import { getFilterSidebarContent } from '~/utils/analytics'
import { downloadCSVdata } from '~/utils/csv'

const getFns = {
  time: getExploreTimeline,
  dim: getExploreLeaderboard,
}

const downloadCSV = (formData: ExploreFormData, rawData: ExploreDataObj<any>, exploreFormulasConfig: ExploreFormulasConfig) => {
  let csv = ''
  const metrics = formData.metrics.map((d) => Object.entries(exploreFormulasConfig).find(([, { formula }]) => formula.name === d)?.[0]).filter(NonNullable)
  if (formData.type.dimension === 'dim') {
    const header = [formData.dimension, ...formData.metrics].join(',')
    const rows = Object.values(rawData.mappings).map((mapping) => {
      return [mapping.label, ...metrics.map((k) => exploreFormulasConfig[k].formula.compute(rawData, 0)[mapping.value])].join(',')
    })

    csv = [header, ...rows].join('\n')
  } else {
    const header = ['epoch', formData.dimension, ...formData.metrics].join(',')
    const rows = rawData.epoch?.flatMap((epoch, index) => {
      return Object.values(rawData.mappings).map((mapping) => {
        return [epoch, mapping.label, ...metrics.map((k) => exploreFormulasConfig[k].formula.compute(rawData, index)[mapping.value])].join(',')
      })
    })

    csv = [header, ...rows].join('\n')
  }

  downloadCSVdata(csv, 'explore-view')
}

export const useMetrics = <T extends ExploreDataObj<any>>({
  metricNames,
  data,
  comparisonData,
  currencySymbol,
  onMetricChange,
  exploreFormulasConfig,
  currentDimension,
}: {
  metricNames: string[]
  data: T
  comparisonData?: T
  currencySymbol: CurrencySymbol
  onMetricChange: (metric: string) => void
  exploreFormulasConfig: ExploreFormulasConfig
  currentDimension: Dimension
}) => {
  const [current, setCurrent] = useState({ index: 0, name: metricNames?.[0] ?? '' })
  useEffect(() => {
    const index = metricNames.findIndex((name) => name === current.name)
    if (index !== -1) {
      setCurrent({ index, name: current.name })
    } else {
      setCurrent({ index: 0, name: metricNames?.[0] ?? '' })
    }
  }, [metricNames])

  return useMemo(() => {
    const metrics = metricNames.map<TimelineMetricProps>((metricName, index) => {
      const f = exploreFormulasConfig[metricName].formula
      const rawValue = f && f.isComputable(data) ? f.sum(data) : 0
      const notApplicableDimension = (exploreFormulasConfig[metricName]?.dimensionNotApplicable || []).includes(currentDimension)
      return {
        active: current.index === index,
        label: f.name,
        value: f ? f.displayable(rawValue, currencySymbol).toString() : undefined,
        notApplicable: !f || notApplicableDimension,
        hideNotApplicableTooltip: notApplicableDimension,
        onClick: () => {
          if (notApplicableDimension) {
            return
          }
          onMetricChange(metricName)
          f && setCurrent({ index, name: metricName })
        },
        tooltipText: notApplicableDimension ? 'Switch to compatible breakdown dimension' : f.tooltip,
      }
    })

    return {
      metrics,
      currentMetric: metrics[current.index],
      currentMetricIndex: current.index,
    }
  }, [current.index, current.name, data, metricNames, comparisonData, currencySymbol, onMetricChange])
}

const dataTypes: { type: DataDistributionType; label: string }[] = [
  { type: 'number', label: '123' },
  { type: 'percentage', label: '%' },
]

type PureExploreChartWidgetProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    rawData?: ExploreDataObj<any>
    formData: ExploreFormData
    currencySymbol: CurrencySymbol
    exploreFormulasConfig: ExploreFormulasConfig
    onMetricChange: (metric: string) => void
  }
const _PureExploreChartWidget: FunctionComponent<PureExploreChartWidgetProps> = ({ rawData, formData, currencySymbol, exploreFormulasConfig, onMetricChange, ...props }) => {
  const [useDataDistributionType, setDataDistributionType] = useState<'number' | 'percentage'>('number')
  const metricNames = useMemo(() => formData.metrics.map((metric) => Object.entries(exploreFormulasConfig).find(([, { formula }]) => formula.name === metric)?.[0] ?? ''), [formData.metrics])

  const { metrics, currentMetricIndex, currentMetric } = useMetrics({
    metricNames,
    data: rawData ?? ({ mappings: {} as Record<string, MappedName> } as ExploreDataObj<any>),
    currencySymbol,
    onMetricChange,
    exploreFormulasConfig,
    currentDimension: formData.dimension as Dimension,
  })
  const currentDimensionTitle = getFilterSidebarContent().find((i) => i.dimension === formData.dimension)

  const metric = metricNames[currentMetricIndex]
  const { chart, legends } = metric
    ? getFns[formData.type.dimension]({ rawData: rawData as any, formula: exploreFormulasConfig[metric].formula, currencySymbol, type: useDataDistributionType, seriesType: formData.type.seriesType })
    : {
        chart: {
          data: undefined,
          chartType: 'BarChart',
          options: {
            ...AnalyticsDefaultChartOptions,
          },
        } as ReactGoogleChartProps,
        legends: [],
      }

  return (
    <AnalyticsChartWidget
      {...props}
      title={'View'}
      icon={'chart_bar'}
      chart={chart}
      legends={legends}
      metrics={metrics}
      info={
        <>
          <Tabs
            tabs={dataTypes.map((d) => ({
              label: d.label,
              active: useDataDistributionType === d.type,
            }))}
            onClick={({ label }: TabProp) => {
              setDataDistributionType(dataTypes.find((d) => d.label === label)?.type ?? 'number')
            }}
            fluid={false}
          />
          <Button variant={'tertiary'} iconName={'download'} onClick={() => (rawData ? downloadCSV(formData, rawData, exploreFormulasConfig) : undefined)} />
        </>
      }
      notApplicable={
        currentMetric?.notApplicable ? (
          <IllustrationMessage iconName={'block'} iconSize={'48px'} message={`${currentMetric.label} is not available by ${currentDimensionTitle?.title ?? ''}. Please try another dimension.`} />
        ) : undefined
      }
    />
  )
}
export const PureExploreChartWidget = styled(_PureExploreChartWidget)``
