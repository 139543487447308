import styled from '@emotion/styled'
import { CONTEXT_ELEMENT_OP_AS_STR, ConditionGroupElement, StackContext } from '@pubstack/common/src/stackContext'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Flyout } from '~/components/Flyout'
import { StackUsage } from '../../StackUsage'

type PureAdStackContextsDetailsFlyoutProps = {
  context: StackContext
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Title = styled.div`
  ${Fonts.H2};
  font-weight: 500;
`

const Subtitle = styled.div`
    ${Fonts.P2};
    color: ${Colors.SlateGrey};
`

const Condition = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

const Strong = styled.span`
  font-weight: 500;
`

const Op = styled.span`
  color: ${Colors.SlateGrey};
`

const generateCondition = (condition: ConditionGroupElement): ReactNode => (
  <>
    {'key' in condition && (
      <Condition>
        <Strong>{condition.key}</Strong>
        <Op>{CONTEXT_ELEMENT_OP_AS_STR[condition.op]}</Op>
        <Strong>{`[${condition.values.join(', ')}]`}</Strong>
      </Condition>
    )}
  </>
)

const _PureAdStackContextsDetailsFlyout: FunctionComponent<PureAdStackContextsDetailsFlyoutProps> = ({ context }) => (
  <Flyout.Content>
    <Flyout.Title>{context.name}</Flyout.Title>
    <Flyout.Body>
      <Wrapper>
        <Block>
          <Title>Identification</Title>
          <div>
            <Subtitle>Name</Subtitle>
            <p>{context.name}</p>
          </div>
        </Block>
        <Block>
          <Title>Conditions</Title>
          {context.conditions.elements.map(generateCondition).reduce((acc, condition, index) => [acc, <Op key={index}>AND</Op>, condition])}
        </Block>
        <Block>
          <StackUsage usedInElement={'Context'} sitesStacks={context.stacksUse} />
        </Block>
      </Wrapper>
    </Flyout.Body>
  </Flyout.Content>
)

export const PureAdStackContextsDetailsFlyout = styled(_PureAdStackContextsDetailsFlyout)``
