import styled from '@emotion/styled'
import { UserSessionMetricsByTime } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { Timezone } from '@pubstack/common/src/timezone'
import { DateTime } from 'luxon'
import { FunctionComponent, useMemo } from 'react'
import { WidgetProps } from '~/components/Widget'
import { AnalyticsChartWidget } from '~/modules/analytics/AnalyticsChartWidget'
import { AnalyticsDefaultChartOptions } from '~/modules/analytics/AnalyticsCharts'
import { MetricConfiguration, TimelineConfiguration, useMetrics, useTimelineChart } from '~/modules/analytics/analyticsTimeline.hooks'
import { impressionPerPage, impressionPerSession, impressionRevenue, pageViewPerSession, sessionCount, sessionRpm } from '~/modules/analytics/formulas'
import { WithClassName } from '~/types/utils'
import { ANALYTICS_CHART_COLOR_CONFIG, getComparisonDataLegendLabel } from '~/utils/analytics'
import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import { displayWithCurrency } from '~/utils/string'

const COMPARISON_CHART_OPTIONS = {
  type: 'line',
  lineWidth: 2,
  lineDashStyle: [7, 5],
  enableInteractivity: false,
  tooltip: 'none',
  color: ANALYTICS_CHART_COLOR_CONFIG['comparisonLine'].color,
}

const useConfig = (rawComparisonData: UserSessionMetricsByTime) => {
  return useMemo((): (MetricConfiguration<UserSessionMetricsByTime> & TimelineConfiguration<UserSessionMetricsByTime>)[] => {
    const baseComparisonConfig: Omit<MetricConfiguration<UserSessionMetricsByTime>['dataConfig'][number], 'getFormula'> = {
      name: 'comparisonLine',
      label: getComparisonDataLegendLabel(rawComparisonData.epoch),
      getTooltipLabel: ({ comparisonData, index, dateFormat }) => (comparisonData ? DateTime.fromISO(comparisonData.epoch[index]).toFormat(dateFormat) : ''),
      iconName: 'data_line_dashed',
    }
    const getSeries = (color: string, comparisonData: UserSessionMetricsByTime | undefined) => ({
      series: { 0: { type: 'bars', enableInteractivity: true, tooltip: true, color }, 1: comparisonData ? COMPARISON_CHART_OPTIONS : {} },
    })
    return [
      {
        getChartOptions: ({ currencySymbol, comparisonData }) => ({
          ...getSeries('#5CCEE8', comparisonData),
          vAxis: {
            ...AnalyticsDefaultChartOptions.vAxis,
            format: displayWithCurrency('#.##', currencySymbol),
          },
        }),
        metric: {
          name: 'impressionRevenue',
        },
        legendConfig: ['impressionRevenue', 'comparisonLine'],
        tooltipConfig: [['impressionRevenue'], ['comparisonLine']],
        dataConfig: [
          {
            name: 'impressionRevenue',
            label: impressionRevenue.name,
            iconColor: '#5CCEE8',
            isComputable: ({ data }) => impressionRevenue.isComputable(data),
            getFormula: ({ data }) => (impressionRevenue.isComputable(data) ? impressionRevenue : undefined),
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ comparisonData }) => impressionRevenue.isComputable(comparisonData ?? { epoch: [] }),
            getFormula: ({ comparisonData }) => (impressionRevenue.isComputable(comparisonData ?? { epoch: [] }) ? impressionRevenue : undefined),
          },
        ],
      },
      {
        getChartOptions: ({ comparisonData }) => ({
          ...getSeries('#256CA0', comparisonData),
        }),
        metric: {
          name: 'uniqueSession',
          tooltipText: (
            <>
              Total number of distinct session. <br />
              Pubstack records a session every single time someone loads a page on your website.
              <br />A session ends at midnight or after 30 minutes of inactivity. <br />
              If a same visitor comes back one or several hour later, a new session is counted.
            </>
          ),
        },
        legendConfig: ['uniqueSession', 'comparisonLine'],
        tooltipConfig: [['uniqueSession'], ['comparisonLine']],
        dataConfig: [
          {
            name: 'uniqueSession',
            label: sessionCount.name,
            iconColor: '#256CA0',
            isComputable: ({ data }) => sessionCount.isComputable(data),
            getFormula: ({ data }) => (sessionCount.isComputable(data) ? sessionCount : undefined),
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ data }) => sessionCount.isComputable(data),
            getFormula: ({ comparisonData }) => (sessionCount.isComputable(comparisonData ?? { epoch: [] }) ? sessionCount : undefined),
          },
        ],
      },
      {
        getChartOptions: ({ currencySymbol, comparisonData }) => ({
          ...getSeries('#F5AF27', comparisonData),
          vAxis: {
            ...AnalyticsDefaultChartOptions.vAxis,
            format: displayWithCurrency('#.##', currencySymbol),
          },
        }),
        metric: {
          name: 'rpmSession',
          tooltipText: ANALYTICS_TOOLTIPS.SESSIONS_RPM,
        },
        legendConfig: ['rpmSession', 'comparisonLine'],
        tooltipConfig: [['rpmSession'], ['comparisonLine']],
        dataConfig: [
          {
            name: 'rpmSession',
            label: sessionRpm.name,
            iconColor: '#F5AF27',
            isComputable: ({ data }) => sessionRpm.isComputable(data),
            getFormula: ({ data }) => (sessionRpm.isComputable(data) ? sessionRpm : undefined),
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ data }) => sessionRpm.isComputable(data),
            getFormula: ({ comparisonData }) => (sessionRpm.isComputable(comparisonData ?? { epoch: [] }) ? sessionRpm : undefined),
          },
        ],
      },
      {
        getChartOptions: ({ comparisonData }) => ({
          ...getSeries('#BD8CFB', comparisonData),
        }),
        metric: {
          name: 'impressionPerSession',
          tooltipText: ANALYTICS_TOOLTIPS.SESSIONS_IMPRESSIONS,
        },
        legendConfig: ['impressionPerSession', 'comparisonLine'],
        tooltipConfig: [['impressionPerSession'], ['comparisonLine']],
        dataConfig: [
          {
            name: 'impressionPerSession',
            label: impressionPerSession.name,
            iconColor: '#BD8CFB',
            isComputable: ({ data }) => impressionPerSession.isComputable(data),
            getFormula: ({ data }) => (impressionPerSession.isComputable(data) ? impressionPerSession : undefined),
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ data }) => impressionPerSession.isComputable(data),
            getFormula: ({ comparisonData }) => (impressionPerSession.isComputable(comparisonData ?? { epoch: [] }) ? impressionPerSession : undefined),
          },
        ],
      },
      {
        getChartOptions: ({ comparisonData }) => ({
          ...getSeries('#7C95ED', comparisonData),
        }),
        metric: {
          name: 'pageView',
          tooltipText: ANALYTICS_TOOLTIPS.SESSIONS_PAGEVIEW,
        },
        legendConfig: ['pageView', 'comparisonLine'],
        tooltipConfig: [['pageView'], ['comparisonLine']],
        dataConfig: [
          {
            name: 'pageView',
            label: pageViewPerSession.name,
            iconColor: '#7C95ED',
            isComputable: ({ data }) => pageViewPerSession.isComputable(data),
            getFormula: ({ data }) => (pageViewPerSession.isComputable(data) ? pageViewPerSession : undefined),
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ data }) => pageViewPerSession.isComputable(data),
            getFormula: ({ comparisonData }) => (pageViewPerSession.isComputable(comparisonData ?? { epoch: [] }) ? pageViewPerSession : undefined),
          },
        ],
      },
      {
        getChartOptions: ({ comparisonData }) => ({
          ...getSeries('#55D3B5', comparisonData),
        }),
        metric: {
          name: 'impressionPerPage',
          tooltipText: ANALYTICS_TOOLTIPS.PAGE_IMPRESSIONS,
        },
        legendConfig: ['impressionPerPage', 'comparisonLine'],
        tooltipConfig: [['impressionPerPage'], ['comparisonLine']],
        dataConfig: [
          {
            name: 'impressionPerPage',
            label: impressionPerPage.name,
            iconColor: '#55D3B5',
            isComputable: ({ data }) => impressionPerPage.isComputable(data),
            getFormula: ({ data }) => (impressionPerPage.isComputable(data) ? impressionPerPage : undefined),
          },
          {
            ...baseComparisonConfig,
            isComputable: ({ data }) => impressionPerPage.isComputable(data),
            getFormula: ({ comparisonData }) => (impressionPerPage.isComputable(comparisonData ?? { epoch: [] }) ? impressionPerPage : undefined),
          },
        ],
      },
    ]
  }, [rawComparisonData])
}

type PureUserSessionTimelineProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon'> & {
    data: UserSessionMetricsByTime
    comparisonData: UserSessionMetricsByTime
    currentEpoch: DateTime
    currencySymbol: CurrencySymbol
    onMetricChange: (metric: string) => void
    timezone: Timezone
  }
const _PureUserSessionTimeline: FunctionComponent<PureUserSessionTimelineProps> = ({ data, comparisonData, currencySymbol, currentEpoch, onMetricChange, timezone, ...props }) => {
  const config = useConfig(comparisonData)
  const { metrics, currentConfig, currentMetric, metricMessage } = useMetrics({ config, data, comparisonData, currencySymbol, onMetricChange })
  const { chart, legends } = useTimelineChart({ currentConfig, data: currentMetric.notApplicable ? { epoch: [] as string[] } : data, comparisonData, currencySymbol, currentEpoch, timezone })

  return <AnalyticsChartWidget {...props} icon={'chart_bar'} title={'Session metrics'} metrics={metrics} chart={chart} legends={legends} metricMessage={metricMessage} />
}
export const PureUserSessionTimeline = styled(_PureUserSessionTimeline)``
