import { cx } from '@emotion/css'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { BidderCatalogAlias } from '@pubstack/common/src/bidderCatalog'
import { FunctionComponent, useState } from 'react'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { PageContent as _PageContent } from '~/components/PageContent'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import { PureCatalogItemHeader } from '~/modules/adstack/inventory/catalog/PureCatalogItemHeader'
import { WithClassName } from '~/types/utils'
import { PureAdStackIntegrationAliasForm } from './PureAdStackIntegrationAliasForm'
import { PureAdStackIntegrationHelpFlyout } from './PureAdStackIntegrationHelpFlyout'

const ANIMATION_IN_DURATION_MS = 200
const ANIMATION_OUT_DURATION_MS = 200

const formIn = keyframes`
  0% {
    opacity: 0;
    height: 0;
  }
  75% {
    height: 105px;
    opacity: 1;
  }
`

const formOut = keyframes`
  0% {
    opacity: 1;
    height: 105px;
  }
  
  25% {
    opacity: 0;
    padding: 20px;
    margin-top: 20px;
  }
  
  100% {
    padding: 0;
    margin-top: 0;
    opacity: 0;
    height: 0px;
  }
`

const IntegrationAliasForm = styled(PureAdStackIntegrationAliasForm)<{ isShown: boolean }>`
  display: ${({ isShown }) => (isShown ? 'block' : 'none')};
  margin-top: 20px;
`

const PageContent = styled(_PageContent)`
  &.form-open > ${IntegrationAliasForm} {
    animation: ${ANIMATION_IN_DURATION_MS}ms ease-out ${formIn} normal forwards;
  }

  &.form-close > ${IntegrationAliasForm} {
    animation: ${ANIMATION_OUT_DURATION_MS}ms ease-out ${formOut} normal forwards;
  }
`

type PureAdStackIntegrationPageProps = WithClassName & {
  breadcrumbs: React.ReactNode
  integrationName: string
  integrationAliases?: BidderCatalogAlias[]
  isLoading: boolean
  isIntegrationUsed?: boolean
  onCreateAlias: (value: string) => void
  baseCDNUrl: string
  disableAliasing: boolean
  onBackClick: () => void
}

const _PureAdStackIntegrationPage: FunctionComponent<PureAdStackIntegrationPageProps> = ({
  className,
  breadcrumbs,
  integrationName,
  isIntegrationUsed,
  isLoading,
  onCreateAlias,
  baseCDNUrl,
  disableAliasing,
  onBackClick,
}) => {
  const flyout = useGlobalFlyout()
  const [isShownIntegrationAliasForm, setIsShownIntegrationAliasForm] = useState<boolean>(false)
  const [isAliasFormClassToggled, setIsAliasFormClassToggled] = useState<boolean>(false)

  const showForm = () => {
    setIsShownIntegrationAliasForm(true)
    setIsAliasFormClassToggled(true)
  }

  const hideForm = () => {
    setIsAliasFormClassToggled(false)
    setTimeout(() => {
      setIsShownIntegrationAliasForm(false)
    }, ANIMATION_OUT_DURATION_MS)
  }
  return (
    <PageContent
      className={cx([className, isAliasFormClassToggled ? 'form-open' : 'form-close'])}
      breadcrumbs={breadcrumbs}
      headerActions={
        <>
          <Button variant={'tertiary'} onClick={onBackClick}>
            Back
          </Button>
          <Button variant={'tertiary'} onClick={() => flyout.open(PureAdStackIntegrationHelpFlyout, undefined, { color: Colors.Ming })} iconName={'help'}>
            Help
          </Button>
          {!disableAliasing && (
            <Button variant={'secondary'} onClick={showForm} iconName={'add_circle'}>
              New alias
            </Button>
          )}
        </>
      }
    >
      <PureCatalogItemHeader catalogItemCode={integrationName} catalogItemType={'prebidBidder'} isLoading={isLoading} isActive={!!isIntegrationUsed} baseCDNUrl={baseCDNUrl} />
      <IntegrationAliasForm
        isShown={isShownIntegrationAliasForm}
        onClose={hideForm}
        onSubmitAlias={(value) => {
          onCreateAlias(value)
          hideForm()
        }}
        isLoading={isLoading}
      />
    </PageContent>
  )
}

export const PureAdStackIntegrationPage = styled(_PureAdStackIntegrationPage)``
