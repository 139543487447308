import styled from '@emotion/styled'
import { AdminScope } from '@pubstack/common/src/scope'
import { FunctionComponent, PropsWithChildren } from 'react'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { PageContent } from '~/components/PageContent'
import { TabProp, Tabs } from '~/components/Tabs'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import { WithClassName } from '~/types/utils'
import { PureAdstackContextKeysHelpFlyout } from './PureAdstackContextHelpFlyout'

const ScopeDetailHeader = styled.div`
  position: sticky;
  top: 0;
  margin-bottom: 20px;
`

type PureAdStackContextTabsProps = WithClassName & {
  currentScope?: AdminScope
  onTabClick: (tab: TabProp) => unknown
  tabs: TabProp[]
  breadcrumbs?: React.ReactNode
}
const _PureAdStackContextTabs: FunctionComponent<PropsWithChildren<PureAdStackContextTabsProps>> = ({ className, breadcrumbs, onTabClick, tabs, children }) => {
  const flyout = useGlobalFlyout()

  return (
    <PageContent
      className={className}
      breadcrumbs={breadcrumbs}
      headerActions={
        <Button variant={'tertiary'} iconName={'help'} onClick={() => flyout.open(PureAdstackContextKeysHelpFlyout, undefined, { color: Colors.Violet })}>
          Help
        </Button>
      }
    >
      <ScopeDetailHeader>
        <Tabs tabs={tabs} onClick={onTabClick} />
      </ScopeDetailHeader>
      {children}
    </PageContent>
  )
}

export const PureAdStackContextTabs = styled(_PureAdStackContextTabs)``
