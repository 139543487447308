import styled from '@emotion/styled'
import { MOBILE_INTERSTITIAL, OUT_OF_PAGE, SKIN_SIZE } from '@pubstack/common/src/adFormat'
import { AdUnit, ContainerMaxSize, getAdUnitMediatypes } from '@pubstack/common/src/adunit'
import { AdUnitDevice } from '@pubstack/common/src/adunitDevice'
import { FunctionComponent, useEffect } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { ContentCard } from '~/components/ContentCard'
import { Link } from '~/components/Link'
import { LoadingModal } from '~/components/LoadingModal'
import { Selector } from '~/components/Selector'
import { Skeleton } from '~/components/Skeleton'
import { StepperIndicator } from '~/components/StepperIndicator'
import { useToast } from '~/components/Toast/useToasts'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import { WithClassName } from '~/types/utils'
import { useCustomStep } from '~/utils/useCustomStep'
import { AdFormatDisplay, useAdFormat } from './AdFormatDisplay'
import { PureAdStackAdUnitEditConfirmationModal } from './PureAdStackAdUnitEditConfirmationModal'
import { PureAdStackAdUnitFirstStep } from './PureAdStackAdUnitFirstStep'
import { PureAdStackAdUnitSecondStep } from './PureAdStackAdUnitSecondStep'
import { SummaryCard } from './components/SummaryCard'

export type AdUnitForm = AdUnit & {
  bannerEnabled: boolean
}

/**
 * Returns an object listing every key-value which is different in b from a
 */
const getAdUnitDiff = (a: AdUnitForm, b: AdUnitForm): Partial<AdUnit> => {
  return Object.fromEntries(Object.entries(b).filter(([key, value]) => JSON.stringify(a[key as keyof AdUnitForm]) !== JSON.stringify(value)))
}

const AdStackEditPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const PageWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: center;
`

const TitleBar = styled.div`
  display: flex;
  padding-bottom: 40px;
  width: 100%;
  justify-content: space-between;
  h1 {
    ${Fonts.H1}
  }
  ${Link} {
    margin-bottom: 12px;
  }
`

const AdStackEditContentCard = styled(ContentCard)`
  width: 1000px;
`

const CardContent = styled.div`
  padding: 0 0 20px 0;
`

const CardActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: end;
`

const PreviousButton = styled(Button)`
  margin-right: auto;
`

const CardTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const CardTitle = styled.h2`
  ${Fonts.colors.Jet}
  ${Fonts.H1}
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  margin: 0;
  flex: 1;
`

export const ErrorMessage = styled.div`
  ${Fonts.P2}
  ${Fonts.colors.Alert}
  display: inline-flex;
  align-items: center;
  gap: 4px;
`
export const DeviceChoice = styled.div`
  display: inline-flex;
  gap: 10px;
  flex-wrap: wrap;

  & ${Selector} {
    width: 182px;
  }

  & ${ErrorMessage} {
    flex-basis: 100%;
  }
`
const BottomBar = styled.div`
  border-top: 1px solid ${Colors.Platinum};
  margin-top: 20px;
`

export const isSizeFitsContainer = (size: string, containerMaxSize: ContainerMaxSize | undefined): { both: boolean; width: boolean; height: boolean } => {
  const [width, height] = size.split('x').map((n) => Number.parseInt(n))
  const sizeFits = { width: (containerMaxSize?.width || Infinity) >= width, height: (containerMaxSize?.height || Infinity) >= height }
  return { ...sizeFits, both: sizeFits.width && sizeFits.height }
}

export const getPredefinedSizes = (adFormat: AdFormatDisplay, devices: AdUnitDevice[]): string[] => {
  if (!adFormat.sizes.length || !devices.length) {
    return []
  }
  const predefinedSizes = adFormat.sizes.filter((sizesByDevice) => devices.some((device) => sizesByDevice.devices.includes(device))).map((sizeByDevice) => sizeByDevice.size)
  return predefinedSizes
}

type PureAdStackAdUnitEditPageProps = WithClassName & {
  adUnit: AdUnit
  isLoading: boolean
  isEditing: boolean
  breadcrumbs: React.ReactNode
  onAdUnitsEditBackClick: () => void
  onCreateAdUnit: (AdUnit: AdUnit) => Promise<void>
  onUpdateAdUnit: (AdUnit: AdUnit) => Promise<void>
  validateUniqueName: (name?: string) => Promise<boolean>
  isSaving: boolean
}

const coalesceContainerMaxSize = (containerMaxSize: Partial<ContainerMaxSize> | undefined): ContainerMaxSize | undefined => {
  if (!containerMaxSize?.width && !containerMaxSize?.height) return undefined

  return {
    width: !containerMaxSize?.width ? undefined : containerMaxSize?.width,
    height: !containerMaxSize?.height ? undefined : containerMaxSize?.height,
  }
}

const _PureAdStackAdUnitEditPage: FunctionComponent<PureAdStackAdUnitEditPageProps> = ({
  adUnit,
  isLoading,
  isEditing,
  className,
  breadcrumbs,
  onAdUnitsEditBackClick,
  onCreateAdUnit,
  onUpdateAdUnit,
  validateUniqueName,
  isSaving,
}) => {
  const modal = useGlobalModal()
  const formSteps = 2
  const [currentStep, stepHelpers] = useCustomStep(formSteps)
  const toast = useToast()

  const defaultValues: AdUnitForm = {
    ...adUnit,
    containerMaxSize: {
      // input expects a string, but the form control uses the end number type. undefined throws a react uncontrolled input error
      width: adUnit.containerMaxSize?.width || ('' as unknown as number),
      height: adUnit.containerMaxSize?.height || ('' as unknown as number),
    },
    bannerEnabled: true,
  }
  const {
    setValue,
    getValues,
    reset,
    trigger,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control,
  } = useForm<AdUnitForm>({ defaultValues, mode: 'onChange' })

  const { sizes, customSizes, fluid, containerMaxSize, adFormat: storedAdFormat, devices, bannerEnabled } = useWatch({ control })
  const adFormat = useAdFormat(storedAdFormat?.id)

  const onContinue = async () => {
    const valid = await trigger(['adFormat', 'adServerAdUnitName', 'divId', 'name', 'devices'])
    if (valid && adFormat) {
      // We force some fields of the form's second step if it's an ad unit creation
      if (!isEditing && adFormat?.id !== adUnit.adFormat?.id) {
        setValue('sizes', adFormat.mediatypes?.includes('Banner') ? getPredefinedSizes(adFormat as AdFormatDisplay, devices as AdUnitDevice[]) : [])
        // Header bidding cannot be disabled if we are editing and the HB is enabled on the original adunit
        setValue('headerBiddingEnabled', (isEditing && adUnit.headerBiddingEnabled) || (adFormat.id !== OUT_OF_PAGE.id && adFormat.id !== MOBILE_INTERSTITIAL.id))
      }

      stepHelpers.goToNextStep()
    }
  }

  const checkMediatypes = (adUnit: AdUnitForm): boolean => {
    if (adUnit.adFormat && adUnit.headerBiddingEnabled && !adUnit.canOutstream && !adUnit.bannerEnabled) {
      toast.alert('At least one mediatype must be selected')
      return false
    }
    return true
  }

  const onSubmit: SubmitHandler<AdUnitForm> = async (adUnit) => {
    if (!checkMediatypes(adUnit)) return
    modal.open(LoadingModal, {}, { showCloseButton: false, shouldCloseOnOverlayClick: false, shouldCloseOnEsc: false })
    const skinEnabled = !!sizes?.includes(SKIN_SIZE)
    const coalescedContainerMaxSize = coalesceContainerMaxSize(containerMaxSize)
    const coalescedAnchor = adUnit.anchor?.mode ? adUnit.anchor : undefined
    // As product wants to keep banner params to the end (in case the user reactivates it), we need to empty banner values only at the form submission
    isEditing
      ? await onUpdateAdUnit({ ...adUnit, containerMaxSize: coalescedContainerMaxSize, skinEnabled, anchor: coalescedAnchor })
      : await onCreateAdUnit({
          ...adUnit,
          containerMaxSize: coalescedContainerMaxSize,
          skinEnabled,
          anchor: coalescedAnchor,
          ...(!adUnit.bannerEnabled ? { fluid: false, sizes: [], customSizes: [], skinEnabled: false } : []),
        })
    modal.close()
  }

  useEffect(() => {
    reset({ ...adUnit, bannerEnabled: isEditing ? getAdUnitMediatypes(adUnit).includes('Banner') : true })
  }, [adUnit])

  useEffect(() => {
    trigger(['fluid', 'sizes', 'customSizes'])
  }, [fluid, sizes, customSizes, bannerEnabled])

  return (
    <AdStackEditPageWrapper className={className}>
      <TitleBar>
        {breadcrumbs}
        <Button variant={'tertiary'} onClick={onAdUnitsEditBackClick}>
          Back
        </Button>
      </TitleBar>
      <PageWrapper>
        <AdStackEditContentCard color={Colors.Turquoise}>
          <CardTitleWrapper>
            <CardTitle>{isLoading ? <Skeleton bigger width={'120px'} /> : isEditing ? `Edit ${adUnit.name}` : 'Ad unit'}</CardTitle>
            <StepperIndicator steps={formSteps} color={Colors.Turquoise} currentStep={currentStep} />
          </CardTitleWrapper>
          <BottomBar />
          <form id={'newAdUnitForm'} onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              {currentStep === 1 && <PureAdStackAdUnitFirstStep control={control} errors={errors} setValue={setValue} validateUniqueName={validateUniqueName} isEditing={isEditing} />}
              {currentStep === 2 && <PureAdStackAdUnitSecondStep control={control} errors={errors} setValue={setValue} adUnit={adUnit} isLoading={isLoading} isEditing={isEditing} />}
            </CardContent>
            <CardActions>
              {stepHelpers.canGoToPrevStep && (
                <PreviousButton variant={'tertiary'} onClick={stepHelpers.goToPrevStep}>
                  Previous
                </PreviousButton>
              )}
              <Button variant={'tertiary'} onClick={onAdUnitsEditBackClick}>
                Cancel
              </Button>
              {stepHelpers.canGoToNextStep ? (
                <Button variant={'primary'} onClick={onContinue} disabled={isLoading}>
                  Continue
                </Button>
              ) : isEditing ? (
                <Button
                  disabled={!isDirty || !isValid || isSaving}
                  variant={'primary'}
                  onClick={() => {
                    if (!checkMediatypes(getValues())) return
                    setValue('skinEnabled', sizes?.includes(SKIN_SIZE))
                    if (!bannerEnabled) {
                      setValue('fluid', false)
                      setValue('sizes', [])
                      setValue('customSizes', [])
                      setValue('skinEnabled', false)
                    }
                    modal.open(PureAdStackAdUnitEditConfirmationModal, {
                      onValidate: async () => {
                        await modal.close()
                        handleSubmit(onSubmit)()
                      },
                      updateDiff: getAdUnitDiff({ ...adUnit, bannerEnabled: getAdUnitMediatypes(adUnit).includes('Banner') }, getValues()),
                    })
                  }}
                >
                  Update
                </Button>
              ) : (
                <Button variant={'primary'} disabled={isSaving || isLoading} onClick={handleSubmit(onSubmit)}>
                  Validate
                </Button>
              )}
            </CardActions>
          </form>
        </AdStackEditContentCard>
        <div>
          <SummaryCard adUnit={getValues()} />
        </div>
      </PageWrapper>
    </AdStackEditPageWrapper>
  )
}

export const PureAdStackAdUnitEditPage = styled(_PureAdStackAdUnitEditPage)``
