import { Site } from './tag'

/**
 * Common store for identity module data
 */
export const ID_MODULES = [
  {
    id: '90a3cf3e-bec7-46e5-95ef-dcf8b449084a',
    code: 'teadsId',
    display: 'Teads ID',
  },
  {
    id: '853f2d81-b29f-433e-b262-69d8db87c445',
    code: 'id5Id',
    display: 'ID5',
  },
  {
    id: 'd3546392-3795-4d4a-b144-ca0c6f48d888',
    code: 'sharedId',
    display: 'SharedId',
  },
  {
    id: '28b604c2-67cf-4d90-91ca-6057d9d9bfb4',
    code: 'quantcastId',
    display: 'QuantcastID',
  },
  {
    id: '5f10bd4b-3f2f-4af0-87e9-73a59e97f0ad',
    code: 'identityLink',
    display: 'LiveRamp',
  },
  {
    id: '7c542aac-f10f-42fa-8820-c825ff277e6f',
    code: 'criteo',
    display: 'Criteo ID',
  },
  {
    id: 'd99a4ffc-f2fc-4cc5-b5db-cabed442b4a3',
    code: 'utiq',
    display: 'Utiq',
  },
  {
    id: '2d73e8be-fa01-484f-b508-35e0dc79274e',
    code: 'utiqMtp',
    display: 'UtiqMtp',
  },
  {
    id: 'fa654529-96ee-4ef6-88d7-8bf345f6aaef',
    code: 'zeotapIdPlus',
    display: 'Zeotap ID+',
  },
  {
    id: '11960053-4e52-487d-b3cd-2d00cf82ab4c',
    code: 'pairId',
    display: 'Google PAIR ID',
  },
  {
    id: '797f46bb-a107-4335-b852-a5b7c425c535',
    code: 'lotamePanoramaId',
    display: 'Lotame Panorama ID',
  },
  {
    id: '2c03965b-1aa0-4aba-9d65-7a1f8902ed89',
    code: 'uid2',
    display: 'Unified ID 2.0',
  },
] as const

export type IdModuleNames = (typeof ID_MODULES)[number]['code']

export type UserId_ID5 = {
  name: 'id5Id'
  params: {
    partner: number
  }
  storage?: { [key in string]: string | number }
}

export type UserId_Teads = {
  name: 'teadsId'
  params: {
    pubId: number
  }
  storage?: { [key in string]: string | number }
}

export type UserId_Shared = {
  name: 'sharedId'
  params?: { [key in string]: string }
  storage?: { [key in string]: string | number }
}

export type UserId_Quant = {
  name: 'quantcastId'
  params?: { [key in string]: string }
  storage?: { [key in string]: string | number }
}

export type UserId_LiveRamp = {
  name: 'identityLink'
  params: {
    pid: string
  }
  storage?: { [key in string]: string | number }
}

export type UserId_Criteo = {
  name: 'criteo'
  params?: { [key in string]: string }
  storage?: { [key in string]: string | number }
}

export type UserId_Utiq = {
  name: 'utiq'
  params: {
    maxDelayTime: number
  }
  bidders: string[]
  storage?: {
    type: string
    name: string
    expires: number
  }
}

export type UserId_UtiqMtp = {
  name: 'utiqMtp'
  bidders: string[]
  params?: { [key in string]: string }
  storage?: { [key in string]: string | number }
}

export type UserId_ZeotapIdPlus = {
  name: 'zeotapIdPlus'
  params?: { [key in string]: string }
  storage?: { [key in string]: string | number }
}

export type UserId_PairID = {
  name: 'pairId'
  params?: {
    liveramp?: {
      storageKey?: string
    }
  }
  storage?: { [key in string]: string | number }
}

export type UserId_LotamePanoramaIdPlus = {
  name: 'lotamePanoramaId'
  params: {
    clientId: string
  }
  storage?: { [key in string]: string | number }
}

export type UserId_UnifiedId2 = {
  name: 'uid2'
  params: {
    uid2Token?: { [key in string]: string | number }
    uid2ServerCookie?: string
    uid2ApiBase?: string
    storage?: string
  }
  value?: { [key in string]: string | number }
  storage?: { [key in string]: string | number }
}

export type UserSyncUserId<ModuleName extends IdModuleNames> = ModuleName extends 'id5Id'
  ? UserId_ID5
  : ModuleName extends 'teadsId'
    ? UserId_Teads
    : ModuleName extends 'sharedId'
      ? UserId_Shared
      : ModuleName extends 'quantcastId'
        ? UserId_Quant
        : ModuleName extends 'identityLink'
          ? UserId_LiveRamp
          : ModuleName extends 'criteo'
            ? UserId_Criteo
            : ModuleName extends 'utiq'
              ? UserId_Utiq
              : ModuleName extends 'zeotapIdPlus'
                ? UserId_ZeotapIdPlus
                : ModuleName extends 'pairId'
                  ? UserId_PairID
                  : ModuleName extends 'lotamePanoramaId'
                    ? UserId_LotamePanoramaIdPlus
                    : ModuleName extends 'uid2'
                      ? UserId_UnifiedId2
                      : ModuleName extends 'utiqMtp'
                        ? UserId_UtiqMtp
                        : never
/** Type for the properties useful in a JSON edition of the module */
export type UserSyncUserIdJSON<ModuleName extends IdModuleNames> = Omit<UserSyncUserId<ModuleName>, 'name'>

export type UserSync<ModuleName extends IdModuleNames> = {
  userIds?: UserSyncUserId<ModuleName>[]
} & {
  [key: string]: unknown
}

export const ID_MODULES_DEFAULT_PARAMETERS: { [ModuleName in IdModuleNames]: Omit<UserSyncUserId<ModuleName>, 'name'> } = {
  utiq: {
    params: {
      maxDelayTime: 0,
    },
    bidders: [],
    storage: {
      type: '',
      name: '',
      expires: 0,
    },
  },
  utiqMtp: {
    bidders: [],
  },
  teadsId: {
    params: {
      pubId: 0,
    },
  },
  id5Id: {
    params: {
      partner: 0,
    },
  },
  sharedId: {},
  quantcastId: {},
  identityLink: {
    params: {
      pid: '',
    },
    storage: {
      type: 'html5',
      name: 'idl_env',
      expires: 15,
      refreshInSeconds: 1800,
    },
  },
  criteo: {},
  zeotapIdPlus: {},
  pairId: { params: { liveramp: { storageKey: '' } } },
  lotamePanoramaId: { params: { clientId: '' } },
  uid2: {
    params: {},
  },
}

export const ID_MODULES_PBJS_NAMES: { [ModuleName in IdModuleNames]: string } = {
  teadsId: 'teadsIdSystem',
  id5Id: 'id5IdSystem',
  sharedId: 'sharedIdSystem',
  quantcastId: 'quantcastIdSystem',
  identityLink: 'identityLinkIdSystem',
  criteo: 'criteoIdSystem',
  utiq: 'utiqSystem',
  utiqMtp: 'utiqMtpIdSystem',
  zeotapIdPlus: 'zeotapIdPlusIdSystem',
  pairId: 'pairIdSystem',
  lotamePanoramaId: 'lotamePanoramaIdSystem',
  uid2: 'uid2IdSystem',
}

/**
 * UserId data for a single site
 */
export type SiteUserIdData<ModuleName extends IdModuleNames> = {
  siteName: Site['name']
  siteId: Site['id']
  userId: UserSyncUserId<ModuleName> | undefined
}

/**
 * Module data for a whole scope
 */
export type ScopeModuleIdentification<ModuleName extends IdModuleNames> = { code: ModuleName; userIdData: SiteUserIdData<ModuleName>[] }
