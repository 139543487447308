import styled from '@emotion/styled'
import { FunctionComponent, PropsWithChildren } from 'react'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { PageContent } from '~/components/PageContent'
import { TabProp, Tabs } from '~/components/Tabs'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import { WithClassName } from '~/types/utils'
import { PureAdstackRefreshHelpFlyout } from './PureAdStackRefreshHelpFlyout'

const ScopeDetailHeader = styled.div`
  background-color: ${Colors.Ghost};
  position: sticky;
  top: 0;
  margin-bottom: 20px;
`

export const RefreshWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

type PureAdStackRefreshPageProps = WithClassName & {
  onTabClick: (tab: TabProp) => unknown
  tabs: TabProp[]
  breadcrumbs?: React.ReactNode
}
const _PureAdStackRefreshPage: FunctionComponent<PropsWithChildren<PureAdStackRefreshPageProps>> = ({ className, breadcrumbs, onTabClick, tabs, children }) => {
  const flyout = useGlobalFlyout()

  return (
    <PageContent
      className={className}
      breadcrumbs={breadcrumbs}
      headerActions={
        <Button variant={'tertiary'} iconName={'help'} onClick={() => flyout.open(PureAdstackRefreshHelpFlyout, undefined, { color: Colors.Petrol })}>
          Help
        </Button>
      }
    >
      <ScopeDetailHeader>
        <Tabs tabs={tabs} onClick={onTabClick} />
      </ScopeDetailHeader>
      {children}
    </PageContent>
  )
}

export const PureAdStackRefreshPage = styled(_PureAdStackRefreshPage)``
