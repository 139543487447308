import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { RefreshGlobalSetting } from '@pubstack/common/src/adstack/refresh-global-settings'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { WithClassName } from '~/types/utils'

const GlobalSettingViewWrapper = styled.div`
  border: 1px solid ${Colors.Platinum};
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 20px 12px;
  flex: 1 0 auto;
`

const TargetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-left: 13px;
`

const Subtitle = styled.span`
  margin-right: 10px;
  ${Fonts.colors.SlateGrey};
`

const SettingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`

const Chip = styled.div`
  border: 1px solid ${Colors.Greu};
  border-radius: 37px;
  padding: 2px 8px;
`

type GlobalSettingViewProps = WithClassName & {
  refreshGlobalSetting: RefreshGlobalSetting
  selectableBidders: BidderAdapter[]
}

const _GlobalSettingView: FunctionComponent<GlobalSettingViewProps> = ({ refreshGlobalSetting, selectableBidders }) => {
  const targetBidders: string[] =
    refreshGlobalSetting.bidders.length === 0 ? ['All bidders'] : refreshGlobalSetting.bidders.map((bidder) => selectableBidders.find((sB) => sB.code === bidder)?.displayName ?? bidder)
  const targetSizes: string[] = refreshGlobalSetting.sizes.length === 0 ? ['All sizes'] : refreshGlobalSetting.sizes.map((bidder) => bidder)
  const targets: string[] = [...targetBidders, ...targetSizes]
  return (
    <GlobalSettingViewWrapper>
      <TargetWrapper>
        <Subtitle>Target</Subtitle>
        {targets.map((target) => (
          <Chip key={target}>{target}</Chip>
        ))}
      </TargetWrapper>
      <SettingsWrapper>
        <Subtitle>Settings</Subtitle>
        <div>{refreshGlobalSetting.timer ? `Refreshed after ${refreshGlobalSetting.timer}s in view` : `No refresh`}</div>
      </SettingsWrapper>
    </GlobalSettingViewWrapper>
  )
}

export const GlobalSettingView = styled(_GlobalSettingView)``
