import styled from '@emotion/styled'
import { AD_UNIT_MEDIATYPES } from '@pubstack/common/src/adFormat'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Icon } from '~/components/Icon'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import { AdFormatDisplay } from '~/modules/adstack/inventory/adunits/AdFormatDisplay'
import { WithClassName } from '~/types/utils'
import { AdFormatCardHelpFlyout } from './AdFormatCardHelpFlyout'

const Card = styled.div<{ isSelected: boolean }>`
  background-color: ${Colors.White};
  border: 1px solid ${({ isSelected }) => (isSelected ? Colors.King : Colors.Platinum)};

  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 226px;
  height: 70px;
  cursor: pointer;
  --format-icon-color: ${({ isSelected }) => (isSelected ? Colors.Turquoise : Colors.Sora)};

  :hover {
    border: 1px solid ${({ isSelected }) => (isSelected ? Colors.King : Colors.Silver)};
    --format-icon-color: ${Colors.Turquoise};
  }
`

const Content = styled.div`
  padding-top: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-grow: 2;
`

const Label = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`
const AdFormatName = styled.div`
  ${Fonts.P1};
  ${Fonts.colors.Jet};
  font-weight: 700;
  width: 140px;
`

const Mediatypes = styled.div`
  ${Fonts.P3};
`

export const AdFormatMediatype = styled.span<{ isPresent: boolean }>`
  color: ${({ isPresent }) => (isPresent ? Colors.Jet : Colors.Silver)};
`

const Help = styled.div`
  align-self: flex-start;
  padding: 8px 8px 0 0;
`

export type AdFormatCardProps = WithClassName & {
  adFormat: AdFormatDisplay
  isSelected: boolean
  onClick: React.MouseEventHandler<HTMLDivElement>
}

const _AdFormatCard: FunctionComponent<AdFormatCardProps> = ({ className, adFormat, isSelected, onClick }) => {
  const flyout = useGlobalFlyout()
  return (
    <Card className={className} isSelected={isSelected ?? false} onClick={onClick}>
      <Content>
        <Icon name={adFormat.iconName} height={'36px'} width={'36px'} fill={Colors.Rose} />
        <Label>
          <AdFormatName>{adFormat.name}</AdFormatName>
          <Mediatypes>
            {AD_UNIT_MEDIATYPES.map<ReactNode>((mediatype) => (
              <AdFormatMediatype key={mediatype} isPresent={adFormat.mediatypes.includes(mediatype) ?? false}>
                {mediatype}
              </AdFormatMediatype>
            )).reduce((prev, curr) => [prev, ' | ', curr])}
          </Mediatypes>
        </Label>
      </Content>
      <Help
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          event.nativeEvent.stopImmediatePropagation()
          flyout.open(AdFormatCardHelpFlyout, { adFormat }, { color: Colors.Turquoise })
        }}
      >
        <Icon name={'help'} width={'18px'} height={'18px'} fill={Colors.King} />
      </Help>
    </Card>
  )
}

export const AdFormatCard = styled(_AdFormatCard)``
