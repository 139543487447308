import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { PageContent } from '~/components/PageContent'
import { TabProp, Tabs } from '~/components/Tabs'
import { WithClassName } from '~/types/utils'

type PureAdStackCatalogTabsProps = WithClassName & {
  breadcrumbs: ReactNode
  onTabClick: (tab: TabProp) => unknown
  tabs: TabProp[]
  children?: ReactNode | ReactNode[]
}
const _PureAdStackCatalogTabs: FunctionComponent<PureAdStackCatalogTabsProps> = ({ breadcrumbs, onTabClick, tabs, children }) => {
  return (
    <PageContent breadcrumbs={breadcrumbs}>
      <Tabs tabs={tabs} onClick={onTabClick} />
      <div>{children}</div>
    </PageContent>
  )
}

export const PureAdStackCatalogTabs = styled(_PureAdStackCatalogTabs)``
