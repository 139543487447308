import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { Flyout } from '~/components/Flyout'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const QuestionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Title = styled.div`
  ${Fonts.H2};
  font-weight: 500;
`

const Strong = styled.span`
  font-weight: 500;
`

const CaseBlock = styled.div`
  background-color: ${Colors.Ghost};
  padding: 10px 6px;
  flex-grow: 1;
`

const ExampleBlock = styled.div`
  ${Fonts.P3};
  display: flex;
  flex-direction: column;
  gap: 6px;
  & > ${CaseBlock} {
    margin-bottom: 6px;
  }
`

const Example = styled.div`
  ${Fonts.H3};
`

const CasesBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 7px;
`

const Priorities = styled.table`
  margin-top: 12px;
  border-collapse: separate;
  border-spacing: 17px 0;
  margin-left: -17px;
  th {
    font-style: italic;
    font-weight: 400;
    color: ${Colors.SlateGrey};
    text-align: start;
  }
  tr td:first-of-type {
    text-align: center;
  }
`

const _PureSiteStackHelpFlyout: FunctionComponent = () => {
  return (
    <Flyout.Content>
      <Flyout.Title>Help</Flyout.Title>
      <Flyout.Body>
        <Wrapper>
          <QuestionsBlock>
            <Section>
              <Title>How to monetize a site?</Title>
              <div>
                <p>1. Create all elements listed in “Activation” tab.</p>
                <p>2. Make sure to have the tag on each page. Find it in “Connect to Pubstack”.</p>
                <p>3. Create a Default stack and deploy it. Your site is now monetized.</p>
              </div>
            </Section>
            <Section>
              <Title>How do I manage multiple stacks?</Title>
              <div>
                <p>
                  <Strong>One page can only use one stack</Strong> at the same time. However <Strong>one stack can be used on several pages.</Strong>
                </p>
                <p>The stack used on the page is decided by the context keys set for the page. Those context keys will match the stack context.</p>
              </div>
            </Section>
          </QuestionsBlock>
          <ExampleBlock>
            <Example>Example</Example>
            <CaseBlock>
              <Strong>For all cases, Pubstack is set like:</Strong>
              <p>Context A = Context key 1 AND Context key 2</p>
              <p>Context B = Context key B</p>
              <Priorities>
                <thead>
                  <th>Priorities</th>
                  <th>Stacks</th>
                </thead>
                <tr>
                  <td>1</td>
                  <td>Stack A with Context A</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Stack B with Context B</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Default stack with Default context</td>
                </tr>
              </Priorities>
            </CaseBlock>
            <CasesBlock>
              <CaseBlock>
                <Strong>Case 1: on the page</Strong>
                <p>Context key 1 + Context key 2</p>
              </CaseBlock>
              <CaseBlock>
                <Strong>Case 2: on the page</Strong>
                <p>Context key B</p>
              </CaseBlock>
              <CaseBlock>
                <Strong>Case 3: on the page</Strong>
                <p>Context key 1</p>
              </CaseBlock>
            </CasesBlock>
            <div>
              <p>Case 1: the stack that matches the page is Stack A.</p>
              <p>Case 2: the stack that matches the page is Stack B.</p>
              <p>Case 3: no stack matches only “Context key 1” so the Default stack is used.</p>
              <p>Other situation: if the page has Context key 1, Context key 2 and Context key B, Stack A will be used because it’s the first priority.</p>
            </div>
          </ExampleBlock>
        </Wrapper>
      </Flyout.Body>
    </Flyout.Content>
  )
}

export const PureSiteStackHelpFlyout = styled(_PureSiteStackHelpFlyout)``
