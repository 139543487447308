import styled from '@emotion/styled'
import { BidderAdapter } from '@pubstack/common/src/adstack/bidder-adapter'
import { ContextKey } from '@pubstack/common/src/contextKey'
import { SiteConfig } from '@pubstack/common/src/siteConfig'
import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors } from '~/assets/style/colors'
import { AdStackIntegrationLogo } from '~/components/AdStackIntegrationLogo'
import { CollapsiblePanel } from '~/components/CollapsiblePanel'
import { Icon } from '~/components/Icon'
import { Link } from '~/components/Link'
import { Skeleton } from '~/components/Skeleton'
import { Status } from '~/components/Status'
import { Toggle } from '~/components/Toggle'
import { PureAdStackGamSiteConfigForm } from '~/modules/adstack/inventory/catalog/adServers/PureAdStackGamSiteConfigForm'
import { PureAdStackPrebidSiteConfigForm } from '~/modules/adstack/inventory/catalog/headerBidders/PureAdStackPrebidSiteConfigForm'
import { WithClassName } from '~/types/utils'

const WrapperPanel = styled(CollapsiblePanel)`
  margin-bottom: 20px;
  & ${AdStackIntegrationLogo} {
    height: 30px;
  }
`
const Logo = styled.div`
  width: 500px;
`
const TamStatus = styled.div`
  display: flex;
  gap: 8px;
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const WarningMessage = styled.div`
  display: inline-flex;
  padding: 10px;
  gap: 4px;
  background-color: ${Colors.Ghost};
  border: 1px solid ${Colors.Cobalt};
  border-radius: 4px;
`

type PureGamAndWrappersProps = WithClassName & {
  isLoading: boolean
  siteConfig: SiteConfig
  baseCDNUrl: string
  hasNetworkId: boolean
  contextKeys: ContextKey[]
  bidderAdapters: BidderAdapter[]
  onCreateSiteConfig: (siteConfig: SiteConfig, type: string) => Promise<void>
}

const _PureGamAndWrappers: FunctionComponent<PureGamAndWrappersProps> = ({ isLoading, baseCDNUrl, siteConfig, hasNetworkId, contextKeys, bidderAdapters, onCreateSiteConfig }) => {
  const navigate = useNavigate()
  const gamState = (siteConfig?.adUnitPathConfig?.length ?? 0) > 0
  return !isLoading ? (
    <>
      <WrapperPanel
        title={
          <TitleWrapper>
            <Logo>
              <AdStackIntegrationLogo baseCDNUrl={baseCDNUrl} name={'gam'} />
            </Logo>
            {gamState ? <Status state={'active'}>Configured</Status> : <Status state={'error'}>Not Configured</Status>}
          </TitleWrapper>
        }
        isCollapsible={true}
      >
        {!hasNetworkId && (
          <WarningMessage>
            <Icon name={'warning'} />
            <span>
              To use GAM properly, you need to define the Network ID in <Link onClick={() => navigate('/adstack/integrations/adserver/gam')} label={'Integrations'} />.
            </span>{' '}
          </WarningMessage>
        )}
        <PureAdStackGamSiteConfigForm
          contextKeys={contextKeys}
          siteId={siteConfig.siteId}
          siteConfig={siteConfig}
          scopeId={siteConfig.scopeId}
          createSiteConfig={(data) => {
            onCreateSiteConfig(data, 'GAM')
          }}
        />
      </WrapperPanel>
      <WrapperPanel
        title={
          <TitleWrapper>
            <Logo>
              <AdStackIntegrationLogo baseCDNUrl={baseCDNUrl} name={'prebid'} />
            </Logo>
            <Status state={'active'}>Active</Status> {/* Prebid is still 'active' */}
          </TitleWrapper>
        }
        isCollapsible={true}
      >
        <PureAdStackPrebidSiteConfigForm
          siteId={siteConfig.siteId}
          siteConfig={siteConfig}
          scopeId={siteConfig.scopeId}
          onCreateSiteConfig={(data) => {
            onCreateSiteConfig(data, 'Prebid')
          }}
          bidderAdapters={bidderAdapters}
        />
      </WrapperPanel>
      <WrapperPanel
        title={
          <TitleWrapper>
            <Logo>
              <AdStackIntegrationLogo baseCDNUrl={baseCDNUrl} name={'tam'} />
            </Logo>
            <TamStatus>
              <Toggle
                value={siteConfig.isTamEnabled}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()
                  onCreateSiteConfig({ ...siteConfig, isTamEnabled: !siteConfig.isTamEnabled }, 'TAM')
                }}
              />
              <span>{siteConfig.isTamEnabled ? 'Enabled' : 'Disabled'}</span>
            </TamStatus>
          </TitleWrapper>
        }
        isCollapsible={false}
      />
    </>
  ) : (
    <>
      {[0, 1, 2].map((e) => (
        <WrapperPanel key={e} forceState={'closed'} hasBorderWhenOpened isCollapsible={true} title={<Skeleton width={`15ch`} />} />
      ))}
    </>
  )
}

export const PureGamAndWrappers = styled(_PureGamAndWrappers)``
