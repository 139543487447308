import styled from '@emotion/styled'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import Button from '~/components/Button'
import { PageContent } from '~/components/PageContent'
import { TabProp, Tabs } from '~/components/Tabs'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import { WithClassName } from '~/types/utils'
import { PureSiteStackHelpFlyout } from './PureSiteStackHelpFlyout'

const ContentWrapper = styled.div`
  margin-top: 20px;
`

type PureSitePageProps = WithClassName & {
  breadcrumbs?: ReactNode
  tabs: TabProp[]
  onTabChange: (tab: TabProp) => void
  children?: ReactNode | ReactNode[]
}
const _PureSitePage: FunctionComponent<PureSitePageProps> = ({ className, breadcrumbs, tabs, onTabChange, children }) => {
  const flyout = useGlobalFlyout()
  return (
    <PageContent
      className={className}
      breadcrumbs={breadcrumbs}
      headerActions={
        <Button variant={'tertiary'} iconName={'help'} onClick={() => flyout.open(PureSiteStackHelpFlyout, undefined, { color: Colors.Lilac, width: `612px` })}>
          Help
        </Button>
      }
    >
      <Tabs tabs={tabs} onClick={onTabChange} />
      <ContentWrapper>{children}</ContentWrapper>
    </PageContent>
  )
}
export const PureSitePage = styled(_PureSitePage)``
