import { buildFormula } from './operation'

export const firstCallImpressionRevenue = buildFormula({
  name: 'Revenue',
  keys: ['firstCallImpressionCpmSum'],
  raw: ({ firstCallImpressionCpmSum }) => firstCallImpressionCpmSum / 1000,
  type: 'monetary',
  isStackable: true,
})

export const refreshImpressionRevenue = buildFormula({
  name: 'Revenue',
  keys: ['refreshImpressionCpmSum'],
  raw: ({ refreshImpressionCpmSum }) => refreshImpressionCpmSum / 1000,
  type: 'monetary',
  isStackable: true,
})

export const refreshRevenueUplift = buildFormula({
  name: 'Refresh uplift',
  keys: ['firstCallImpressionCpmSum', 'refreshImpressionCpmSum'],
  raw: ({ refreshImpressionCpmSum, firstCallImpressionCpmSum }) => Math.round(((refreshImpressionCpmSum ?? 0) / 1000 / (firstCallImpressionCpmSum / 1000)) * 100),
  type: 'uplift',
  isStackable: false,
})

export const firstCallAndRefreshImpressionRevenue = buildFormula({
  name: 'Revenue',
  keys: ['refreshImpressionCpmSum', 'firstCallImpressionCpmSum'],
  raw: ({ refreshImpressionCpmSum, firstCallImpressionCpmSum }) => (refreshImpressionCpmSum + firstCallImpressionCpmSum) / 1000,
  type: 'monetary',
  isStackable: true,
})

export const refreshAuctionCount = buildFormula({
  name: 'Refresh Auctions',
  keys: ['refreshAuctionCount'],
  raw: ({ refreshAuctionCount }) => refreshAuctionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const firstCallAuctionCount = buildFormula({
  name: 'Refresh Auctions',
  keys: ['firstCallAuctionCount'],
  raw: ({ firstCallAuctionCount }) => firstCallAuctionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const firstCallAndRefreshAuctionCount = buildFormula({
  name: 'Refresh Auctions',
  keys: ['firstCallAuctionCount', 'refreshAuctionCount'],
  raw: ({ firstCallAuctionCount, refreshAuctionCount }) => firstCallAuctionCount + refreshAuctionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const refreshAuctionFillRate = buildFormula({
  name: 'Open Bidding Fill rate',
  keys: ['refreshImpressionCount', 'refreshAuctionCount'],
  raw: ({ refreshImpressionCount, refreshAuctionCount }) => (refreshAuctionCount ? refreshImpressionCount / refreshAuctionCount : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const firstCallAuctionFillRate = buildFormula({
  name: 'Open Bidding Fill rate',
  keys: ['firstCallImpressionCount', 'firstCallAuctionCount'],
  raw: ({ firstCallImpressionCount, firstCallAuctionCount }) => (firstCallAuctionCount ? firstCallImpressionCount / firstCallAuctionCount : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const firstCallAndRefreshAuctionFillRate = buildFormula({
  name: 'Fill rate',
  keys: ['firstCallImpressionCount', 'refreshImpressionCount', 'firstCallAuctionCount', 'refreshAuctionCount'],
  optionalKeys: ['firstCallImpressionCount', 'refreshImpressionCount'],
  raw: ({ firstCallImpressionCount, refreshImpressionCount, firstCallAuctionCount, refreshAuctionCount }) =>
    (firstCallAuctionCount || refreshAuctionCount ? ((firstCallImpressionCount ?? 0) + (refreshImpressionCount ?? 0)) / ((firstCallAuctionCount ?? 0) + (refreshAuctionCount ?? 0)) : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const firstCallViewabilityMRC = buildFormula({
  name: 'Viewability',
  keys: ['firstCallMeasurableImpressionCount', 'firstCallMrcViewableImpressionCount'],
  raw: ({ firstCallMeasurableImpressionCount, firstCallMrcViewableImpressionCount }) =>
    (firstCallMeasurableImpressionCount ? firstCallMrcViewableImpressionCount / firstCallMeasurableImpressionCount : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const refreshViewabilityMRC = buildFormula({
  name: 'Viewability',
  keys: ['refreshMeasurableImpressionCount', 'refreshMrcViewableImpressionCount'],
  raw: ({ refreshMeasurableImpressionCount, refreshMrcViewableImpressionCount }) => (refreshMeasurableImpressionCount ? refreshMrcViewableImpressionCount / refreshMeasurableImpressionCount : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const firstCallAndRefreshViewabilityMRC = buildFormula({
  name: 'Viewability',
  keys: ['refreshMeasurableImpressionCount', 'refreshMrcViewableImpressionCount', 'firstCallMeasurableImpressionCount', 'firstCallMrcViewableImpressionCount'],
  raw: ({ refreshMeasurableImpressionCount, refreshMrcViewableImpressionCount, firstCallMrcViewableImpressionCount, firstCallMeasurableImpressionCount }) =>
    (refreshMeasurableImpressionCount || firstCallMeasurableImpressionCount
      ? (refreshMrcViewableImpressionCount + firstCallMrcViewableImpressionCount) / (refreshMeasurableImpressionCount + firstCallMeasurableImpressionCount)
      : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const firstCallAverageViewedTime = buildFormula({
  name: 'Avg. viewable time',
  keys: ['firstCallMeasuredImpressionViewedTimeSum', 'firstCallMeasuredImpressionCount'],
  raw: ({ firstCallMeasuredImpressionViewedTimeSum, firstCallMeasuredImpressionCount }) =>
    firstCallMeasuredImpressionCount ? firstCallMeasuredImpressionViewedTimeSum / firstCallMeasuredImpressionCount : 0,
  type: 'seconds',
  isStackable: false,
})

export const refreshAverageViewedTime = buildFormula({
  name: 'Avg. viewable time',
  keys: ['refreshMeasuredImpressionViewedTimeSum', 'refreshMeasuredImpressionCount'],
  raw: ({ refreshMeasuredImpressionViewedTimeSum, refreshMeasuredImpressionCount }) => (refreshMeasuredImpressionCount ? refreshMeasuredImpressionViewedTimeSum / refreshMeasuredImpressionCount : 0),
  type: 'seconds',
  isStackable: true,
})

export const firstCallAndRefreshAverageViewedTime = buildFormula({
  name: 'Avg. viewable time',
  keys: ['refreshMeasuredImpressionViewedTimeSum', 'refreshMeasuredImpressionCount', 'firstCallMeasuredImpressionViewedTimeSum', 'firstCallMeasuredImpressionCount'],
  raw: ({ refreshMeasuredImpressionViewedTimeSum, refreshMeasuredImpressionCount, firstCallMeasuredImpressionViewedTimeSum, firstCallMeasuredImpressionCount }) =>
    firstCallMeasuredImpressionCount || refreshMeasuredImpressionCount
      ? (firstCallMeasuredImpressionViewedTimeSum + refreshMeasuredImpressionViewedTimeSum) / (firstCallMeasuredImpressionCount + refreshMeasuredImpressionCount)
      : 0,
  type: 'seconds',
  isStackable: false,
})

export const firstCallImpressionCount = buildFormula({
  name: 'Open Bidding impressions',
  keys: ['firstCallImpressionCount'],
  raw: ({ firstCallImpressionCount }) => firstCallImpressionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const refreshImpressionCount = buildFormula({
  name: 'Open Bidding impressions',
  keys: ['refreshImpressionCount'],
  raw: ({ refreshImpressionCount }) => refreshImpressionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const firstCallAndRefreshImpressionCount = buildFormula({
  name: 'Impressions',
  keys: ['refreshImpressionCount', 'firstCallImpressionCount'],
  raw: ({ refreshImpressionCount, firstCallImpressionCount }) => refreshImpressionCount + firstCallImpressionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const firstCallAndRefreshECPM = buildFormula({
  name: 'eCPM',
  keys: ['firstCallImpressionCpmSum', 'refreshImpressionCpmSum', 'refreshImpressionCount', 'firstCallImpressionCount'],
  raw: ({ firstCallImpressionCpmSum, refreshImpressionCpmSum, refreshImpressionCount, firstCallImpressionCount }) =>
    refreshImpressionCount || firstCallImpressionCount ? (firstCallImpressionCpmSum + refreshImpressionCpmSum) / (refreshImpressionCount + firstCallImpressionCount) : 0,
  type: 'preciseMonetary',
  isStackable: false,
})

export const refreshECPM = buildFormula({
  name: 'eCPM',
  keys: ['refreshImpressionCpmSum', 'refreshImpressionCount'],
  raw: ({ refreshImpressionCpmSum, refreshImpressionCount }) => (refreshImpressionCount ? refreshImpressionCpmSum / refreshImpressionCount : 0),
  type: 'preciseMonetary',
  isStackable: false,
})

export const firstCallECPM = buildFormula({
  name: 'eCPM',
  keys: ['firstCallImpressionCpmSum', 'firstCallImpressionCount'],
  raw: ({ firstCallImpressionCpmSum, firstCallImpressionCount }) => (firstCallImpressionCount ? firstCallImpressionCpmSum / firstCallImpressionCount : 0),
  type: 'preciseMonetary',
  isStackable: false,
})

export const impressionCount = buildFormula({
  name: 'Impressions',
  keys: ['impressionCount'],
  raw: ({ impressionCount }) => impressionCount ?? NaN,
  type: 'shortNumber',
  isStackable: true,
})

export const impressionRevenue = buildFormula({
  name: 'Revenue',
  keys: ['impressionCpmSum'],
  raw: ({ impressionCpmSum: value }) => value / 1000,
  type: 'monetary',
  isStackable: true,
})

export const auctionFillRate = buildFormula({
  name: 'Fill rate',
  keys: ['impressionCount', 'auctionCount'],
  raw: ({ impressionCount, auctionCount }) => (auctionCount ? impressionCount / auctionCount : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const eCPM = buildFormula({
  name: 'eCPM',
  keys: ['impressionCpmSum', 'impressionCount'],
  raw: ({ impressionCpmSum, impressionCount }) => impressionCpmSum / (impressionCount || 1),
  type: 'monetary',
  isStackable: false,
})

export const viewabilityMRC = buildFormula({
  name: 'Viewability',
  keys: ['measurableImpressionCount', 'mrcViewableImpressionCount'],
  raw: ({ measurableImpressionCount, mrcViewableImpressionCount }) => (mrcViewableImpressionCount / (measurableImpressionCount || 1)) * 100,
  type: 'percentage',
  isStackable: false,
})

export const averageViewedTime = buildFormula({
  name: 'Avg viewable time',
  keys: ['measuredImpressionViewedTimeSum', 'measuredImpressionCount'],
  raw: ({ measuredImpressionViewedTimeSum, measuredImpressionCount }) => (measuredImpressionCount ? measuredImpressionViewedTimeSum / measuredImpressionCount : 0),
  type: 'seconds',
  isStackable: false,
})
