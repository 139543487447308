import { AllMetrics, AllMetricsObj } from '@pubstack/common/src/analytics/query'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DisplayableTypes, Formula, arraySum, asPercentage } from './operation'

const keysObj = (o: Partial<AllMetricsObj>) => Object.keys(o) as (keyof AllMetricsObj)[]

export interface FormulaObj<T extends Partial<AllMetricsObj>> {
  name: string
  raw(values: { [key in keyof T]: { [key: string]: number } }): { [key: string]: number }
  compute: ComputeFormulaFunctionObj<T>
  sum: ComputeSumFormulaFunctionObj<T>
  displayable: (number: number, currency?: CurrencySymbol) => string
  isComputable: (value: Partial<T>) => value is Required<T>
  percentage: ComputeFormulaFunctionObj<T>
  displayablePercentage: (number: number) => string
  tooltip?: string
  keys: (keyof AllMetrics)[]
  optionalKeys?: (keyof AllMetrics)[]
  type: DisplayableTypes
  isStackable: boolean
}

export interface ComputeFormulaFunctionObj<T extends Partial<AllMetricsObj>, X = { [key: string]: number }> {
  (values: T, index: number): X
}

export interface ComputeSumFormulaFunctionObj<T extends Partial<AllMetricsObj>> {
  (values: T, index?: number): number
}
export const arraySumObj = (myArray: { [key: string]: number }[]): { [key: string]: number } => {
  return myArray.reduce((acc3: { [key: string]: number }, current) => {
    return Object.entries(current).reduce(
      (acc2, [key, value]) => {
        // if key is already in map1, add the values, otherwise, create new pair
        return { ...acc2, [key]: (acc2[key] || 0) + value }
      },
      { ...acc3 }
    )
  }, {})
}
export const computeObj = <T extends Partial<AllMetricsObj>, V>(formula: FormulaObj<T>, values: T, index: number): { [key: string]: number } => {
  const emptyObject = {} as { [key in keyof T]: { [key: string]: number } }
  const flattenedObject = keysObj(values).reduce((objectAgg, value) => ({ ...objectAgg, [value]: values[value]?.[index] ?? 0 }), emptyObject)
  return formula.raw(flattenedObject)
}

export const sumObj = <T extends Partial<AllMetricsObj>>(values: T, index?: number): { [key in keyof T]: { [key: string]: number } } => {
  const emptyObject = {} as { [key in keyof T]: { [key: string]: number } }
  return keysObj(values)
    .filter((value) => Array.isArray(values[value]))
    .reduce((objectAgg, value) => {
      const arr = values[value] ?? []
      objectAgg[value] = index === undefined ? arraySumObj(arr) : arraySumObj([arr[index]])
      return objectAgg
    }, emptyObject)
}

export const percentageObj = (value: { [key: string]: number }): { [key: string]: number } => {
  const sum = Object.values(value).reduce((a, b) => a + b, 0)
  return Object.entries(value).reduce((acc: { [key: string]: number }, [key, value]) => {
    // if key is already in map1, add the values, otherwise, create new pair
    return { ...acc, [key]: sum === 0 ? 0 : (value / sum) * 100 }
  }, {})
}

type ExtractKeys<T> = T extends Formula<Pick<AllMetrics, infer K>> ? Exclude<K, 'total'> : never

export const buildFormulaObj = <X extends Partial<AllMetrics>, T extends Pick<AllMetricsObj, ExtractKeys<Formula<X>>>>({
  name,
  formula,
  pivot,
  denominators,
}: {
  name?: string
  formula: Formula<X>
  pivot: keyof T
  denominators?: (keyof T)[]
}): FormulaObj<T> => {
  const formulaObj: FormulaObj<T> = {
    name: name ?? formula.name,
    raw: (values) =>
      Object.keys(values[pivot]).reduce((objectAgg, value) => {
        return {
          ...objectAgg,
          [value]: formula.raw(
            formula.keys.reduce(
              (acc, metric) => ({
                ...acc,
                [metric]: (values[metric as keyof T] ?? {})[value],
              }),
              {} as { [key in keyof X]: number }
            )
          ),
        }
      }, {}),
    compute: (values, index) => computeObj(formulaObj, values, index),
    sum: (values, index) => {
      const sum = sumObj(values, index)
      return formula.raw(
        formula.keys.reduce(
          (acc, metric) => {
            return {
              ...acc,
              [metric]: (denominators || []).includes(metric as keyof T)
                ? arraySum(Object.values(sum[metric as keyof T] ?? {})) / Object.values(sum[metric as keyof T]).length
                : arraySum(Object.values(sum[metric as keyof T] ?? {})),
            }
          },
          {} as { [key in keyof X]: number }
        )
      )
    },
    percentage: (values, index) => percentageObj(formulaObj.compute(values, index)),
    displayablePercentage: (number) => asPercentage(number),
    displayable: formula.displayable,
    isComputable: (value): value is Required<T> => formula.isComputable(Object.keys(value).reduce((acc, key) => ({ ...acc, [key]: 0 }), {})),
    tooltip: formula.tooltip,
    keys: formula.keys,
    optionalKeys: formula.optionalKeys,
    type: formula.type,
    isStackable: formula.isStackable,
  }
  return formulaObj
}
