import styled from '@emotion/styled'
import { Site } from '@pubstack/common/src/tag'
import { FunctionComponent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from '~/components/Button'
import { Input } from '~/components/Input'
import { Link } from '~/components/Link'
import { Tooltip } from '~/components/Tooltip'
import { useGlobalFlyout } from '~/components/layout/GlobalFlyout'
import { useGlobalModal } from '~/components/layout/GlobalModal'
import Table, { EmptyTable, handleTableSort, onColumnSort, TableColumns } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'
import { SUPPORT_MAIL } from '~/utils/constants'
import { SETTINGS_NAV_CONFIG } from '~/utils/settings'
import { PureSettingsSitesAddSiteFlyout } from './PureSettingsSitesAddSiteFlyout'
import { PureSettingsSitesArchiveSiteModal } from './PureSettingsSitesArchiveSiteModal'
import { PureSettingsSitesEditSiteFlyout } from './PureSettingsSitesEditSiteFlyout'

const SettingsSitesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const SettingsSitesHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

const ActionsTableCell = styled(TableCell)`
  width: 0px; /** necessary to get the last cell to shrink down to hug action buttons */
`

const SettingsSitesActions = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

type PureSettingsSitesPageProps = WithClassName & {
  sites: Site[]
  isLoading: boolean
  isError: boolean
  canEditSites: boolean
  canArchiveSites: boolean
  onAddSite: (sites: Site[]) => unknown
  onArchiveSite: (site: Site) => unknown
  onEditSite: (site: Site) => unknown
  onUnarchiveSite: (site: Site) => unknown
  onCopyToClipBoard: (label: string) => unknown
  onSearch: (search: string) => unknown
}
const _PureSettingsSitesPage: FunctionComponent<PureSettingsSitesPageProps> = ({
  className,
  sites,
  isLoading,
  isError,
  canEditSites,
  canArchiveSites,
  onEditSite,
  onArchiveSite,
  onUnarchiveSite,
  onAddSite,
  onCopyToClipBoard,
  onSearch,
}) => {
  const hasActions = canEditSites || canArchiveSites // Note: can lead to rendering of Action column if all sites are archived and user doesn't have the right to unarchive
  const modal = useGlobalModal()
  const sitesCols: TableColumns<Omit<Site, 'enabled' | 'adManagementEnabled'>> = [
    { name: 'Site', attributeSort: 'name', isSortable: true, order: 'ascending' },
    { name: 'Site ID', attributeSort: 'id', isSortable: true, order: 'none' },
    ...(hasActions ? ([{ name: 'Action', isSortable: false }] as TableColumns) : []),
  ]

  const [columns, setColumns] = useState<TableColumns<Site>>(sitesCols)
  const [lastSearch, setLastSearch] = useState('')

  const { control, watch } = useForm({
    defaultValues: {
      search: '',
    },
  })

  const search = watch('search')
  useEffect(() => {
    if (lastSearch !== search) {
      const timeout = setTimeout(() => {
        onSearch(search)
        setLastSearch(search)
      }, 400)

      return () => clearTimeout(timeout)
    }
  }, [search])

  const sortSites = (sites: Site[]) => handleTableSort(columns, sites)

  const enabledSites = sites.filter((s) => s.enabled)
  const disabledSites = sites.filter((s) => !s.enabled)

  const sortedSites = [...sortSites(enabledSites), ...sortSites(disabledSites)]

  const flyout = useGlobalFlyout()

  const validateSiteName = (site: string | boolean | Omit<Site, 'id'> | Omit<Site, 'id'>[] | undefined) => {
    if (typeof site === 'string') {
      return !sites.some((s) => s.name.toLocaleLowerCase().trim() === site?.toLocaleLowerCase().trim())
    }
    return false
  }

  return (
    <SettingsSitesWrapper className={className}>
      <SettingsSitesHeader>
        <Input control={control} name={'search'} label={'Search'} labelIsPlaceholder iconLeft={'search'} />
        {canEditSites && (
          <Button
            iconName={'add_site'}
            onClick={() =>
              flyout.open(
                PureSettingsSitesAddSiteFlyout,
                {
                  validateSite: validateSiteName,
                  onAdd: (sites: Site[]) => {
                    flyout.close()
                    onAddSite(sites)
                  },
                },
                { color: SETTINGS_NAV_CONFIG.sites.color }
              )
            }
          >
            Add site
          </Button>
        )}
      </SettingsSitesHeader>
      <Table isLoading={isLoading} columns={columns} onClickHeading={(column) => onColumnSort(columns, column, setColumns)}>
        {sortedSites.length ? (
          sortedSites.map((site, index) => {
            const isArchived = !site.enabled
            return (
              <TableRow
                onClick={() => {
                  canEditSites &&
                    !isArchived &&
                    flyout.open(
                      PureSettingsSitesEditSiteFlyout,
                      {
                        site,
                        onCopyToClipBoard,
                        onSaveChanges: (site: Site) => {
                          flyout.close()
                          onEditSite(site)
                        },
                      },
                      { color: SETTINGS_NAV_CONFIG.sites.color }
                    )
                }}
                archived={isArchived}
                key={index}
              >
                <TableCell>{site.name}</TableCell>
                <TableCell>{site.id}</TableCell>
                {hasActions && (
                  <ActionsTableCell>
                    <SettingsSitesActions>
                      {canEditSites && !isArchived && (
                        <Tooltip title={'Edit'}>
                          <Button
                            onClick={(event) => {
                              event.stopPropagation()
                              flyout.open(
                                PureSettingsSitesEditSiteFlyout,
                                {
                                  site,
                                  onCopyToClipBoard,
                                  onSaveChanges: (site: Site) => {
                                    flyout.close()
                                    onEditSite(site)
                                  },
                                },
                                { color: SETTINGS_NAV_CONFIG.sites.color }
                              )
                            }}
                            variant={'tertiary'}
                            iconName={'edit'}
                          />
                        </Tooltip>
                      )}
                      {canArchiveSites &&
                        (!isArchived ? (
                          <Tooltip title={'Archive'}>
                            <Button
                              onClick={(event) => {
                                event.stopPropagation()
                                modal.open(PureSettingsSitesArchiveSiteModal, { site, onArchive: onArchiveSite })
                              }}
                              variant={'tertiary'}
                              iconName={'archive'}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title={'Unarchive'}>
                            <Button
                              onClick={(event) => {
                                event.stopPropagation()
                                onUnarchiveSite({ ...site, enabled: true })
                              }}
                              variant={'tertiary'}
                              iconName={'unarchive'}
                            />
                          </Tooltip>
                        ))}
                    </SettingsSitesActions>
                  </ActionsTableCell>
                )}
              </TableRow>
            )
          })
        ) : (
          <TableRow>
            <TableCell colspan={columns.length}>
              <EmptyTable>
                {isError ? (
                  <div>
                    <h2>An error occurred when trying to display your sites</h2>
                    <p>
                      Please refresh the page or contact <Link label={SUPPORT_MAIL} href={`mailto:${SUPPORT_MAIL}`} /> if the issue persists.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h2>No sites</h2>
                    <p>You have no sites set up.</p>
                    {canEditSites && (
                      <Button
                        iconName={'add_site'}
                        onClick={() =>
                          flyout.open(
                            PureSettingsSitesAddSiteFlyout,
                            {
                              validateSite: validateSiteName,
                              onAdd: (sites: Site[]) => {
                                flyout.close()
                                onAddSite(sites)
                              },
                            },
                            { color: SETTINGS_NAV_CONFIG.sites.color }
                          )
                        }
                      >
                        Add site
                      </Button>
                    )}
                  </div>
                )}
              </EmptyTable>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </SettingsSitesWrapper>
  )
}

export const PureSettingsSitesPage = styled(_PureSettingsSitesPage)``
