import styled from '@emotion/styled'
import { Dimension } from '@pubstack/common/src/analytics/dimension'
import { FunctionComponent, useMemo } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import { ChipListInput } from '~/components/ChipListInput'
import { Icon } from '~/components/Icon'
import { RadioGroup } from '~/components/RadioGroup'
import { Select } from '~/components/Select'
import { SelectOptionProp } from '~/components/SelectableOptionsPopover'
import { useToast } from '~/components/Toast/useToasts'
import { Toggle } from '~/components/Toggle'
import { Tooltip } from '~/components/Tooltip'
import { Widget, WidgetProps } from '~/components/Widget'
import { ExploreFormData, ExploreFormulasConfig } from '~/modules/analytics/explore/explore'
import { WithClassName } from '~/types/utils'
import { getFilterSidebarContent } from '~/utils/analytics'

const FormWrapper = styled.form`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
  padding-top: 5px;
  ${Fonts.P2};
`

const FlexGrow = styled.div`
  flex: 1;
`

const Label = styled.span`
  width: 37px;
`

const Group = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
  flex: 1;
  ${Tooltip} {
    align-self: center;
  }
`

const InfoIcon = styled(Icon)`
  color: ${Colors.Hurricane};
`

const MetricOptionContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
`
const MetricOptionContentNotAvailable = styled.span`
  ${Fonts.P2};
  font-style: italic;
`

type PureExploreQueryFormProps = WithClassName &
  Omit<WidgetProps, 'title' | 'info' | 'icon' | 'isLoading' | 'error' | 'empty' | 'onRefreshClick' | 'hideHeader'> & {
    onSubmit: (data: ExploreFormData) => Promise<void>
    formData: ExploreFormData
    dimensions: Dimension[]
    exploreFormulasConfig: ExploreFormulasConfig
    currentMetric?: string
  }
const _PureExploreQueryForm: FunctionComponent<PureExploreQueryFormProps> = ({ onSubmit, formData, dimensions, exploreFormulasConfig, currentMetric, ...props }) => {
  const { control, handleSubmit } = useForm<ExploreFormData>({ defaultValues: { ...formData } })
  const toast = useToast()
  const dimensionsOptions = getFilterSidebarContent()
    .filter((f) => dimensions.includes(f.dimension))
    .map(({ dimension, title }) => ({
      label: title === 'Bidders' ? 'Prebid bidders' : title,
      value: dimension,
    }))

  const currentDimension = useWatch({ control, name: 'dimension' }) as Dimension
  const currentDimensionTitle = getFilterSidebarContent().find((i) => i.dimension === currentDimension)

  const metricsOptions = useMemo(() => {
    const o = Object.entries(exploreFormulasConfig).reduce(
      (acc, [, { formula, category }]) => ({ ...acc, [category]: [...(acc[category] ?? []), { label: formula.name, value: formula.name }] }),
      {} as { [k: string]: SelectOptionProp<string>[] }
    )
    return Object.entries(o).reduce((acc, [category, value]) => [...acc, { label: category, value }], [] as SelectOptionProp[])
  }, [exploreFormulasConfig, currentDimension])

  const MetricOptionContent: FunctionComponent<{ option: SelectOptionProp }> = ({ option }) => {
    const config = Object.entries(exploreFormulasConfig).find(([, { formula }]) => formula.name === option.label)?.[1]
    const isIncompatible = config?.dimensionNotApplicable?.includes(currentDimension)
    return (
      <MetricOptionContentWrapper>
        <span>{option.label}</span>
        {isIncompatible && (
          <>
            <MetricOptionContentNotAvailable>-</MetricOptionContentNotAvailable>
            <MetricOptionContentNotAvailable>not available with current dimension</MetricOptionContentNotAvailable>
          </>
        )}
      </MetricOptionContentWrapper>
    )
  }

  const onMetricChange = (values: string[]) => {
    const newValue = values.find((value) => !formData.metrics.includes(value))
    const config = Object.entries(exploreFormulasConfig).find(([, { formula }]) => formula.name === newValue)?.[1]
    if (config?.dimensionNotApplicable?.includes(currentDimension)) {
      const name = config.formula.name
      toast(
        <span>
          <b>{name}</b> is not compatible with <b>{currentDimensionTitle?.title ?? ''}</b>. Please select another dimension or metric.
        </span>
      )
    }
    handleSubmit(onSubmit)()
  }

  return (
    <Widget
      {...props}
      hideHeader
      isLoading={false}
      error={false}
      empty={false}
      onRefreshClick={function (): void {
        throw new Error('Function not implemented.')
      }}
    >
      <FormWrapper>
        <Group>
          <Label>Query</Label>
          <FlexGrow>
            <ChipListInput
              control={control}
              name={'metrics'}
              label={'Metrics'}
              selectableOptions={metricsOptions}
              onChange={onMetricChange}
              isSpaceAllowed
              maxChipCount={5}
              variant={'small'}
              showMultiLevelAsCategory
              OptionContentComponent={MetricOptionContent}
            />
          </FlexGrow>
        </Group>
        <Group>
          <FlexGrow>
            <Select control={control} onChange={() => handleSubmit(onSubmit)()} name={'dimension'} label={'Grouped by dimension'} options={dimensionsOptions} searchable variant={'small'} />
          </FlexGrow>
        </Group>
        <Group>
          <Label>View</Label>
          <FlexGrow>
            <RadioGroup
              control={control}
              onChange={() => handleSubmit(onSubmit)()}
              name={'type'}
              size={'small'}
              options={[
                {
                  label: 'Timeline',
                  value: { dimension: 'time', seriesType: 'bars' },
                  iconName: 'timeline',
                  disabled: !!currentMetric && exploreFormulasConfig[currentMetric] && !exploreFormulasConfig[currentMetric]?.formula.isStackable,
                },
                { label: 'Line', value: { dimension: 'time', seriesType: 'line' }, iconName: 'chart_line' },
                { label: 'Bars', value: { dimension: 'dim', seriesType: 'bars' }, iconName: 'bar' },
              ]}
            />
          </FlexGrow>
        </Group>
        <Group>
          <Controller
            control={control}
            name={'others'}
            render={({ field: { onChange, value } }) => (
              <>
                <Toggle
                  value={value}
                  onClick={() => {
                    onChange(!value)
                    handleSubmit(onSubmit)()
                  }}
                />
                <span>Show &quot;Others&quot;</span>
                <Tooltip title={'When disabled, only the top 10 in terms of Auctions is shown.'}>
                  <InfoIcon name={'info'} width={'16px'} />
                </Tooltip>
              </>
            )}
          />
        </Group>
      </FormWrapper>
    </Widget>
  )
}
export const PureExploreQueryForm = styled(_PureExploreQueryForm)``
