import { ANALYTICS_TOOLTIPS } from '~/utils/constants'
import { buildFormula } from './operation'

export const bidRate = buildFormula({
  name: 'Bid rate',
  keys: ['bidResponseCount', 'bidRequestCount'],
  raw: ({ bidResponseCount, bidRequestCount }) => (bidResponseCount / (bidRequestCount || 1)) * 100,
  type: 'percentage',
  tooltip: ANALYTICS_TOOLTIPS.BID_RATE,
  isStackable: false,
})

export const noBidRate = buildFormula({
  name: 'No bid rate',
  keys: ['bidResponseCount', 'bidTimeoutCount', 'bidRequestCount'],
  raw: ({ bidResponseCount, bidTimeoutCount, bidRequestCount }) => (1 - (bidResponseCount / (bidRequestCount || 1) + bidTimeoutCount / (bidRequestCount || 1) || 1)) * 100,
  type: 'percentage',
  isStackable: false,
})

export const noBid = buildFormula({
  name: 'No bid',
  keys: ['bidRequestCount', 'bidResponseCount', 'bidTimeoutCount'],
  raw: ({ bidRequestCount, bidResponseCount, bidTimeoutCount }) => bidRequestCount - (bidResponseCount + bidTimeoutCount),
  type: 'shortNumber',
  isStackable: true,
})

export const hbWinRate = buildFormula({
  name: 'Prebid win rate',
  keys: ['bidWinCount', 'bidResponseCount'],
  raw: ({ bidWinCount, bidResponseCount }) => (bidWinCount / (bidResponseCount || 1)) * 100,
  type: 'percentage',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.PREBID_WIN_RATE,
})

export const bidRequests = buildFormula({
  name: 'Bid requests',
  keys: ['bidRequestCount'],
  raw: ({ bidRequestCount }) => bidRequestCount,
  type: 'shortNumber',
  isStackable: true,
})

export const bidResponses = buildFormula({
  name: 'Bid responses',
  keys: ['bidResponseCount'],
  raw: ({ bidResponseCount }) => bidResponseCount,
  type: 'shortNumber',
  isStackable: true,
})

export const bidResponseCpmSum = buildFormula({
  name: 'Bid response CPM sum',
  keys: ['bidResponseCpmSum'],
  raw: ({ bidResponseCpmSum }) => bidResponseCpmSum / 1000,
  type: 'monetary',
  isStackable: true,
})

export const hbBidWinCount = buildFormula({
  name: 'Prebid win. bids',
  keys: ['bidWinCount'],
  raw: ({ bidWinCount }) => bidWinCount,
  type: 'shortNumber',
  isStackable: true,
})

export const bidTimeoutRate = buildFormula({
  name: 'Timeout rate',
  keys: ['bidRequestCount', 'bidTimeoutCount'],
  raw: ({ bidRequestCount, bidTimeoutCount }) => (bidTimeoutCount / (bidRequestCount || 1)) * 100,
  type: 'percentage',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.BID_TIMEOUT_RATE,
})

export const bidTimeouts = buildFormula({
  name: 'Bid timeout',
  keys: ['bidTimeoutCount'],
  raw: ({ bidTimeoutCount }) => bidTimeoutCount,
  type: 'shortNumber',
  isStackable: true,
})

export const bidTagIdCount = buildFormula({
  name: 'Sites',
  keys: ['bidTagIdCount'],
  optionalKeys: ['bidTagIdCount'],
  raw: ({ bidTagIdCount }) => bidTagIdCount ?? 0,
  type: 'identity',
  isStackable: true,
})

export const bidAdUnitCount = buildFormula({
  name: 'Ad units',
  keys: ['bidAdUnitCount'],
  optionalKeys: ['bidAdUnitCount'],
  raw: ({ bidAdUnitCount }) => bidAdUnitCount ?? 0,
  type: 'identity',
  isStackable: true,
})

export const bidBidderCount = buildFormula({
  name: 'Bidders',
  keys: ['bidBidderCount'],
  optionalKeys: ['bidBidderCount'],
  raw: ({ bidBidderCount }) => bidBidderCount ?? 0,
  type: 'identity',
  isStackable: true,
})

export const hbImpressionCount = buildFormula({
  name: 'Prebid impressions',
  keys: ['impressionCount'],
  raw: ({ impressionCount }) => impressionCount ?? NaN,
  type: 'shortNumber',
  isStackable: true,
})

export const adxImpressionCount = buildFormula({
  name: 'AdX impressions',
  keys: ['adxImpressionCount'],
  raw: ({ adxImpressionCount }) => adxImpressionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const openBiddingImpressionCount = buildFormula({
  name: 'Open Bidding impressions',
  keys: ['openBiddingImpressionCount'],
  raw: ({ openBiddingImpressionCount }) => openBiddingImpressionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const adxAndHbImpressionCount = buildFormula({
  name: 'Impressions',
  keys: ['adxImpressionCount', 'impressionCount'],
  raw: ({ adxImpressionCount, impressionCount }) => adxImpressionCount + impressionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const allImpressionCount = buildFormula({
  name: 'Impressions',
  keys: ['adxImpressionCount', 'impressionCount', 'openBiddingImpressionCount'],
  optionalKeys: ['adxImpressionCount', 'impressionCount', 'openBiddingImpressionCount'],
  raw: ({ adxImpressionCount, impressionCount, openBiddingImpressionCount }) => (adxImpressionCount ?? 0) + (impressionCount ?? 0) + (openBiddingImpressionCount ?? 0),
  type: 'shortNumber',
  isStackable: true,
  tooltip: ANALYTICS_TOOLTIPS.ALL_IMPRESSIONS,
})

export const hbImpressionRevenue = buildFormula({
  name: 'Prebid revenue',
  keys: ['impressionCpmSum'],
  raw: ({ impressionCpmSum }) => impressionCpmSum / 1000,
  type: 'monetary',
  isStackable: true,
})

export const adxImpressionRevenue = buildFormula({
  name: 'AdX revenue',
  keys: ['adxImpressionCpmSum'],
  raw: ({ adxImpressionCpmSum }) => adxImpressionCpmSum / 1000,
  type: 'monetary',
  isStackable: true,
})

export const openBiddingImpressionRevenue = buildFormula({
  name: 'Open Bidding revenue',
  keys: ['openBiddingImpressionCpmSum'],
  raw: ({ openBiddingImpressionCpmSum }) => openBiddingImpressionCpmSum / 1000,
  type: 'monetary',
  isStackable: true,
})

export const allImpressionRevenue = buildFormula({
  name: 'Revenue',
  keys: ['impressionCpmSum', 'adxImpressionCpmSum', 'openBiddingImpressionCpmSum'],
  optionalKeys: ['impressionCpmSum', 'adxImpressionCpmSum', 'openBiddingImpressionCpmSum'],
  raw: ({ impressionCpmSum, adxImpressionCpmSum, openBiddingImpressionCpmSum }) => ((impressionCpmSum ?? 0) + (adxImpressionCpmSum ?? 0) + (openBiddingImpressionCpmSum ?? 0)) / 1000,
  type: 'monetary',
  isStackable: true,
  tooltip: ANALYTICS_TOOLTIPS.REVENUE,
})

export const impressionRpm = buildFormula({
  name: 'Imp. RPM',
  keys: ['impressionCpmSum', 'bidRequestCount'],
  raw: ({ impressionCpmSum, bidRequestCount }) => (bidRequestCount ? impressionCpmSum / bidRequestCount : 0),
  type: 'preciseMonetary',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_IMP_RPM,
})

export const hbWinningCpmSum = buildFormula({
  name: 'Prebid win. bids',
  keys: ['bidWinCpmSum'],
  raw: ({ bidWinCpmSum }) => bidWinCpmSum / 1000,
  type: 'monetary',
  isStackable: true,
})

export const bidCpm = buildFormula({
  name: 'Bid CPM',
  keys: ['bidCpmSum', 'bidResponseCount'],
  raw: ({ bidCpmSum, bidResponseCount }) => bidCpmSum / (bidResponseCount || 1),
  type: 'monetary',
  isStackable: true,
  tooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_BID_CPM,
})

export const bidWinCpm = buildFormula({
  name: 'Win. CPM',
  keys: ['bidWinCount', 'bidWinCpmSum'],
  raw: ({ bidWinCount, bidWinCpmSum }) => bidWinCpmSum / (bidWinCount || 1),
  type: 'monetary',
  isStackable: true,
  tooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_WIN_CPM,
})

export const bidWinRpm = buildFormula({
  name: 'Win. RPM',
  keys: ['bidWinCpmSum', 'bidRequestCount'],
  raw: ({ bidWinCpmSum, bidRequestCount }) => (bidRequestCount ? bidWinCpmSum / bidRequestCount : 0),
  type: 'preciseMonetary',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_WIN_RPM,
})

export const bidRpm = buildFormula({
  name: 'Bid RPM',
  keys: ['bidResponseCpmSum', 'bidRequestCount'],
  raw: ({ bidResponseCpmSum, bidRequestCount }) => (bidRequestCount ? bidResponseCpmSum / bidRequestCount : 0),
  type: 'preciseMonetary',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.BIDDER_DETAILS_BID_RPM,
})

export const hbECPM = buildFormula({
  name: 'Prebid eCPM',
  keys: ['impressionCpmSum', 'impressionCount'],
  raw: ({ impressionCpmSum, impressionCount }) => impressionCpmSum / (impressionCount || 1),
  type: 'monetary',
  isStackable: false,
})

export const openBiddingECPM = buildFormula({
  name: 'Open Bidding eCPM',
  keys: ['openBiddingImpressionCpmSum', 'openBiddingImpressionCount'],
  raw: ({ openBiddingImpressionCpmSum, openBiddingImpressionCount }) => openBiddingImpressionCpmSum / (openBiddingImpressionCount || 1),
  type: 'monetary',
  isStackable: false,
})

export const adxECPM = buildFormula({
  name: 'AdX eCPM',
  keys: ['adxImpressionCpmSum', 'adxImpressionCount'],
  raw: ({ adxImpressionCpmSum, adxImpressionCount }) => adxImpressionCpmSum / (adxImpressionCount || 1),
  type: 'monetary',
  isStackable: false,
})

export const allECPM = buildFormula({
  name: 'eCPM',
  keys: ['impressionCpmSum', 'adxImpressionCpmSum', 'impressionCount', 'adxImpressionCount', 'openBiddingImpressionCpmSum', 'openBiddingImpressionCount'],
  optionalKeys: ['impressionCpmSum', 'adxImpressionCpmSum', 'impressionCount', 'adxImpressionCount', 'openBiddingImpressionCpmSum', 'openBiddingImpressionCount'],
  raw: ({ impressionCpmSum, adxImpressionCpmSum, impressionCount, adxImpressionCount, openBiddingImpressionCpmSum, openBiddingImpressionCount }) =>
    ((impressionCpmSum ?? 0) + (adxImpressionCpmSum ?? 0) + (openBiddingImpressionCpmSum ?? 0)) / ((impressionCount ?? 0) + (adxImpressionCount ?? 0) + (openBiddingImpressionCount ?? 0) || 1),
  type: 'monetary',
  tooltip: ANALYTICS_TOOLTIPS.ECPM,
  isStackable: false,
})

export const impressionRate = buildFormula({
  name: 'Imp. rate',
  keys: ['impressionCount', 'bidWinCount'],
  raw: ({ impressionCount, bidWinCount }) => (impressionCount / (bidWinCount || 1)) * 100,
  type: 'percentage',
  isStackable: false,
})

export const auctionCount = buildFormula({
  name: 'Auctions',
  keys: ['auctionCount'],
  raw: ({ auctionCount }) => auctionCount,
  type: 'shortNumber',
  isStackable: true,
  tooltip: ANALYTICS_TOOLTIPS.AUCTION_COUNT,
})

export const hbAuctionRpm = buildFormula({
  name: 'Prebid RPM',
  keys: ['impressionCpmSum', 'auctionCount'],
  raw: ({ impressionCpmSum, auctionCount }) => (auctionCount ? impressionCpmSum / auctionCount : 0),
  type: 'preciseMonetary',
  isStackable: false,
})

export const adxAuctionRpm = buildFormula({
  name: 'AdX RPM',
  keys: ['adxImpressionCpmSum', 'auctionCount'],
  raw: ({ adxImpressionCpmSum, auctionCount }) => (auctionCount ? adxImpressionCpmSum / auctionCount : 0),
  type: 'preciseMonetary',
  isStackable: false,
})

export const openBiddingAuctionRpm = buildFormula({
  name: 'Open Bidding RPM',
  keys: ['openBiddingImpressionCpmSum', 'auctionCount'],
  raw: ({ openBiddingImpressionCpmSum, auctionCount }) => (auctionCount ? openBiddingImpressionCpmSum / auctionCount : 0),
  type: 'preciseMonetary',
  isStackable: false,
})

export const allAuctionRpm = buildFormula({
  name: 'RPM',
  keys: ['impressionCpmSum', 'adxImpressionCpmSum', 'openBiddingImpressionCpmSum', 'auctionCount'],
  optionalKeys: ['impressionCpmSum', 'adxImpressionCpmSum', 'openBiddingImpressionCpmSum'],
  raw: ({ impressionCpmSum, adxImpressionCpmSum, openBiddingImpressionCpmSum, auctionCount }) =>
    auctionCount ? ((impressionCpmSum ?? 0) + (adxImpressionCpmSum ?? 0) + (openBiddingImpressionCpmSum ?? 0)) / auctionCount : 0,
  type: 'preciseMonetary',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.AUCTION_RPM,
})

export const hbAuctionFillRate = buildFormula({
  name: 'Prebid fill rate',
  keys: ['impressionCount', 'auctionCount'],
  raw: ({ impressionCount, auctionCount }) => (auctionCount ? impressionCount / auctionCount : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const adxAuctionFillRate = buildFormula({
  name: 'AdX fill rate',
  keys: ['adxImpressionCount', 'auctionCount'],
  raw: ({ adxImpressionCount, auctionCount }) => (auctionCount ? adxImpressionCount / auctionCount : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const openBiddingAuctionFillRate = buildFormula({
  name: 'Open Bidding fill rate',
  keys: ['openBiddingImpressionCount', 'auctionCount'],
  raw: ({ openBiddingImpressionCount, auctionCount }) => (auctionCount ? openBiddingImpressionCount / auctionCount : 0) * 100,
  type: 'percentage',
  isStackable: false,
})

export const allAuctionFillRate = buildFormula({
  name: 'Fill rate',
  keys: ['impressionCount', 'adxImpressionCount', 'openBiddingImpressionCount', 'auctionCount'],
  optionalKeys: ['impressionCount', 'adxImpressionCount', 'openBiddingImpressionCount'],
  raw: ({ impressionCount, adxImpressionCount, openBiddingImpressionCount, auctionCount }) =>
    (auctionCount ? ((impressionCount ?? 0) + (adxImpressionCount ?? 0) + (openBiddingImpressionCount ?? 0)) / auctionCount : 0) * 100,
  type: 'percentage',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.AUCTION_FILL_RATE,
})

export const hbBidDensity = buildFormula({
  name: 'Prebid density',
  keys: ['hbAuctionCount', 'bidResponseCount'],
  raw: ({ hbAuctionCount, bidResponseCount }) => bidResponseCount / (hbAuctionCount || 1),
  // TODO: use a good display for the bid density
  type: 'shortNumber',
  isStackable: true,
})

export const hbViewabilityMRC = buildFormula({
  name: 'Viewability',
  keys: ['measurableImpressionCount', 'mrcViewableImpressionCount'],
  raw: ({ measurableImpressionCount, mrcViewableImpressionCount }) => (mrcViewableImpressionCount / (measurableImpressionCount || 1)) * 100,
  type: 'percentage',
  isStackable: false,
})

export const adxViewabilityMRC = buildFormula({
  name: 'AdX viewability',
  keys: ['adxMeasurableImpressionCount', 'adxMrcViewableImpressionCount'],
  raw: ({ adxMeasurableImpressionCount, adxMrcViewableImpressionCount }) => (adxMrcViewableImpressionCount / (adxMeasurableImpressionCount || 1)) * 100,
  type: 'percentage',
  isStackable: false,
})

export const allViewabilityMRC = buildFormula({
  name: 'Viewability',
  keys: ['measurableImpressionCount', 'mrcViewableImpressionCount', 'adxMeasurableImpressionCount', 'adxMrcViewableImpressionCount'],
  optionalKeys: ['measurableImpressionCount', 'mrcViewableImpressionCount', 'adxMeasurableImpressionCount', 'adxMrcViewableImpressionCount'],
  raw: ({ measurableImpressionCount, mrcViewableImpressionCount, adxMeasurableImpressionCount, adxMrcViewableImpressionCount }) =>
    (measurableImpressionCount || adxMeasurableImpressionCount
      ? ((mrcViewableImpressionCount ?? 0) + (adxMrcViewableImpressionCount ?? 0)) / ((measurableImpressionCount ?? 1) + (adxMeasurableImpressionCount ?? 1))
      : 0) * 100,
  type: 'percentage',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.VIEWABILITY,
})

export const hbViewedMRCImpressionCount = buildFormula({
  name: 'Viewable impressions',
  keys: ['mrcViewableImpressionCount'],
  raw: ({ mrcViewableImpressionCount }) => mrcViewableImpressionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const adxViewedMRCImpressionCount = buildFormula({
  name: 'AdX viewable impressions',
  keys: ['adxMrcViewableImpressionCount'],
  raw: ({ adxMrcViewableImpressionCount }) => adxMrcViewableImpressionCount,
  type: 'shortNumber',
  isStackable: true,
})

export const allViewedMRCImpressionCount = buildFormula({
  name: 'All viewable impressions',
  keys: ['mrcViewableImpressionCount', 'adxMrcViewableImpressionCount'],
  optionalKeys: ['mrcViewableImpressionCount', 'adxMrcViewableImpressionCount'],
  raw: ({ mrcViewableImpressionCount, adxMrcViewableImpressionCount }) => (mrcViewableImpressionCount ?? 0) + (adxMrcViewableImpressionCount ?? 0) || 0,
  type: 'shortNumber',
  isStackable: true,
})

export const hbAverageViewedTime = buildFormula({
  name: 'Avg. viewable time',
  keys: ['measuredImpressionViewedTimeSum', 'measuredImpressionCount'],
  raw: ({ measuredImpressionViewedTimeSum, measuredImpressionCount }) => (measuredImpressionCount ? measuredImpressionViewedTimeSum / measuredImpressionCount : 0),
  type: 'seconds',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
})

export const adxAverageViewedTime = buildFormula({
  name: 'Avg. viewable time',
  keys: ['adxMeasuredImpressionViewedTimeSum', 'adxMeasuredImpressionCount'],
  raw: ({ adxMeasuredImpressionViewedTimeSum, adxMeasuredImpressionCount }) => (adxMeasuredImpressionCount ? adxMeasuredImpressionViewedTimeSum / adxMeasuredImpressionCount : 0),
  type: 'seconds',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
})

export const allAverageViewedTime = buildFormula({
  name: 'Avg. viewable time',
  keys: ['measuredImpressionViewedTimeSum', 'measuredImpressionCount', 'adxMeasuredImpressionViewedTimeSum', 'adxMeasuredImpressionCount'],
  optionalKeys: ['measuredImpressionViewedTimeSum', 'measuredImpressionCount', 'adxMeasuredImpressionViewedTimeSum', 'adxMeasuredImpressionCount'],
  raw: ({ measuredImpressionViewedTimeSum, measuredImpressionCount, adxMeasuredImpressionViewedTimeSum, adxMeasuredImpressionCount }) =>
    measuredImpressionCount || adxMeasuredImpressionCount
      ? ((measuredImpressionViewedTimeSum ?? 0) + (adxMeasuredImpressionViewedTimeSum ?? 0)) / Math.max((measuredImpressionCount ?? 0) + (adxMeasuredImpressionCount ?? 0), 1)
      : 0,
  type: 'seconds',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.AVG_VIEWABLE_TIME,
})

export const sessionRpm = buildFormula({
  name: 'Session RPM',
  keys: ['impressionCpmSum', 'sessionCount'],
  raw: ({ impressionCpmSum, sessionCount }) => (sessionCount === 0 ? 0 : impressionCpmSum / sessionCount),
  type: 'preciseMonetary',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.SESSIONS_RPM,
})

export const pageRpm = buildFormula({
  name: 'Page RPM',
  keys: ['impressionCpmSum', 'pageCount'],
  raw: ({ impressionCpmSum, pageCount }) => (pageCount === 0 ? 0 : impressionCpmSum / pageCount),
  type: 'preciseMonetary',
  tooltip: ANALYTICS_TOOLTIPS.PAGES_RPM,
  isStackable: false,
})

export const impressionPerSession = buildFormula({
  name: 'Imp. per session',
  keys: ['impressionCount', 'sessionCount'],
  raw: ({ impressionCount, sessionCount }) => (sessionCount === 0 ? 0 : impressionCount / sessionCount),
  type: 'number',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.SESSIONS_IMPRESSIONS,
})

export const impressionPerPage = buildFormula({
  name: 'Imp. per page',
  keys: ['impressionCount', 'pageCount'],
  raw: ({ impressionCount, pageCount }) => (pageCount === 0 ? 0 : impressionCount / pageCount),
  type: 'number',
  isStackable: false,
  tooltip: ANALYTICS_TOOLTIPS.PAGE_IMPRESSIONS,
})

export const sessionCount = buildFormula({
  name: 'Sessions',
  keys: ['sessionCount'],
  raw: ({ sessionCount }) => sessionCount,
  type: 'shortNumber',
  isStackable: true,
  tooltip: ANALYTICS_TOOLTIPS.SESSIONS,
})

export const pageViewPerSession = buildFormula({
  name: 'Pageviews per session',
  keys: ['pageCount', 'sessionCount'],
  raw: ({ pageCount, sessionCount }) => (sessionCount === 0 ? 0 : pageCount / sessionCount),
  type: 'shortNumber',
  isStackable: true,
  tooltip: ANALYTICS_TOOLTIPS.SESSIONS_PAGEVIEW,
})

export const pageView = buildFormula({
  name: 'Pageviews',
  keys: ['pageCount'],
  raw: ({ pageCount }) => pageCount,
  type: 'shortNumber',
  tooltip: ANALYTICS_TOOLTIPS.SESSIONS_PAGEVIEW,
  isStackable: true,
})

export const hbVideoCompletionRate = buildFormula({
  name: 'Video completion rate',
  keys: ['videoViewershipComplete', 'videoStart'],
  raw: ({ videoViewershipComplete, videoStart }) => (videoStart === 0 ? 0 : videoViewershipComplete / videoStart) * 100,
  type: 'percentage',
  isStackable: false,
})

export const hbVideoViewershipTotalErrorCount = buildFormula({
  name: 'Video error rate',
  keys: ['videoErrorCount', 'impressionCount'],
  raw: ({ videoErrorCount, impressionCount }) => (videoErrorCount + impressionCount === 0 ? 0 : (videoErrorCount / (videoErrorCount + impressionCount)) * 100),
  type: 'percentage',
  isStackable: false,
})

export const hbVideoStart = buildFormula({
  name: 'Vid. starts',
  keys: ['videoStart'],
  raw: ({ videoStart }) => videoStart,
  type: 'shortNumber',
  isStackable: true,
})

export const hbVideoFirstQuartile = buildFormula({
  name: '1st quartile',
  keys: ['videoFirstQuartile'],
  raw: ({ videoFirstQuartile }) => videoFirstQuartile,
  type: 'shortNumber',
  isStackable: true,
})

export const hbVideoMidpoint = buildFormula({
  name: 'Midpoint',
  keys: ['videoMidpoint'],
  raw: ({ videoMidpoint }) => videoMidpoint,
  type: 'shortNumber',
  isStackable: true,
})

export const hbVideoThirdQuartile = buildFormula({
  name: '3rd quartile',
  keys: ['videoThirdQuartile'],
  raw: ({ videoThirdQuartile }) => videoThirdQuartile,
  type: 'shortNumber',
  isStackable: true,
})

export const hbVideoViewershipComplete = buildFormula({
  name: 'Complete',
  keys: ['videoViewershipComplete'],
  raw: ({ videoViewershipComplete }) => videoViewershipComplete,
  type: 'shortNumber',
  isStackable: true,
})
