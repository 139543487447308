import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Alert } from '@pubstack/common/src/analytics/check'
import { CurrencySymbol } from '@pubstack/common/src/currency'
import { DateTime } from 'luxon'
import { FunctionComponent, ReactNode } from 'react'
import { Colors } from '~/assets/style/colors'
import { Fonts } from '~/assets/style/fonts'
import Button from '~/components/Button'
import { Icon } from '~/components/Icon'
import { IllustrationMessage } from '~/components/IllustrationMessage'
import { PageContent } from '~/components/PageContent'
import { Widget } from '~/components/Widget'
import Table, { Column } from '~/components/table/Table'
import TableCell from '~/components/table/TableCell'
import TableRow from '~/components/table/TableRow'
import { WithClassName } from '~/types/utils'
import { displayWithCurrency } from '~/utils/string'

const RedAlert = styled.div<{ center?: boolean }>`
  ${Fonts.colors.Alert}
  font-weight: bold;
  ${({ center }) =>
    center &&
    css`
      display: flex;
      align-items: center;
    `}
`

type PureAlertsTableProps = WithClassName & {
  currencySymbol: CurrencySymbol
  data?: Alert[]
  loading: boolean
  onRowClick: (alert: Alert) => void
}
const PureAlertsTable: FunctionComponent<PureAlertsTableProps> = ({ currencySymbol, data, loading, onRowClick }) => {
  const columns: Column[] = [
    {
      name: 'Site',
      isSortable: false,
    },
    {
      name: 'Bidder',
      isSortable: false,
    },
    {
      name: 'Ad unit',
      isSortable: false,
    },
    {
      name: currencySymbol + ' (vs D-1)',
      isSortable: false,
    },
    {
      name: '% (vs D-1)',
      isSortable: false,
    },
    {
      name: 'Occurrence',
      isSortable: false,
    },
  ]
  const alerts = (data || []).sort((alert1, alert2) => {
    return alert2.time - alert1.time === 0 ? Object.values(alert2.keys).join('|').localeCompare(Object.values(alert1.keys).join('|')) : alert2.time - alert1.time
  })
  return (
    <Table isLoading={loading} columns={columns}>
      {alerts.map((alert, index) => (
        <TableRow key={index} onClick={() => onRowClick(alert)}>
          <TableCell>{alert.keys.tagId ? alert.keys.tagId.label : '-'}</TableCell>
          <TableCell>{alert.keys.bidder ? alert.keys.bidder.label : '-'}</TableCell>
          <TableCell>{alert.keys.adUnit ? alert.keys.adUnit.label : '-'}</TableCell>
          <TableCell>
            <RedAlert>{'- ' + displayWithCurrency(Math.abs(Math.round(alert.comparedValue) - Math.round(alert.refValue)), currencySymbol)}</RedAlert>
          </TableCell>
          <TableCell>
            <RedAlert center>
              <Icon name={'arrow_bottom_right'} width={'16px'} fill={Colors.Alert} />
              {-Math.trunc(Math.abs(alert.value) * 100) + ' %'}
            </RedAlert>
          </TableCell>
          {/*As the alert in trigger as J for J-1 we need to minus 1 day from the alert time */}
          <TableCell>{DateTime.fromSeconds(alert.time).minus({ days: 1 }).toFormat('yyyy-MM-dd')}</TableCell>
        </TableRow>
      ))}
    </Table>
  )
}

type PureAlertsPageProps = WithClassName &
  PureAlertsTableProps & {
    breadcrumbs: ReactNode
    onManageClick: () => void
    error: boolean
    onRefreshClick: () => void
  }
const _PureAlertsPage: FunctionComponent<PureAlertsPageProps> = ({ className, breadcrumbs, onManageClick, loading, error, data, currencySymbol, onRowClick, onRefreshClick }) => (
  <PageContent
    breadcrumbs={breadcrumbs}
    headerActions={
      <Button variant={'tertiary'} iconName={'mail'} onClick={onManageClick}>
        Manage email subscription
      </Button>
    }
  >
    <Widget className={className} isLoading={loading} error={error} empty={false} icon={'notification'} title={'Summary'} onRefreshClick={onRefreshClick}>
      {!loading && (data || []).length === 0 ? (
        <IllustrationMessage iconName={'binoculars'} iconSize={'190px'} message={"Nothing to see, you're all good !"} />
      ) : (
        <PureAlertsTable currencySymbol={currencySymbol} loading={loading} data={data} onRowClick={onRowClick} />
      )}
    </Widget>
  </PageContent>
)

export const PureAlertsPage = styled(_PureAlertsPage)``
